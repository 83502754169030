<script setup lang="ts">
import { useStore } from 'vuex';
import TextInput from '@/components/input/TextInput.vue';
import EmailInput from '@/components/input/EmailInput.vue';
import ContentCard from '@/components/card/ContentCard.vue';
import { getRegex, TextInputType } from '@/types/TextInputType';
import { translate } from '@/i18n';
import AddressInput from '@/components/input/AddressInput.vue';
import { suggestions } from '@/apiCalls/addressApi';
import CalendarInput from '@/components/input/CalendarInput.vue';
import Button from '@/components/button/Button.vue';
import { useRoute } from 'vue-router';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import PhoneInput from '../input/PhoneInput.vue';
import PhoneInputMessage from '@/components/message/PhoneInputMessage.vue';
import { usePhone } from '@/composables/usePhone';
import { isString } from 'lodash';

const route = useRoute();
const store = useStore();
const phone = usePhone();

const props = withDefaults(
  defineProps<{
    disableFraudScoreButton?: boolean,
    disableSmbScoreButton?: boolean,
    disableScoreFraudCompanyButton?: boolean,
    disableIbanButton?: boolean,
    disableAmlcftButton?: boolean,
    showButton?: boolean,
    marketType?: string,
    companyCountry?: string,
    customerProfile?: any,
    propPrefillClientForm?: any,
    permissions?: Array<String>,
    doubleButton?: boolean,
    amlcftBlock?: boolean,
    companyScoreBlock?: boolean,
    scoreSmbBlock?: boolean,
    checkIbanBlock?: boolean,
    canWebScoreB2c?: boolean,
    hideCheckIbanBtn?: boolean
  }>(),
  {
    disableFraudScoreButton: false,
    disableSmbScoreButton: false,
    disableScoreFraudCompanyButton: false,
    disableIbanButton: false,
    disableAmlcftButton: false,
    showButton: true,
    marketType: '',
    doubleButton: false,
    amlcftBlock: false,
    companyScoreBlock: false,
    scoreSmbBlock: false,
    checkIbanBlock: false,
    canWebScoreB2c: false,
    hideCheckIbanBtn: false,
    companyCountry: 'FR'
  }
);

const emit = defineEmits(['show-score', 'check-iban', 'check-amlcft', 'form-cleaned']);
const clearResponseStatus = store.dispatch('clearResponseStatus');

const propPrefillClientFormValue = ref(props.propPrefillClientForm);
const DATE = TextInputType.DATE;
const EMAIL = TextInputType.EMAIL;
const IBAN = TextInputType.IBAN;
const LETTERS = TextInputType.LETTERS;
const NAME = TextInputType.NAME;
const address = ref({
  id: 'address',
  value: '' as string,
  autocomplete: 'off',
  options: [] as any,
  disabled: false
});
const phoneCountry = ref('+33');
const showAmlcftButtonData = ref(false);
const isValidate = ref(false);
const clientForm = ref({
  lastName: { disabled: false, value: '' },
  firstNames: { disabled: false, value: '' },
  phone: { disabled: false, value: '+33' },
  email: { disabled: false, value: '' },
  country: { disabled: false, value: '' },
  city: { disabled: false, value: '' },
  zipcode: { disabled: false, value: '' },
  street: { disabled: false, value: '' },
  birthDate: { disabled: false, value: '' },
  iban: { disabled: false, value: '' }
} as any);
const highlightScoreInput = ref(false);
const highlightIbanInput = ref(false);
const highlightAmlcftInput = ref(false);

const customerProfileV3 = computed(() => {
  return {
    identity: {
      lastName: clientForm.value.lastName.value,
      firstName: clientForm.value.firstNames.value,
      birthDate: clientForm.value.birthDate.value,
      email: clientForm.value.email.value,
      mobileNumber: clientForm.value.phone.value && clientForm.value.phone.value.length >= 5 ? clientForm.value.phone.value : null
    },
    address: {
      street: address.value.value,
      city: clientForm.value.city.value,
      zipCode: clientForm.value.zipcode.value,
      country: clientForm.value.country.value
    }
  };
});


const isEnableDoubleButton = computed(() => {
  return (props.disableSmbScoreButton && props.disableScoreFraudCompanyButton);
});

const displayDoubleButton = computed(() => {
  return props.doubleButton === 'true' && route.path === '/analysis-b2b';
});

const isB2CFolder = computed(() => route.name === 'AnalysisPageB2C');

const showScore = (e: SubmitEvent, label: string) => {
  e.preventDefault();
  const isValid = props.doubleButton ? isEnableDoubleButton.value : (props.disableFraudScoreButton);
  if (!(isValid || validateDataFraudScore())) {
    clearResponseStatus;
    isValidate.value = true;
    clientForm.value.phone.value = phone.getPhoneValue(clientForm.value.phone.value);
    showAmlcftButtonData.value = false;
    if (props.doubleButton) {
      emit('show-score', customerProfileV3.value, label);
    } else {
      emit('show-score', customerProfileV3.value);
    }
    clientForm.value.firstNames.disabled = true;
    clientForm.value.lastName.disabled = true;
    clientForm.value.phone.disabled = true;
    clientForm.value.email.disabled = true;
  } else {
    highlightScoreInput.value = true;
    setTimeout(() => {
      highlightScoreInput.value = false;
    }, 3000);
  }
};

const onAddressChanged = (name: string, value: any) => {
  if (value instanceof InputEvent) {
    if (address.value.value === undefined) {
      address.value.value = value.data as string;
    }

    const lastAddressTyped = address.value.value;

    setTimeout(() => {
      const currentAddressTyped = address.value.value;

      if (currentAddressTyped === lastAddressTyped) {
        getSuggestions(lastAddressTyped);
      }
    }, 500);
  } else if (value !== undefined && typeof value === 'string') {
    address.value.value = value as string;
  }
};

const getSuggestions = (value: string) => {
  if (value) {
    address.value.options = isInputFocused()
      ? [
        {
          label: 'Chargement ...',
          value: null
        }
      ]
      : [];

    suggestions(value).then((response: any) => {
      onAddressResponse(response);
    });
  }
};

const onAddressResponse = (response: any) => {
  const labels = [] as any;
  let city = '';
  let house_number = '';
  let new_address = '';
  let city_code = '';
  let country = '';
  let countryCode = '';
  response.features.forEach((value: any) => {
    const _address = value?.properties?.address;
    if (_address.city) {
      city = _address.city;
    } else if (_address.town) {
      city = _address.town;
    } else if (_address.village) {
      city = _address.village;
    } else if (_address.hamlet) {
      city = _address.hamlet;
    }

    if (_address.house_number) {
      house_number = _address.house_number;
    }

    if (_address.road) {
      new_address = _address.road;
    }

    if (_address.postcode) {
      city_code = _address.postcode;
    }

    if (_address.country) {
      country = _address.country;
    }

    if (_address.country_code) {
      countryCode = _address.country_code;
    }

    labels.push({
      label:
        house_number +
        ' ' +
        new_address +
        ' ' +
        city_code +
        ' ' +
        city +
        ' ' +
        country,
      house_number: house_number,
      address: new_address,
      city: city,
      postalCode: city_code,
      country: country,
      countryCode: countryCode.toUpperCase()
    });
  });

  address.value.options = isInputFocused() ? labels : [];
};

const getElementByInputType = () => {
  return document.getElementById(address.value.id);
};

const isInputFocused = () => {
  return getElementByInputType() === document.activeElement;
};

const onClickChild = (value: any) => {
  if (typeof value === 'string') {
    clientForm.value.street.value = value;
  } else {
    clientForm.value.street.value = '';
    clientForm.value.city.value = '';
    clientForm.value.zipcode.value = '';
    clientForm.value.country.value = '';
    clientForm.value.street.value = value.house_number + ' ' + value.address;
    clientForm.value.city.value = value.city;
    clientForm.value.zipcode.value = value.postalCode;
    clientForm.value.country.value = value.country;
  }
};

const checkIban = (e: SubmitEvent) => {
  e.preventDefault();
  if (!(props.disableIbanButton || validateDataCheckIban())) {
    clearResponseStatus;
    emit(
      'check-iban',
      clientForm.value.iban.value,
      customerProfileV3.value
    );
  } else {
    highlightIbanInput.value = true;
    setTimeout(() => {
      highlightIbanInput.value = false;
    }, 3000);
  }
};

const checkAmlcft = (e: SubmitEvent) => {
  e.preventDefault();
  if (!(props.disableAmlcftButton || validateCheckAmlcft())) {
    clearResponseStatus;
    emit('check-amlcft', customerProfileV3.value);
  } else {
    highlightAmlcftInput.value = true;
    setTimeout(() => {
      highlightAmlcftInput.value = false;
    }, 3000);
  }
};

const validateDataCheckIban = () => {
  if (route.path === '/analysis-b2c') {
    return !(
      getRegex(IBAN).test(clientForm.value.iban.value) &&
      getRegex(NAME).test(clientForm.value.firstNames.value) &&
      getRegex(NAME).test(clientForm.value.lastName.value)
    );
  } else {
    return !getRegex(IBAN).test(clientForm.value.iban.value);
  }
};

const validateDataFraudScore = () => {
  const checkPhone = phone.checkPhoneValue(clientForm.value.phone.value, phoneCountry.value);
  const checkFullAddressOr =
    address.value.value === '' ||
    clientForm.value.city.value === '' ||
    clientForm.value.zipcode.value === '' ||
    clientForm.value.country.value === '';

  const checkEmailAndFullName =
    getRegex(EMAIL).test(clientForm.value.email.value) &&
    isString(clientForm.value.firstNames.value) && getRegex(NAME).test(clientForm.value.firstNames.value) &&
    isString(clientForm.value.lastName.value) && getRegex(NAME).test(clientForm.value.lastName.value);

  if (
    clientForm.value.birthDate.value === '' ||
    checkFullAddressOr
  ) {
    return !(checkEmailAndFullName && checkPhone);
  } else if (checkFullAddressOr) {
    return !(
      checkEmailAndFullName &&
      checkPhone &&
      clientForm.value.birthDate.value !== ''
    );
  } else {
    return !(
      checkEmailAndFullName &&
      checkPhone &&
      clientForm.value.birthDate.value !== '' &&
      address.value.value !== '' &&
      clientForm.value.city.value !== '' &&
      clientForm.value.zipcode.value !== '' &&
      clientForm.value.country.value !== ''
    );
  }
};

const validateCheckAmlcft = () => {
  return !(
    getRegex(NAME).test(clientForm.value.firstNames.value) &&
    getRegex(NAME).test(clientForm.value.lastName.value) &&
    clientForm.value.birthDate.value &&
    clientForm.value.birthDate.value !== ''
  );
};

const applyIbanFormat = (event: any) => {
  if (clientForm.value.iban == null) {
    return;
  }
  const keyCode = event.which || event.keyCode;
  if (keyCode === 8 && clientForm.value.iban.value.charAt(clientForm.value.iban.length - 1) === ' ') {
    event.preventDefault();
  }
  clientForm.value.iban.value = clientForm.value.iban.value = clientForm.value.iban.value.replace(/\s/g, '').replace(/(.{4})/g, '$1 ');
  if (clientForm.value.iban.value.charAt(clientForm.value.iban.value.length - 1) === ' ') {
    clientForm.value.iban.value = clientForm.value.iban.value.slice(0, -1);
  }
};

const computeDefaultPhoneCountry = () => {
  const countryCode = phone.computePhoneCountry(props.companyCountry);
  phoneCountry.value = countryCode;
  if (clientForm.value.phone.value.length > 4) return;
  clientForm.value.phone.value = countryCode;
};

watch(clientForm, () => {
    clientForm.value = props.propPrefillClientForm;
    address.value.value = props.propPrefillClientForm.street.value;
    address.value.disabled = props.propPrefillClientForm.street.disabled;

    if (
      (clientForm.value.birthDate.value &&
        clientForm.value.birthDate.value.length === 2) ||
      (clientForm.value.birthDate.value &&
        clientForm.value.birthDate.value.length === 5)
    )
      clientForm.value.birthDate.value += '/';
    clientForm.value.phone.value
      ? clientForm.value.phone.value.replaceAll(' ', '')
      : null;

    if (!clientForm.value.phone.value)
      clientForm.value.phone.value = phoneCountry.value;
  },
  {
    immediate: true, // to trigger the watcher at the assembly of the component
    deep: true
  }
);

watch(() => props.propPrefillClientForm, (newValue) => { //to clean the form when clicking on "start a new analysis"
  propPrefillClientFormValue.value = newValue;
  clientForm.value = props.propPrefillClientForm;
  phoneCountry.value = '+33'; //to put the default indicator
});

onMounted(() => {
  computeDefaultPhoneCountry();
});

onUnmounted(() => {
  clearResponseStatus;
});
</script>

<template>
  <ContentCard :title="translate('CLIENT_INFORMATION')" class="personal-info">
    <form ref="form" v-on:submit="">
      <div class="personal-info-first-block">
        <div class="container-input">
          <TextInput class="form-b2c-bloc-input-result" :label="translate('IDENTITY.FIRSTNAME')"
                     :highlight="highlightIbanInput || highlightAmlcftInput || highlightScoreInput"
                     v-model="clientForm.firstNames.value" :type="NAME" :disabled="clientForm.firstNames.disabled"
                     required />
          <TextInput class="form-b2c-bloc-input-result" :label="translate('IDENTITY.NAME')"
                     :highlight="highlightIbanInput || highlightAmlcftInput || highlightScoreInput"
                     v-model="clientForm.lastName.value" :type="NAME" :disabled="clientForm.lastName.disabled" />
        </div>
        <div class="container-input">
          <CalendarInput class="form-b2c-bloc-input-result" :label="translate('IDENTITY.BIRTHDATE')"
                         :highlight="highlightAmlcftInput" v-model="clientForm.birthDate.value" :type="DATE"
                         :disabled="clientForm.birthDate.disabled" />
          <span v-if="!isB2CFolder"
                class="personal-info-birthdate-indication">{{ translate('BIRTHDATE_INDICATION_MESSAGE') }}</span>
        </div>
      </div>
      <div :class="`${marketType === 'b2b'? 'personal-info-second-block-b2b': 'personal-info-second-block-b2c'}`">
        <div class="container-input container-mail-telephone">
          <EmailInput v-model="clientForm.email.value" :label="translate('IDENTITY.EMAIL')"
                      :country-code="companyCountry" :disabled="clientForm.email.disabled" required
                      class="form-b2c-bloc-input-result mail" />
          <div class="phone-inputs">
            <PhoneInput v-model:phoneValue="clientForm.phone.value" v-model:phoneCountry="phoneCountry"
                        :disable-phone-value="clientForm.phone.disabled" :highlight="highlightScoreInput"
                        :required="true" />
            <PhoneInputMessage />
          </div>
        </div>
        <div class="personal-info-address">
          <AddressInput v-bind="address" :label="translate('ADDRESS.NUMBER_AND_STREET')"
                        @input="(val) => onAddressChanged('address', val)" @focus="(val) => onAddressChanged('', val)"
                        @blur="address.options = []" @clicked="onClickChild" @no-click="onClickChild" />
          <div class="personal-info-address-city">
            <TextInput class="form-b2c-input-result zipcode" :label="translate('ADDRESS.ZIP_CODE')"
                       v-model="clientForm.zipcode.value" :disabled="clientForm.zipcode.disabled" />
            <TextInput class="form-b2c-input-result city" :label="translate('ADDRESS.CITY')"
                       v-model="clientForm.city.value" :disabled="clientForm.city.disabled" />
            <TextInput class="form-b2c-input-result country" :label="translate('ADDRESS.COUNTRY')"
                       v-model="clientForm.country.value" :disabled="clientForm.country.disabled" />
          </div>
          <TextInput class="form-b2c-bloc-input-result" :label="translate('OPEN_BANKING.IBAN')"
                     :highlight="highlightIbanInput" v-model="clientForm.iban.value" :type="IBAN"
                     :disabled="clientForm.iban.disabled" @input="applyIbanFormat" />
        </div>
      </div>
      <span class="personal-info-select-analysis no-print">{{ `${translate('SELECT_ANALYSIS')} :` }}</span>
      <div class="container-button no-print">
        <template v-if="displayDoubleButton">
          <Button type="button" :label="translate('BUTTONS.SCORE_SMB')"
                  :disabled="disableSmbScoreButton || validateDataFraudScore()" @click="showScore($event, 'smbScore')"
                  :action-icon="!scoreSmbBlock ? 'padlock-blue.png' : ''" />
          <Button type="button" :label="translate('BUTTONS.SCORE_COMPANY_FRAUD')"
                  :disabled="disableScoreFraudCompanyButton || validateDataFraudScore()"
                  @click="showScore($event, 'scoreFraudCompany')"
                  :action-icon="!companyScoreBlock ? 'padlock-blue.png' : ''" />
        </template>
        <Button v-else type="button"
                :label="`${marketType === 'b2c' ? translate('BUTTONS.FRAUD_SCORE') : translate('BUTTONS.SCORE_B2B')}`"
                :disabled="disableFraudScoreButton || validateDataFraudScore()" @click="showScore"
                :action-icon="!canWebScoreB2c ? 'padlock-blue.png' : ''" />
        <Button v-if="!hideCheckIbanBtn" type="button" :label="translate('BUTTONS.IBAN')" @click="checkIban"
                :disabled="disableIbanButton || validateDataCheckIban()"
                :action-icon="!checkIbanBlock ? 'padlock-blue.png' : ''" />
        <Button type="button" :label="translate('BUTTONS.AMLCFT')"
                :disabled="disableAmlcftButton || validateCheckAmlcft()" @click="checkAmlcft"
                :action-icon="!amlcftBlock ? 'padlock-blue.png' : ''" />
      </div>
    </form>
  </ContentCard>
</template>