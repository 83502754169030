<script lang="ts" setup>
import { computed, onUnmounted, ref } from 'vue';

import { translate } from '@/i18n';
import { getRegex, TextInputType } from '@/types/TextInputType';

import Button from '@/components/button/Button.vue';
import PhoneInput from '@/components/input/PhoneInput.vue';
import Loader from '@/components/loader/Loader.vue';

const props = defineProps<{
  uploadMode: 'PHONE' | 'IFRAME';
  isLoadingIframe: boolean;
  iframeLink: string;
}>();

const emit = defineEmits(['changeUploadMode', 'sendSms', 'closeModal']);

const phoneNumber = ref('+33');

const phoneValid = computed(() => {
  return !getRegex(TextInputType.PHONE_NUMBER).test(phoneNumber.value);
});

const sendSms = () => {
  emit('sendSms', phoneNumber.value);
  phoneNumber.value = '';
};

onUnmounted(() => {
  phoneNumber.value = '';
});
</script>

<template>
  <h5>{{ translate('DOCUMENT_CONTROL.TABLE.ID_ANALYSIS_TITLE') }}</h5>
  <Loader v-if="uploadMode === 'IFRAME' && isLoadingIframe" />
  <iframe
    v-if="uploadMode === 'IFRAME' && iframeLink"
    :src="iframeLink"
    id="upload-iframe"
  />
  <div v-if="uploadMode == 'PHONE'" class="verification-document__phone">
    <PhoneInput v-model:phoneValue="phoneNumber" />
    <Button
      class="container-openbanking-informations-form-submit-button"
      type-button="submit"
      :label="translate('BUTTONS.VALIDATE_AND_SEND_SMS')"
      v-on:click="sendSms"
      :disabled="phoneValid"
    />
  </div>
  <Button
    type="submit"
    :label="translate('BUTTONS.CLOSE')"
    @click="emit('closeModal')"
  />
</template>
