<script setup lang="ts">
import { computed } from 'vue';
import {
  CheckType,
  formatDataChecks,
  selectAndSortChecksByKey
} from '@/composables/formatKeyValuePairWithFunc';
import { translate } from '@/i18n';
import { KeyValueType } from '@/utils/sortArrayObjectKeys';

import LabelValuePrinter from '@/components/LabelValuePrinter.vue';
import ResultCard from '@/components/card/ResultCard.vue';
import AuthenticityResults from '@/components/document-analysis/AuthenticityResults.vue';

const props = withDefaults(defineProps<{
  checks: Object,
  ocrStatus: string
}>(), {});

const sortKeys: KeyValueType[] = [
  {
    COMPANY_DESCRIPTION: [
      'siren',
      'siret',
      'companyName',
      'birthName',
      'lastName',
      'firstNames',
      'legalForm',
      'activity'
    ]
  },
  {
    ESTABLISHMENT_DESCRIPTION: [
      'siret',
      'address',
      'activity',
      'registrationDate'
    ]
  }
];

const sortedCheks = computed(() => {
  return selectAndSortChecksByKey({
    checks: props.checks as CheckType,
    sortKeys
  });
});
const formattedChecks = computed(() => formatDataChecks(sortedCheks.value));
</script>

<template>
  <div class="kbis-results">
    <ResultCard
      v-if="!['FAILED', 'CANCELED'].includes(ocrStatus)"
      v-for="check in formattedChecks"
      :title="translate(`DOCUMENT_CONTROL.TABLE.RESULT.KBIS.${check.transKey}`)"
    >
      <template v-for="obj in check.value">
        <template v-if="!Array.isArray(obj)">
          <LabelValuePrinter
            class="kbis-results-label"
            :label="
              translate(`DOCUMENT_CONTROL.TABLE.RESULT.KBIS.${obj.transKey}`)
            "
            :value="obj.value"
          />
        </template>
      </template>
    </ResultCard>
    <AuthenticityResults
      :title="translate('DOCUMENT_CONTROL.AUTHENTICITY_DETAILS')"
      :checks="checks.authenticityReasons" :status="checks.AUTHENTICITY_STATUS"
    />
  </div>
</template>
