<script setup lang="ts">
import Button from '@/components/button/Button.vue';
import ResultCard from '@/components/card/ResultCard.vue';
import { translate } from '@/i18n';
import { isEmpty, isString } from 'lodash';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    checks: Object;
    status: string;
  }>(),
  {}
);

const asAuthenticityReason = computed(() => {
    return props?.checks?.hasOwnProperty('authenticityReasons')
      ? props.checks.authenticityReasons
      : props.checks;
  }
);
const isCancelled = computed(() => asAuthenticityReason.value?.REASONS === 'CANCELED');

const ifEmpty = computed(() => {
  return isEmpty(asAuthenticityReason.value?.REASONS) && isEmpty(asAuthenticityReason.value?.REASONS_DETAILS);
});

const ifFailed = computed(() => {
  return asAuthenticityReason.value?.STATUS === 'FAILED' || props.status === 'FAILED';
});

const checkReasonDetails = computed(() => asAuthenticityReason.value?.REASONS_DETAILS && Object.keys(asAuthenticityReason.value?.REASONS_DETAILS));

const checksFn = (item: string) => asAuthenticityReason.value?.REASONS_DETAILS[item];
</script>
<template>
  <ResultCard v-if="isString(status) && status !== 'IN_PROGRESS'" :title="translate('INFORMATIONS')">
    <div class="authenticity-results">
      <div class="authenticity-results-details">
        <span v-if="isCancelled" class="authenticity-results-details-message">{{
            translate('DOCUMENT_CONTROL.TABLE.RESULT.AUTHENTICITY.CANCELLED')
          }}</span>
        <template v-else>
          <span v-if="ifEmpty"
                class="authenticity-results-details-message">{{ translate('DOCUMENT_CONTROL.TABLE.RESULT.AUTHENTICITY.NO_ALERT')
            }}</span>
          <span v-else-if="ifFailed"
                class="authenticity-results-details-message">{{ translate('DOCUMENT_CONTROL.TABLE.RESULT.AUTHENTICITY.FAILED_TASK')
            }}</span>
          <div v-else class="authenticity-results-details-reasons">
            <template v-for="(item, index) in checkReasonDetails" :key="index">
              <template v-for="check in checksFn(item)">
                <div>
                  <img
                    :src="
                      require(`/public/images/${
                        item === 'high'
                          ? 'failed-icon.png'
                          : item === 'medium'
                          ? 'warning-icon.png'
                          : item === 'low'
                          ? 'success-icon.png'
                          : 'null-icon.png'
                      }`)
                    "
                    alt="failed-icon.png"
                    class="authenticity-results-details-reasons__icon"
                  />
                  <span>{{
                      ` ${translate(
                        `DOCUMENT_CONTROL.TABLE.RESULT.AUTHENTICITY.${check}`
                      )}`
                    }}</span>
                </div>
              </template>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div class="authenticity-results-report no-print">
      <a v-if="asAuthenticityReason?.REDIRECT_URL" :href="asAuthenticityReason?.REDIRECT_URL" target="_blank">
        <Button :label="translate('BUTTONS.CONSULT_FINOVOX')" />
      </a>
    </div>
  </ResultCard>
</template>
