const countryCode = [
  {
    'country_code': 'AF',
    'country': [
      'afghanistan',
      'afghanistan',
      'afganistán',
      'afghanistan',
      'afghanistan',
      'afghanistan',
      'afeganistão'
    ],
    'flag': '🇦🇫'
  },
  {
    'country_code': 'AL',
    'country': [
      'albanien',
      'albania',
      'albania',
      'albanie',
      'albania',
      'albanië',
      'albânia'
    ],
    'flag': '🇦🇱'
  },
  {
    'country_code': 'DZ',
    'country': [
      'algerien',
      'algeria',
      'argelia',
      'algérie',
      'algeria',
      'algerije',
      'argélia'
    ],
    'flag': '🇩🇿'
  },
  {
    'country_code': 'AS',
    'country': [
      'amerikanisch-samoa',
      'american samoa',
      'samoa americana',
      'samoa américaines',
      'samoa americane',
      'amerikaans-samoa',
      'samoa americana'
    ],
    'flag': '🇦🇸'
  },
  {
    'country_code': 'AD',
    'country': [
      'andorra',
      'andorra',
      'andorra',
      'andorre',
      'andorra',
      'andorra',
      'andorra'
    ],
    'flag': '🇦🇩'
  },
  {
    'country_code': 'AO',
    'country': [
      'angola',
      'angola',
      'angola',
      'angola',
      'angola',
      'angola',
      'angola'
    ],
    'flag': '🇦🇴'
  },
  {
    'country_code': 'AI',
    'country': [
      'anguilla',
      'anguilla',
      'anguilla',
      'anguilla',
      'anguilla',
      'anguilla',
      'anguilla'
    ],
    'flag': '🇦🇮'
  },
  {
    'country_code': 'AQ',
    'country': [
      'antarktis',
      'antarctica',
      'antártida',
      'antarctique',
      'antartide',
      'antarctica',
      'antártida'
    ],
    'flag': '🇦🇶'
  },
  {
    'country_code': 'AG',
    'country': [
      'antigua und barbuda',
      'antigua and barbuda',
      'antigua y barbuda',
      'antigua-et-barbuda',
      'antigua e barbuda',
      'antigua en barbuda',
      'antígua e barbuda'
    ],
    'flag': '🇦🇬'
  },
  {
    'country_code': 'AR',
    'country': [
      'argentinien',
      'argentina',
      'argentina',
      'argentine',
      'argentina',
      'argentinië',
      'argentina'
    ],
    'flag': '🇦🇷'
  },
  {
    'country_code': 'AM',
    'country': [
      'armenien',
      'armenia',
      'armenia',
      'arménie',
      'armenia',
      'armenië',
      'arménia'
    ],
    'flag': '🇦🇲'
  },
  {
    'country_code': 'AU',
    'country': [
      'australien',
      'australia',
      'australia',
      'australie',
      'australia',
      'australië',
      'austrália'
    ],
    'flag': '🇦🇺'
  },
  {
    'country_code': 'AT',
    'country': [
      'österreich',
      'austria',
      'austria',
      'autriche',
      'austria',
      'oostenrijk',
      'áustria'
    ],
    'flag': '🇦🇹'
  },
  {
    'country_code': 'AZ',
    'country': [
      'aserbaidschan',
      'azerbaijan',
      'azerbaiyán',
      'azerbaïdjan',
      'azerbaigian',
      'azerbeidzjan',
      'azerbaijão'
    ],
    'flag': '🇦🇿'
  },
  {
    'country_code': 'BS',
    'country': [
      'bahamas',
      'bahamas',
      'bahamas',
      'bahamas',
      'bahamas',
      'bahama\'s',
      'bahamas'
    ],
    'flag': '🇧🇸'
  },
  {
    'country_code': 'BH',
    'country': [
      'bahrain',
      'bahrain',
      'baréin',
      'bahreïn',
      'bahrein',
      'bahrein',
      'bahrein'
    ],
    'flag': '🇧🇭'
  },
  {
    'country_code': 'BD',
    'country': [
      'bangladesch',
      'bangladesh',
      'bangladés',
      'bangladesh',
      'bangladesh',
      'bangladesh',
      'bangladesh'
    ],
    'flag': '🇧🇩'
  },
  {
    'country_code': 'BB',
    'country': [
      'barbados',
      'barbados',
      'barbados',
      'barbade',
      'barbados',
      'barbados',
      'barbados'
    ],
    'flag': '🇧🇧'
  },
  {
    'country_code': 'BY',
    'country': [
      'weißrussland',
      'belarus',
      'bielorrusia',
      'biélorussie',
      'bielorussia',
      'wit-rusland',
      'bielorrússia'
    ],
    'flag': '🇧🇾'
  },
  {
    'country_code': 'BE',
    'country': [
      'belgien',
      'belgium',
      'bélgica',
      'belgique',
      'belgio',
      'belgië',
      'bélgica'
    ],
    'flag': '🇧🇪'
  },
  {
    'country_code': 'BZ',
    'country': [
      'belize',
      'belize',
      'belice',
      'belize',
      'belize',
      'belize',
      'belize'
    ],
    'flag': '🇧🇿'
  },
  {
    'country_code': 'BJ',
    'country': [
      'benin',
      'benin',
      'benín',
      'bénin',
      'benin',
      'benin',
      'benim'
    ],
    'flag': '🇧🇯'
  },
  {
    'country_code': 'BM',
    'country': [
      'bermuda',
      'bermuda',
      'bermudas',
      'bermudes',
      'bermuda',
      'bermuda',
      'bermudas'
    ],
    'flag': '🇧🇲'
  },
  {
    'country_code': 'BT',
    'country': [
      'bhutan',
      'bhutan',
      'bután',
      'bhoutan',
      'bhutan',
      'bhutan',
      'butão'
    ],
    'flag': '🇧🇹'
  },
  {
    'country_code': 'BO',
    'country': [
      'bolivien',
      'bolivia',
      'bolivia',
      'bolivie',
      'bolivia',
      'bolivië',
      'bolívia'
    ],
    'flag': '🇧🇴'
  },
  {
    'country_code': 'BA',
    'country': [
      'bosnien und herzegowina',
      'bosnia and herzegovina',
      'bosnia y herzegovina',
      'bosnie-herzégovine',
      'bosnia ed erzegovina',
      'bosnië en herzegovina',
      'bósnia e herzegovina'
    ],
    'flag': '🇧🇦'
  },
  {
    'country_code': 'BW',
    'country': [
      'botswana',
      'botswana',
      'botsuana',
      'botswana',
      'botswana',
      'botswana',
      'botswana'
    ],
    'flag': '🇧🇼'
  },
  {
    'country_code': 'BR',
    'country': [
      'brasilien',
      'brazil',
      'brasil',
      'brésil',
      'brasile',
      'brazilië',
      'brasil'
    ],
    'flag': '🇧🇷'
  },
  {
    'country_code': 'BN',
    'country': [
      'brunei',
      'brunei',
      'brunéi',
      'brunei',
      'brunei',
      'brunei',
      'brunei'
    ],
    'flag': '🇧🇳'
  },
  {
    'country_code': 'BG',
    'country': [
      'bulgarien',
      'bulgaria',
      'bulgaria',
      'bulgarie',
      'bulgaria',
      'bulgarije',
      'bulgária'
    ],
    'flag': '🇧🇬'
  },
  {
    'country_code': 'BF',
    'country': [
      'burkina faso',
      'burkina faso',
      'burkina faso',
      'burkina faso',
      'burkina faso',
      'burkina faso',
      'burkina faso'
    ],
    'flag': '🇧🇫'
  },
  {
    'country_code': 'BI',
    'country': [
      'burundi',
      'burundi',
      'burundi',
      'burundi',
      'burundi',
      'burundi',
      'burundi'
    ],
    'flag': '🇧🇮'
  },
  {
    'country_code': 'KH',
    'country': [
      'kambodscha',
      'cambodia',
      'camboya',
      'cambodge',
      'cambogia',
      'cambodja',
      'camboja'
    ],
    'flag': '🇰🇭'
  },
  {
    'country_code': 'CM',
    'country': [
      'kamerun',
      'cameroon',
      'camerún',
      'cameroun',
      'camerun',
      'kameroen',
      'camarões'
    ],
    'flag': '🇨🇲'
  },
  {
    'country_code': 'CA',
    'country': [
      'kanada',
      'canada',
      'canadá',
      'canada',
      'canada',
      'canada',
      'canadá'
    ],
    'flag': '🇨🇦'
  },
  {
    'country_code': 'CV',
    'country': [
      'kap verde',
      'cape verde',
      'cabo verde',
      'cap-vert',
      'capo verde',
      'kaapverdië',
      'cabo verde'
    ],
    'flag': '🇨🇻'
  },
  {
    'country_code': 'CF',
    'country': [
      'zentralafrikanische republik',
      'central african republic',
      'república centroafricana',
      'république centrafricaine',
      'repubblica centrafricana',
      'centraal afrikaanse republiek',
      'república centro-africana'
    ],
    'flag': '🇨🇫'
  },
  {
    'country_code': 'TD',
    'country': [
      'tschad',
      'chad',
      'chad',
      'tchad',
      'ciad',
      'tsjaad',
      'chade'
    ],
    'flag': '🇹🇩'
  },
  {
    'country_code': 'CL',
    'country': [
      'chile',
      'chile',
      'chile',
      'chili',
      'cile',
      'chili',
      'chile'
    ],
    'flag': '🇨🇱'
  },
  {
    'country_code': 'CN',
    'country': [
      'china',
      'china',
      'china',
      'chine',
      'cina',
      'china',
      'china'
    ],
    'flag': '🇨🇳'
  },
  {
    'country_code': 'CO',
    'country': [
      'kolumbien',
      'colombia',
      'colombia',
      'colombie',
      'colombia',
      'colombia',
      'colômbia'
    ],
    'flag': '🇨🇴'
  },
  {
    'country_code': 'KM',
    'country': [
      'komoren',
      'comoros',
      'comoras',
      'comores',
      'comore',
      'comoren',
      'comores'
    ],
    'flag': '🇰🇲'
  },
  {
    'country_code': 'CD',
    'country': [
      'demokratische republik kongo',
      'democratic republic of the congo',
      'república democrática del congo',
      'république démocratique du congo',
      'repubblica democratica del congo',
      'democratische republiek congo',
      'república democrática do congo'
    ],
    'flag': '🇨🇩'
  },
  {
    'country_code': 'CG',
    'country': [
      'republik kongo',
      'republic of the congo',
      'república del congo',
      'république du congo',
      'repubblica del congo',
      'republiek congo',
      'república do congo'
    ],
    'flag': '🇨🇬'
  },
  {
    'country_code': 'CR',
    'country': [
      'costa rica',
      'costa rica',
      'costa rica',
      'costa rica',
      'costa rica',
      'costa rica',
      'costa rica'
    ],
    'flag': '🇨🇷'
  },
  {
    'country_code': 'CI',
    'country': [
      'elfenbeinküste',
      'ivory coast',
      'costa de marfil',
      'côte d\'ivoire',
      'costa d\'avorio',
      'ivoorkust',
      'costa do marfim'
    ],
    'flag': '🇨🇮'
  },
  {
    'country_code': 'HR',
    'country': [
      'kroatien',
      'croatia',
      'croacia',
      'croatie',
      'croazia',
      'kroatië',
      'croácia'
    ],
    'flag': '🇭🇷'
  },
  {
    'country_code': 'CU',
    'country': [
      'kuba',
      'cuba',
      'cuba',
      'cuba',
      'cuba',
      'cuba',
      'cuba'
    ],
    'flag': '🇨🇺'
  },
  {
    'country_code': 'CY',
    'country': [
      'zypern',
      'cyprus',
      'chipre',
      'chypre',
      'cipro',
      'cyprus',
      'chipre'
    ],
    'flag': '🇨🇾'
  },
  {
    'country_code': 'CZ',
    'country': [
      'tschechien',
      'czech republic',
      'república checa',
      'république tchèque',
      'repubblica ceca',
      'tsjechië',
      'república checa'
    ],
    'flag': '🇨🇿'
  },
  {
    'country_code': 'DK',
    'country': [
      'dänemark',
      'denmark',
      'dinamarca',
      'danemark',
      'danimarca',
      'denemarken',
      'dinamarca'
    ],
    'flag': '🇩🇰'
  },
  {
    'country_code': 'DJ',
    'country': [
      'dschibuti',
      'djibouti',
      'yibuti',
      'djibouti',
      'gibuti',
      'djibouti',
      'djibouti'
    ],
    'flag': '🇩🇯'
  },
  {
    'country_code': 'DM',
    'country': [
      'dominica',
      'dominica',
      'dominica',
      'dominique',
      'dominica',
      'dominica',
      'dominica'
    ],
    'flag': '🇩🇲'
  },
  {
    'country_code': 'DO',
    'country': [
      'dominikanische republik',
      'dominican republic',
      'república dominicana',
      'république dominicaine',
      'repubblica dominicana',
      'dominicaanse republiek',
      'república dominicana'
    ],
    'flag': '🇩🇴'
  },
  {
    'country_code': 'EC',
    'country': [
      'ecuador',
      'ecuador',
      'ecuador',
      'équateur',
      'ecuador',
      'ecuador',
      'equador'
    ],
    'flag': '🇪🇨'
  },
  {
    'country_code': 'EG',
    'country': [
      'ägypten',
      'egypt',
      'egipto',
      'égypte',
      'egitto',
      'egypte',
      'egito'
    ],
    'flag': '🇪🇬'
  },
  {
    'country_code': 'SV',
    'country': [
      'el salvador',
      'el salvador',
      'el salvador',
      'el salvador',
      'el salvador',
      'el salvador',
      'el salvador'
    ],
    'flag': '🇸🇻'
  },
  {
    'country_code': 'GQ',
    'country': [
      'äquatorialguinea',
      'equatorial guinea',
      'guinea ecuatorial',
      'guinée équatoriale',
      'guinea equatoriale',
      'equatoriaal-guinea',
      'guiné equatorial'
    ],
    'flag': '🇬🇶'
  },
  {
    'country_code': 'ER',
    'country': [
      'eritrea',
      'eritrea',
      'eritrea',
      'érythrée',
      'eritrea',
      'eritrea',
      'eritreia'
    ],
    'flag': '🇪🇷'
  },
  {
    'country_code': 'EE',
    'country': [
      'estland',
      'estonia',
      'estonia',
      'estonie',
      'estonia',
      'estland',
      'estónia'
    ],
    'flag': '🇪🇪'
  },
  {
    'country_code': 'SZ',
    'country': [
      'eswatini',
      'eswatini',
      'esuatini',
      'eswatini',
      'eswatini',
      'eswatini',
      'eswatini'
    ],
    'flag': '🇸🇿'
  },
  {
    'country_code': 'ET',
    'country': [
      'äthiopien',
      'ethiopia',
      'etiopía',
      'éthiopie',
      'etiopia',
      'ethiopië',
      'etiópia'
    ],
    'flag': '🇪🇹'
  },
  {
    'country_code': 'FJ',
    'country': [
      'fidschi',
      'fiji',
      'fiyi',
      'fidji',
      'fiji',
      'fiji',
      'fiji'
    ],
    'flag': '🇫🇯'
  },
  {
    'country_code': 'FI',
    'country': [
      'finnland',
      'finland',
      'finlandia',
      'finlande',
      'finlandia',
      'finland',
      'finlândia'
    ],
    'flag': '🇫🇮'
  },
  {
    'country_code': 'FR',
    'country': [
      'frankreich',
      'france',
      'francia',
      'france',
      'francia',
      'frankrijk',
      'frança'
    ],
    'flag': '🇫🇷'
  },
  {
    'country_code': 'GA',
    'country': [
      'gabun',
      'gabon',
      'gabón',
      'gabon',
      'gabon',
      'gabon',
      'gabão'
    ],
    'flag': '🇬🇦'
  },
  {
    'country_code': 'GM',
    'country': [
      'gambia',
      'gambia',
      'gambia',
      'gambie',
      'gambia',
      'gambia',
      'gâmbia'
    ],
    'flag': '🇬🇲'
  },
  {
    'country_code': 'GE',
    'country': [
      'georgien',
      'georgia',
      'georgia',
      'géorgie',
      'georgia',
      'georgië',
      'geórgia'
    ],
    'flag': '🇬🇪'
  },
  {
    'country_code': 'DE',
    'country': [
      'deutschland',
      'germany',
      'alemania',
      'allemagne',
      'germania',
      'duitsland',
      'alemanha'
    ],
    'flag': '🇩🇪'
  },
  {
    'country_code': 'GH',
    'country': [
      'ghana',
      'ghana',
      'ghana',
      'ghana',
      'ghana',
      'ghana',
      'ghana'
    ],
    'flag': '🇬🇭'
  },
  {
    'country_code': 'GR',
    'country': [
      'griechenland',
      'greece',
      'grecia',
      'grèce',
      'grecia',
      'griekenland',
      'grécia'
    ],
    'flag': '🇬🇷'
  },
  {
    'country_code': 'GD',
    'country': [
      'grenada',
      'grenada',
      'granada',
      'grenade',
      'grenada',
      'grenada',
      'granada'
    ],
    'flag': '🇬🇩'
  },
  {
    'country_code': 'GT',
    'country': [
      'guatemala',
      'guatemala',
      'guatemala',
      'guatemala',
      'guatemala',
      'guatemala',
      'guatemala'
    ],
    'flag': '🇬🇹'
  },
  {
    'country_code': 'GN',
    'country': [
      'guinea',
      'guinea',
      'guinea',
      'guinée',
      'guinea',
      'guinea',
      'guiné'
    ],
    'flag': '🇬🇳'
  },
  {
    'country_code': 'GW',
    'country': [
      'guinea-bissau',
      'guinea-bissau',
      'guinea-bisáu',
      'guinée-bissau',
      'guinea-bissau',
      'guinee-bissau',
      'guiné-bissau'
    ],
    'flag': '🇬🇼'
  },
  {
    'country_code': 'GY',
    'country': [
      'guyana',
      'guyana',
      'guyana',
      'guyana',
      'guyana',
      'guyana',
      'guiana'
    ],
    'flag': '🇬🇾'
  },
  {
    'country_code': 'HT',
    'country': [
      'haiti',
      'haiti',
      'haití',
      'haïti',
      'haiti',
      'haïti',
      'haiti'
    ],
    'flag': '🇭🇹'
  },
  {
    'country_code': 'HN',
    'country': [
      'honduras',
      'honduras',
      'honduras',
      'honduras',
      'honduras',
      'honduras',
      'honduras'
    ],
    'flag': '🇭🇳'
  },
  {
    'country_code': 'HU',
    'country': [
      'ungarn',
      'hungary',
      'hungría',
      'hongrie',
      'ungheria',
      'hongarije',
      'hungria'
    ],
    'flag': '🇭🇺'
  },
  {
    'country_code': 'IS',
    'country': [
      'island',
      'iceland',
      'islandia',
      'islande',
      'islanda',
      'ijsland',
      'islândia'
    ],
    'flag': '🇮🇸'
  },
  {
    'country_code': 'IN',
    'country': [
      'indien',
      'india',
      'india',
      'inde',
      'india',
      'india',
      'índia'
    ],
    'flag': '🇮🇳'
  },
  {
    'country_code': 'ID',
    'country': [
      'indonesien',
      'indonesia',
      'indonesia',
      'indonésie',
      'indonesia',
      'indonesië',
      'indonésia'
    ],
    'flag': '🇮🇩'
  },
  {
    'country_code': 'IR',
    'country': [
      'iran',
      'iran',
      'irán',
      'iran',
      'iran',
      'iran',
      'irã'
    ],
    'flag': '🇮🇷'
  },
  {
    'country_code': 'IQ',
    'country': [
      'irak',
      'iraq',
      'irak',
      'irak',
      'iraq',
      'irak',
      'iraque'
    ],
    'flag': '🇮🇶'
  },
  {
    'country_code': 'IE',
    'country': [
      'irland',
      'ireland',
      'irlanda',
      'irlande',
      'irlanda',
      'ierland',
      'irlanda'
    ],
    'flag': '🇮🇪'
  },
  {
    'country_code': 'IL',
    'country': [
      'israel',
      'israel',
      'israel',
      'israël',
      'israele',
      'israël',
      'israel'
    ],
    'flag': '🇮🇱'
  },
  {
    'country_code': 'IT',
    'country': [
      'italien',
      'italy',
      'italia',
      'italie',
      'italia',
      'italië',
      'itália'
    ],
    'flag': '🇮🇹'
  },
  {
    'country_code': 'JM',
    'country': [
      'jamaika',
      'jamaica',
      'jamaica',
      'jamaïque',
      'giamaica',
      'jamaica',
      'jamaica'
    ],
    'flag': '🇯🇲'
  },
  {
    'country_code': 'JP',
    'country': [
      'japan',
      'japan',
      'japón',
      'japon',
      'giappone',
      'japan',
      'japão'
    ],
    'flag': '🇯🇵'
  },
  {
    'country_code': 'JO',
    'country': [
      'jordanien',
      'jordan',
      'jordania',
      'jordanie',
      'giordania',
      'jordanië',
      'jordânia'
    ],
    'flag': '🇯🇴'
  },
  {
    'country_code': 'KZ',
    'country': [
      'kasachstan',
      'kazakhstan',
      'kazajistán',
      'kazakhstan',
      'kazakhstan',
      'kazachstan',
      'cazaquistão'
    ],
    'flag': '🇰🇿'
  },
  {
    'country_code': 'KE',
    'country': [
      'kenia',
      'kenya',
      'kenia',
      'kenya',
      'kenya',
      'kenia',
      'quênia'
    ],
    'flag': '🇰🇪'
  },
  {
    'country_code': 'KI',
    'country': [
      'kiribati',
      'kiribati',
      'kiribati',
      'kiribati',
      'kiribati',
      'kiribati',
      'kiribati'
    ],
    'flag': '🇰🇮'
  },
  {
    'country_code': 'KW',
    'country': [
      'kuwait',
      'kuwait',
      'kuwait',
      'koweït',
      'kuwait',
      'koeweit',
      'kuwait'
    ],
    'flag': '🇰🇼'
  },
  {
    'country_code': 'KG',
    'country': [
      'kirgisistan',
      'kyrgyzstan',
      'kirguistán',
      'kirghizistan',
      'kirghizistan',
      'kyrgyzstan',
      'quirguistão'
    ],
    'flag': '🇰🇬'
  },
  {
    'country_code': 'LA',
    'country': [
      'laos',
      'laos',
      'laos',
      'laos',
      'laos',
      'laos',
      'laos'
    ],
    'flag': '🇱🇦'
  },
  {
    'country_code': 'LV',
    'country': [
      'lettland',
      'latvia',
      'letonia',
      'lettonie',
      'lettonia',
      'letland',
      'letónia'
    ],
    'flag': '🇱🇻'
  },
  {
    'country_code': 'LB',
    'country': [
      'libanon',
      'lebanon',
      'líbano',
      'liban',
      'libano',
      'libanon',
      'líbano'
    ],
    'flag': '🇱🇧'
  },
  {
    'country_code': 'LS',
    'country': [
      'lesotho',
      'lesotho',
      'lesoto',
      'lesotho',
      'lesotho',
      'lesotho',
      'lesoto'
    ],
    'flag': '🇱🇸'
  },
  {
    'country_code': 'LR',
    'country': [
      'liberia',
      'liberia',
      'liberia',
      'libéria',
      'liberia',
      'liberia',
      'libéria'
    ],
    'flag': '🇱🇷'
  },
  {
    'country_code': 'LY',
    'country': [
      'libyen',
      'libya',
      'libia',
      'libye',
      'libia',
      'libië',
      'líbia'
    ],
    'flag': '🇱🇾'
  },
  {
    'country_code': 'LI',
    'country': [
      'liechtenstein',
      'liechtenstein',
      'liechtenstein',
      'liechtenstein',
      'liechtenstein',
      'liechtenstein',
      'liechtenstein'
    ],
    'flag': '🇱🇮'
  },
  {
    'country_code': 'LT',
    'country': [
      'litauen',
      'lithuania',
      'lituania',
      'lituanie',
      'lituania',
      'litouwen',
      'lituânia'
    ],
    'flag': '🇱🇹'
  },
  {
    'country_code': 'LU',
    'country': [
      'luxemburg',
      'luxembourg',
      'luxemburgo',
      'luxembourg',
      'lussemburgo',
      'luxemburg',
      'luxemburgo'
    ],
    'flag': '🇱🇺'
  },
  {
    'country_code': 'MG',
    'country': [
      'madagaskar',
      'madagascar',
      'madagascar',
      'madagascar',
      'madagascar',
      'madagaskar',
      'madagáscar'
    ],
    'flag': '🇲🇬'
  },
  {
    'country_code': 'MW',
    'country': [
      'malawi',
      'malawi',
      'malaui',
      'malawi',
      'malawi',
      'malawi',
      'malawi'
    ],
    'flag': '🇲🇼'
  },
  {
    'country_code': 'MY',
    'country': [
      'malaysia',
      'malaysia',
      'malasia',
      'malaisie',
      'malesia',
      'maleisië',
      'malásia'
    ],
    'flag': '🇲🇾'
  },
  {
    'country_code': 'MV',
    'country': [
      'malediven',
      'maldives',
      'maldivas',
      'maldives',
      'maldive',
      'maldiven',
      'maldivas'
    ],
    'flag': '🇲🇻'
  },
  {
    'country_code': 'ML',
    'country': [
      'mali',
      'mali',
      'mali',
      'mali',
      'mali',
      'mali',
      'mali'
    ],
    'flag': '🇲🇱'
  },
  {
    'country_code': 'MT',
    'country': [
      'malta',
      'malta',
      'malta',
      'malte',
      'malta',
      'malta',
      'malta'
    ],
    'flag': '🇲🇹'
  },
  {
    'country_code': 'MH',
    'country': [
      'marshallinseln',
      'marshall islands',
      'islas marshall',
      'îles marshall',
      'isole marshall',
      'marshalleilanden',
      'ilhas marshall'
    ],
    'flag': '🇲🇭'
  },
  {
    'country_code': 'MR',
    'country': [
      'mauretanien',
      'mauritania',
      'mauritania',
      'mauritanie',
      'mauritania',
      'mauritanië',
      'mauritânia'
    ],
    'flag': '🇲🇷'
  },
  {
    'country_code': 'MU',
    'country': [
      'mauritius',
      'mauritius',
      'mauricio',
      'maurice',
      'mauritius',
      'mauritius',
      'maurícia'
    ],
    'flag': '🇲🇺'
  },
  {
    'country_code': 'MX',
    'country': [
      'mexiko',
      'mexico',
      'méxico',
      'mexique',
      'messico',
      'mexico',
      'méxico'
    ],
    'flag': '🇲🇽'
  },
  {
    'country_code': 'FM',
    'country': [
      'mikronesien',
      'micronesia',
      'micronesia',
      'micronésie',
      'micronesia',
      'micronesië',
      'micronésia'
    ],
    'flag': '🇫🇲'
  },
  {
    'country_code': 'MD',
    'country': [
      'moldawien',
      'moldova',
      'moldavia',
      'moldavie',
      'moldavia',
      'moldavië',
      'moldávia'
    ],
    'flag': '🇲🇩'
  },
  {
    'country_code': 'MC',
    'country': [
      'monaco',
      'monaco',
      'mónaco',
      'monaco',
      'monaco',
      'monaco',
      'mônaco'
    ],
    'flag': '🇲🇨'
  },
  {
    'country_code': 'MN',
    'country': [
      'mongolei',
      'mongolia',
      'mongolia',
      'mongolie',
      'mongolia',
      'mongolië',
      'mongólia'
    ],
    'flag': '🇲🇳'
  },
  {
    'country_code': 'ME',
    'country': [
      'montenegro',
      'montenegro',
      'montenegro',
      'monténégro',
      'montenegro',
      'montenegro',
      'montenegro'
    ],
    'flag': '🇲🇪'
  },
  {
    'country_code': 'MA',
    'country': [
      'marokko',
      'morocco',
      'marruecos',
      'maroc',
      'marocco',
      'marokko',
      'marrocos'
    ],
    'flag': '🇲🇦'
  },
  {
    'country_code': 'MZ',
    'country': [
      'mosambik',
      'mozambique',
      'mozambique',
      'mozambique',
      'mozambico',
      'mozambique',
      'moçambique'
    ],
    'flag': '🇲🇿'
  },
  {
    'country_code': 'MM',
    'country': [
      'myanmar',
      'myanmar',
      'myanmar',
      'myanmar',
      'myanmar',
      'myanmar',
      'myanmar'
    ],
    'flag': '🇲🇲'
  },
  {
    'country_code': 'NA',
    'country': [
      'namibia',
      'namibia',
      'namibia',
      'namibie',
      'namibia',
      'namibië',
      'namíbia'
    ],
    'flag': '🇳🇦'
  },
  {
    'country_code': 'NR',
    'country': [
      'nauru',
      'nauru',
      'nauru',
      'nauru',
      'nauru',
      'nauru',
      'nauru'
    ],
    'flag': '🇳🇷'
  },
  {
    'country_code': 'NP',
    'country': [
      'nepal',
      'nepal',
      'nepal',
      'népal',
      'nepal',
      'nepal',
      'nepal'
    ],
    'flag': '🇳🇵'
  },
  {
    'country_code': 'NL',
    'country': [
      'niederlande',
      'netherlands',
      'países bajos',
      'pays-bas',
      'paesi bassi',
      'nederland',
      'países baixos'
    ],
    'flag': '🇳🇱'
  },
  {
    'country_code': 'NZ',
    'country': [
      'neuseeland',
      'new zealand',
      'nueva zelanda',
      'nouvelle-zélande',
      'nuova zelanda',
      'nieuw-zeeland',
      'nova zelândia'
    ],
    'flag': '🇳🇿'
  },
  {
    'country_code': 'NI',
    'country': [
      'nicaragua',
      'nicaragua',
      'nicaragua',
      'nicaragua',
      'nicaragua',
      'nicaragua',
      'nicaragua'
    ],
    'flag': '🇳🇮'
  },
  {
    'country_code': 'NE',
    'country': [
      'niger',
      'niger',
      'níger',
      'niger',
      'niger',
      'niger',
      'níger'
    ],
    'flag': '🇳🇪'
  },
  {
    'country_code': 'NG',
    'country': [
      'nigeria',
      'nigeria',
      'nigeria',
      'nigeria',
      'nigeria',
      'nigeria',
      'nigéria'
    ],
    'flag': '🇳🇬'
  },
  {
    'country_code': 'MK',
    'country': [
      'nordmazedonien',
      'north macedonia',
      'macedonia del norte',
      'macédoine du nord',
      'macedonia del nord',
      'noord-macedonië',
      'macedônia do norte'
    ],
    'flag': '🇲🇰'
  },
  {
    'country_code': 'NO',
    'country': [
      'norwegen',
      'norway',
      'noruega',
      'norvège',
      'norvegia',
      'noorwegen',
      'noruega'
    ],
    'flag': '🇳🇴'
  },
  {
    'country_code': 'OM',
    'country': [
      'oman',
      'oman',
      'omán',
      'oman',
      'oman',
      'oman',
      'omã'
    ],
    'flag': '🇴🇲'
  },
  {
    'country_code': 'PK',
    'country': [
      'pakistan',
      'pakistan',
      'pakistán',
      'pakistan',
      'pakistan',
      'pakistan',
      'paquistão'
    ],
    'flag': '🇵🇰'
  },
  {
    'country_code': 'PW',
    'country': [
      'palau',
      'palau',
      'palaos',
      'palaos',
      'palau',
      'palau',
      'palau'
    ],
    'flag': '🇵🇼'
  },
  {
    'country_code': 'PA',
    'country': [
      'panama',
      'panama',
      'panamá',
      'panama',
      'panama',
      'panama',
      'panamá'
    ],
    'flag': '🇵🇦'
  },
  {
    'country_code': 'PG',
    'country': [
      'papua-neuguinea',
      'papua new guinea',
      'papúa nueva guinea',
      'papouasie-nouvelle-guinée',
      'papua nuova guinea',
      'papoea-nieuw-guinea',
      'papua-nova guiné'
    ],
    'flag': '🇵🇬'
  },
  {
    'country_code': 'PY',
    'country': [
      'paraguay',
      'paraguay',
      'paraguay',
      'paraguay',
      'paraguay',
      'paraguay',
      'paraguai'
    ],
    'flag': '🇵🇾'
  },
  {
    'country_code': 'PE',
    'country': [
      'peru',
      'peru',
      'perú',
      'pérou',
      'perù',
      'peru',
      'peru'
    ],
    'flag': '🇵🇪'
  },
  {
    'country_code': 'PH',
    'country': [
      'philippinen',
      'philippines',
      'filipinas',
      'philippines',
      'filippine',
      'filipijnen',
      'filipinas'
    ],
    'flag': '🇵🇭'
  },
  {
    'country_code': 'PL',
    'country': [
      'polen',
      'poland',
      'polonia',
      'pologne',
      'polonia',
      'polen',
      'polónia'
    ],
    'flag': '🇵🇱'
  },
  {
    'country_code': 'PT',
    'country': [
      'portugal',
      'portugal',
      'portugal',
      'portugal',
      'portogallo',
      'portugal',
      'portugal'
    ],
    'flag': '🇵🇹'
  },
  {
    'country_code': 'QA',
    'country': [
      'katar',
      'qatar',
      'catar',
      'qatar',
      'qatar',
      'qatar',
      'catar'
    ],
    'flag': '🇶🇦'
  },
  {
    'country_code': 'RO',
    'country': [
      'rumänien',
      'romania',
      'rumanía',
      'roumanie',
      'romania',
      'roemenië',
      'romênia'
    ],
    'flag': '🇷🇴'
  },
  {
    'country_code': 'RU',
    'country': [
      'russland',
      'russia',
      'rusia',
      'russie',
      'russia',
      'rusland',
      'rússia'
    ],
    'flag': '🇷🇺'
  },
  {
    'country_code': 'RW',
    'country': [
      'ruanda',
      'rwanda',
      'ruanda',
      'rwanda',
      'ruanda',
      'rwanda',
      'ruanda'
    ],
    'flag': '🇷🇼'
  },
  {
    'country_code': 'KN',
    'country': [
      'st. kitts und nevis',
      'saint kitts and nevis',
      'san cristóbal y nieves',
      'saint-christophe-et-niévès',
      'saint kitts e nevis',
      'saint kitts en nevis',
      'são cristóvão e nevis'
    ],
    'flag': '🇰🇳'
  },
  {
    'country_code': 'LC',
    'country': [
      'st. lucia',
      'saint lucia',
      'santa lucía',
      'sainte-lucie',
      'saint lucia',
      'saint lucia',
      'santa lúcia'
    ],
    'flag': '🇱🇨'
  },
  {
    'country_code': 'VC',
    'country': [
      'st. vincent und die grenadinen',
      'saint vincent and the grenadines',
      'san vicente y las granadinas',
      'saint-vincent-et-les grenadines',
      'saint vincent e grenadine',
      'saint vincent en de grenadines',
      'são vicente e granadinas'
    ],
    'flag': '🇻🇨'
  },
  {
    'country_code': 'WS',
    'country': [
      'samoa',
      'samoa',
      'samoa',
      'samoa',
      'samoa',
      'samoa',
      'samoa'
    ],
    'flag': '🇼🇸'
  },
  {
    'country_code': 'SM',
    'country': [
      'san marino',
      'san marino',
      'san marino',
      'saint-marin',
      'san marino',
      'san marino',
      'san marino'
    ],
    'flag': '🇸🇲'
  },
  {
    'country_code': 'ST',
    'country': [
      'são tomé und príncipe',
      'são tomé and príncipe',
      'santo tomé y príncipe',
      'são tomé-et-principe',
      'são tomé e príncipe',
      'sao tomé en principe',
      'são tomé e príncipe'
    ],
    'flag': '🇸🇹'
  },
  {
    'country_code': 'SA',
    'country': [
      'saudi-arabien',
      'saudi arabia',
      'arabia saudita',
      'arabie saoudite',
      'arabia saudita',
      'saoedi-arabië',
      'arábia saudita'
    ],
    'flag': '🇸🇦'
  },
  {
    'country_code': 'SN',
    'country': [
      'senegal',
      'senegal',
      'senegal',
      'sénégal',
      'senegal',
      'senegal',
      'senegal'
    ],
    'flag': '🇸🇳'
  },
  {
    'country_code': 'RS',
    'country': [
      'serbien',
      'serbia',
      'serbia',
      'serbie',
      'serbia',
      'servië',
      'sérvia'
    ],
    'flag': '🇷🇸'
  },
  {
    'country_code': 'SC',
    'country': [
      'seychellen',
      'seychelles',
      'seychelles',
      'seychelles',
      'seychelles',
      'seychellen',
      'seicheles'
    ],
    'flag': '🇸🇨'
  },
  {
    'country_code': 'SL',
    'country': [
      'sierra leone',
      'sierra leone',
      'sierra leona',
      'sierra leone',
      'sierra leone',
      'sierra leone',
      'serra leoa'
    ],
    'flag': '🇸🇱'
  },
  {
    'country_code': 'SG',
    'country': [
      'singapur',
      'singapore',
      'singapur',
      'singapour',
      'singapore',
      'singapore',
      'singapura'
    ],
    'flag': '🇸🇬'
  },
  {
    'country_code': 'SK',
    'country': [
      'slowakei',
      'slovakia',
      'eslovaquia',
      'slovaquie',
      'slovacchia',
      'slowakije',
      'eslováquia'
    ],
    'flag': '🇸🇰'
  },
  {
    'country_code': 'SI',
    'country': [
      'slowenien',
      'slovenia',
      'eslovenia',
      'slovénie',
      'slovenia',
      'slovenië',
      'eslovênia'
    ],
    'flag': '🇸🇮'
  },
  {
    'country_code': 'SB',
    'country': [
      'salomonen',
      'solomon islands',
      'islas salomón',
      'îles salomon',
      'isole salomone',
      'salomonseilanden',
      'ilhas salomão'
    ],
    'flag': '🇸🇧'
  },
  {
    'country_code': 'SO',
    'country': [
      'somalia',
      'somalia',
      'somalia',
      'somalie',
      'somalia',
      'somalië',
      'somália'
    ],
    'flag': '🇸🇴'
  },
  {
    'country_code': 'ZA',
    'country': [
      'südafrika',
      'south africa',
      'sudáfrica',
      'afrique du sud',
      'sudafrica',
      'zuid-afrika',
      'áfrica do sul'
    ],
    'flag': '🇿🇦'
  },
  {
    'country_code': 'KR',
    'country': [
      'südkorea',
      'south korea',
      'corea del sur',
      'corée du sud',
      'corea del sud',
      'zuid-korea',
      'coreia do sul'
    ],
    'flag': '🇰🇷'
  },
  {
    'country_code': 'SS',
    'country': [
      'südsudan',
      'south sudan',
      'sudán del sur',
      'soudan du sud',
      'sud sudan',
      'zuid-soedan',
      'sudão do sul'
    ],
    'flag': '🇸🇸'
  },
  {
    'country_code': 'ES',
    'country': [
      'spanien',
      'spain',
      'españa',
      'espagne',
      'spagna',
      'spanje',
      'espanha'
    ],
    'flag': '🇪🇸'
  },
  {
    'country_code': 'LK',
    'country': [
      'sri lanka',
      'sri lanka',
      'sri lanka',
      'sri lanka',
      'sri lanka',
      'sri lanka',
      'sri lanka'
    ],
    'flag': '🇱🇰'
  },
  {
    'country_code': 'SD',
    'country': [
      'sudan',
      'sudan',
      'sudán',
      'soudan',
      'sudan',
      'soedan',
      'sudão'
    ],
    'flag': '🇸🇩'
  },
  {
    'country_code': 'SR',
    'country': [
      'surinam',
      'suriname',
      'surinam',
      'suriname',
      'suriname',
      'suriname',
      'suriname'
    ],
    'flag': '🇸🇷'
  },
  {
    'country_code': 'SE',
    'country': [
      'schweden',
      'sweden',
      'suecia',
      'suède',
      'svezia',
      'zweden',
      'suécia'
    ],
    'flag': '🇸🇪'
  },
  {
    'country_code': 'CH',
    'country': [
      'schweiz',
      'switzerland',
      'suiza',
      'suisse',
      'svizzera',
      'zwitserland',
      'suíça'
    ],
    'flag': '🇨🇭'
  },
  {
    'country_code': 'SY',
    'country': [
      'syrien',
      'syria',
      'siria',
      'syrie',
      'siria',
      'syrië',
      'síria'
    ],
    'flag': '🇸🇾'
  },
  {
    'country_code': 'TJ',
    'country': [
      'tadschikistan',
      'tajikistan',
      'tayikistán',
      'tadjikistan',
      'tagikistan',
      'tadzjikistan',
      'tajiquistão'
    ],
    'flag': '🇹🇯'
  },
  {
    'country_code': 'TZ',
    'country': [
      'tansania',
      'tanzania',
      'tanzania',
      'tanzanie',
      'tanzania',
      'tanzania',
      'tanzânia'
    ],
    'flag': '🇹🇿'
  },
  {
    'country_code': 'TH',
    'country': [
      'thailand',
      'thailand',
      'tailandia',
      'thaïlande',
      'thailandia',
      'thailand',
      'tailândia'
    ],
    'flag': '🇹🇭'
  },
  {
    'country_code': 'TL',
    'country': [
      'timor-leste',
      'timor-leste',
      'timor oriental',
      'timor-leste',
      'timor est',
      'oost-timor',
      'timor-leste'
    ],
    'flag': '🇹🇱'
  },
  {
    'country_code': 'TG',
    'country': [
      'togo',
      'togo',
      'togo',
      'togo',
      'togo',
      'togo',
      'togo'
    ],
    'flag': '🇹🇬'
  },
  {
    'country_code': 'TO',
    'country': [
      'tonga',
      'tonga',
      'tonga',
      'tonga',
      'tonga',
      'tonga',
      'tonga'
    ],
    'flag': '🇹🇴'
  },
  {
    'country_code': 'TT',
    'country': [
      'trinidad und tobago',
      'trinidad and tobago',
      'trinidad y tobago',
      'trinité-et-tobago',
      'trinidad e tobago',
      'trinidad en tobago',
      'trindade e tobago'
    ],
    'flag': '🇹🇹'
  },
  {
    'country_code': 'TN',
    'country': [
      'tunesien',
      'tunisia',
      'túnez',
      'tunisie',
      'tunisia',
      'tunesië',
      'tunísia'
    ],
    'flag': '🇹🇳'
  },
  {
    'country_code': 'TR',
    'country': [
      'türkei',
      'turkey',
      'turquía',
      'turquie',
      'turchia',
      'turkije',
      'turquia'
    ],
    'flag': '🇹🇷'
  },
  {
    'country_code': 'TM',
    'country': [
      'turkmenistan',
      'turkmenistan',
      'turkmenistán',
      'turkménistan',
      'turkmenistan',
      'turkmenistan',
      'turquemenistão'
    ],
    'flag': '🇹🇲'
  },
  {
    'country_code': 'TV',
    'country': [
      'tuvalu',
      'tuvalu',
      'tuvalu',
      'tuvalu',
      'tuvalu',
      'tuvalu',
      'tuvalu'
    ],
    'flag': '🇹🇻'
  },
  {
    'country_code': 'UG',
    'country': [
      'uganda',
      'uganda',
      'uganda',
      'ouganda',
      'uganda',
      'uganda',
      'uganda'
    ],
    'flag': '🇺🇬'
  },
  {
    'country_code': 'UA',
    'country': [
      'ukraine',
      'ukraine',
      'ucrania',
      'ukraine',
      'ucraina',
      'oekraïne',
      'ucrânia'
    ],
    'flag': '🇺🇦'
  },
  {
    'country_code': 'AE',
    'country': [
      'vereinigte arabische emirate',
      'united arab emirates',
      'emiratos árabes unidos',
      'émirats arabes unis',
      'emirati arabi uniti',
      'verenigde arabische emiraten',
      'emirados árabes unidos'
    ],
    'flag': '🇦🇪'
  },
  {
    'country_code': 'GB',
    'country': [
      'vereinigtes königreich',
      'united kingdom',
      'reino unido',
      'royaume-uni',
      'regno unito',
      'verenigd koninkrijk',
      'reino unido'
    ],
    'flag': '🇬🇧'
  },
  {
    'country_code': 'US',
    'country': [
      'vereinigte staaten',
      'united states',
      'estados unidos',
      'états-unis',
      'etats-unis d\'amerique',
      'stati uniti',
      'verenigde staten',
      'estados unidos'
    ],
    'flag': '🇺🇸'
  },
  {
    'country_code': 'UY',
    'country': [
      'uruguay',
      'uruguay',
      'uruguay',
      'uruguay',
      'uruguay',
      'uruguay',
      'uruguai'
    ],
    'flag': '🇺🇾'
  },
  {
    'country_code': 'UZ',
    'country': [
      'usbekistan',
      'uzbekistan',
      'uzbekistán',
      'ouzbekistan',
      'uzbekistan',
      'oezbekistan',
      'uzbequistão'
    ],
    'flag': '🇺🇿'
  },
  {
    'country_code': 'VU',
    'country': [
      'vanuatu',
      'vanuatu',
      'vanuatu',
      'vanuatu',
      'vanuatu',
      'vanuatu',
      'vanuatu'
    ],
    'flag': '🇻🇺'
  },
  {
    'country_code': 'VA',
    'country': [
      'vatikanstadt',
      'vatican city',
      'ciudad del vaticano',
      'cité du vatican',
      'città del vaticano',
      'vaticaanstad',
      'cidade do vaticano'
    ],
    'flag': '🇻🇦'
  },
  {
    'country_code': 'VE',
    'country': [
      'venezuela',
      'venezuela',
      'venezuela',
      'venezuela',
      'venezuela',
      'venezuela',
      'venezuela'
    ],
    'flag': '🇻🇪'
  },
  {
    'country_code': 'VN',
    'country': [
      'vietnam',
      'vietnam',
      'vietnam',
      'vietnam',
      'vietnam',
      'vietnam',
      'vietname'
    ],
    'flag': '🇻🇳'
  },
  {
    'country_code': 'YE',
    'country': [
      'jemen',
      'yemen',
      'yemen',
      'yémen',
      'yemen',
      'jemen',
      'iêmen'
    ],
    'flag': '🇾🇪'
  },
  {
    'country_code': 'ZM',
    'country': [
      'sambia',
      'zambia',
      'zambia',
      'zambie',
      'zambia',
      'zambia',
      'zâmbia'
    ],
    'flag': '🇿🇲'
  },
  {
    'country_code': 'ZW',
    'country': [
      'simbabwe',
      'zimbabwe',
      'zimbabue',
      'zimbabwe',
      'zimbabwe',
      'zimbabwe',
      'zimbábue'
    ],
    'flag': '🇿🇼'
  }
];


export const countryFormat = (country: string) => {
  return country?.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
};

export const searchCountryCode = (country: string) => {
  const countryNormalized = countryFormat(country);
  const foundCountry = countryCode.find(({ country }) => country.includes(countryNormalized));
  return foundCountry ? foundCountry.country_code : '';
};

export const searchCountryFlag = (code: string) => {
  const country = countryCode.find(item => item.country_code === code.toUpperCase());
  return country ? country.flag : '';
};
