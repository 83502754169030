import router from "@/router";
import store from "@/store";
import {isNil} from "lodash";
import connectAsApi from "@/apiCalls/connectAsApi";
import {useCookies} from "@/composables/useCookies";
import api from "@/utils/api";
import envConfig from "@/utils/envConfig";
import {Routes} from "@/utils/openBankingUtils";
import {useRoute} from "vue-router";

export const ACCESS_TOKEN = "access_token";

const cookies = useCookies();
const route = useRoute();

const user = store.getters.user;

export async function deleteHttpCookie(cookieName: string) {
  await api.delete(`${envConfig.BACKEND_ROUTE}/cookie/delete-cookie/${cookieName}`);
}

export async function setHttpCookie(cookieName: string, cookieValue: string) {
  await api.post(`${envConfig.BACKEND_ROUTE}/cookie/set-cookie`, {
    cookieName,
    cookieValue
  });
}

export async function deleteLocalCookies() {
  cookies.deleteCookie("expiration_instant");
  cookies.deleteCookie("user");
  cookies.deleteCookie("configuration");
  cookies.deleteCookie("connexion_date");
}

export async function pushToLogin() {
  await router.push(Routes.LOGIN);
}

export async function handleUserConnectedAsLogout() {
  if (user) {
    document.body.classList.remove(user.companyName ? user.companyName.replaceAll(' ', '-') : 'meelo');
    if (!isNil(user.realUser)) await connectAsApi.logoutConnectAs();
  }
}

export async function deleteLocalCookiesAndPushToLogin() {
  await Promise.all([
    deleteLocalCookies(),
    handleUserConnectedAsLogout(),
    pushToLogin()
  ]);
}

export async function handleUserLogout() {
  await Promise.all([
    deleteHttpCookie(ACCESS_TOKEN),
    deleteLocalCookies(),
    pushToLogin(),
  ]);
}
