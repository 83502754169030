import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "monitoring__my-usage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MonitoringCharts = _resolveComponent("MonitoringCharts")!
  const _component_MonitoringValues = _resolveComponent("MonitoringValues")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MonitoringCharts, {
      "total-label-usage": _ctx.totalLabelUsage,
      "total-usage": _ctx.totalUsage,
      "total-value-usage": _ctx.totalValueUsage,
      "color-usage": _ctx.generateRandomColorsArray(_ctx.totalValueUsage.length),
      "consumption-to-display": "MY_CONSUMPTION",
      "is-monitoring-data-loading": _ctx.isMonitoringDataLoading
    }, null, 8, ["total-label-usage", "total-usage", "total-value-usage", "color-usage", "is-monitoring-data-loading"]),
    _createVNode(_component_MonitoringValues, {
      "usage-without-all": _ctx.usageWithoutAll,
      "scores-usages": _ctx.scoresUsages
    }, null, 8, ["usage-without-all", "scores-usages"])
  ]))
}