import {apiBackOffice as api} from "@/utils/api";
import envConfig from "@/utils/envConfig";

export const getProfilesByCompanyAdmin = (profileName?: string) =>
  api.get(`${envConfig.IAM_ROUTE}/v5/client/admin/profiles?profileName=${profileName && profileName || ''}`)
    .then(response => response.data);

export const getAllPermissionsForAProfileByCompanyAdmin = (profileName: string) =>
  api.get(`${envConfig.IAM_ROUTE}/v5/client/admin/profiles/${profileName}/permissions`)
    .then(response => response.data);

export const searchProfileByCompanyAdmin = (profileName: string) =>
  api.get(`${envConfig.IAM_ROUTE}/v5/client/admin/profiles/search`)
    .then(response => response.data);

export const createProfileByCompanyAdmin = (name?: string, description?: string, enabled?: boolean) =>
  api.post(`${envConfig.IAM_ROUTE}/v5/client/admin/profiles`, {
      "name": (name === '' || name?.replaceAll(' ', '') === '') ? undefined : name,
      "description": (description === '' || description?.replaceAll(' ', '') === '') ? undefined : description,
      "enabled": enabled
    })
    .then(response => response);

export const editProfileByCompanyAdmin = (oldName?: string, newName?: string, description?: string, enabled?: boolean) =>
  api.put(`${envConfig.IAM_ROUTE}/v5/client/admin/profiles/${oldName}`, {
      "name": (newName === '' || newName?.replaceAll(' ', '') === '') ? undefined : newName,
      "description": (description === '' || description?.replaceAll(' ', '') === '') ? undefined : description,
      "enabled": enabled
    })
    .then(response => response.data);

export const editPermissionByCompanyAdmin = (profileName: string, body: {
  kitName: string,
  moduleName: string,
  permissionName: string
}[]) =>
  api.put(`${envConfig.IAM_ROUTE}/v5/client/admin/profiles/${profileName}/permissions`, body)
    .then(response => response.data);

export const deletePermissionByCompanyAdmin = (permissionName: string) =>
  api.delete(`${envConfig.IAM_ROUTE}/v5/client/admin/profiles/${permissionName}/permissions`)
    .then(response => response.data);

export const deleteProfileByCompanyAdmin = (profileName: string) =>
  api.delete(`${envConfig.IAM_ROUTE}/v5/client/admin/profiles/${profileName}`)
    .then(response => response.data);