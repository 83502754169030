import envConfig from "@/utils/envConfig";
import api from "@/utils/api";
import {Notification} from "@/utils/notifications";

enum NotificationEvent {
  INSERT = 'insert_alert'
}

/**
 * Code fragment commented because cookies are not accessible by client and SSE is disabled in production
 * */
// const accessToken = cookiesUtils.getCookie('access_token');
// const url = `${envConfig.HATCH_BACKEND_ROUTE}/alerting/v1/alerts/sse?access_token=${accessToken}`;
const url = `${envConfig.ALERTS_ROUTE}/v1/alerts/sse`;

// alerting
export const setAlertsToViewed = async (ids: string[]) => {
  return await api.post(`${envConfig.ALERTS_ROUTE}/v1/alerts/viewed`, ids)
    .then(response => {
      return response.data;
    });
}

export const deleteNotifications = async (ids: string[]) => {
  await api.post(`${envConfig.ALERTS_ROUTE}/v1/alerts/delete/me`, ids);
}

export const getAllNotifications = async (callback: (notification: Notification[]) => void) => {
  return await api.get(`${envConfig.ALERTS_ROUTE}/v1/alerts`)
    .then(response => {
      callback(response.data)
    });
}

export const getAllAlerts = async () => {
  return await api.get(`${envConfig.ALERTS_ROUTE}/v1/alerts/maintenance`)
    .then(response => response);
}


/**
 * @deprecated - this function is deprecated and will be removed in the future
 * */
export const getNotification = (callback: (notification: Notification) => void) => {
  const eventSource = new EventSource(url);
  eventSource.addEventListener(NotificationEvent.INSERT, (event) => {
    callback(JSON.parse(event.data));
  });
}