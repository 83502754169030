import { ApiUserDtoV2 } from '@/apiCalls/login';
import moment from 'moment';
import store from '@/store';

export function useCookies() {

  const deleteCookie = (name: string) => {
    const date = new Date();
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000)); // Set it to expire in the past
    document.cookie = `${name}=; expires=${date.toUTCString()}; path=/`;
  };

  const getCookie = (name: string) => {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');

    if (parts.length == 2) {
      let part = parts.pop();
      if (part != undefined) {
        let splitted = part.split(';');
        if (splitted != undefined)
          return splitted.shift() as string;
        else
          return undefined;
      }
    }
  };

  const setCookie = (name: string, val: string) => {
    const date = new Date();
    const value = val;

    // Set it expire in 7 days
    date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
    /*if (!isLocalhost) {
        document.cookie = name+"="+value+"; expires="+date.toUTCString()+"; path=/";
    } else {
        document.cookie = name+"="+value+"; expires="+date.toUTCString()+"; Secure; HttpOnly; path=/";
    }*/
  };

  const persistUserCookie = async ({ user, token, expirationInstant }: {
    user: ApiUserDtoV2,
    token: string,
    expirationInstant: string
  }) => {
    setCookie('expiration_instant', expirationInstant);
    setCookie('user', JSON.stringify(user));
    setCookie('connexion_date', moment().format('YYYY-MM-DD HH:mm'));
    await store.dispatch('fetchUser');
  };

  return { persistUserCookie, setCookie, getCookie, deleteCookie };
}