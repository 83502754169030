import api from "@/utils/api";
import envConfig from "@/utils/envConfig";
import {createApiUrlWithParams} from "@/apiCalls/apiUtil";

export const applyRule = (rule: string, journeyId: string) =>
  api.put(`${envConfig.RULES_ENGINE_ROUTE}/v3/rule/apply?journeyId=${journeyId}&name=${rule}`, {})
    .then((response) => response.data)
    .catch(() => {
      return false
    });

export const setRule = async (set: string, journeyId: string) => {
  const baseUrl = createApiUrlWithParams(`${envConfig.RULES_ENGINE_ROUTE}/v3/set/apply`, {
    journeyId: journeyId,
    name: set,
    globalSet: true,
    mode: 'NORMAL'
  })

  return api.put(baseUrl, {}).then(response => response.data);
}