import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

export const downloadDocument = (journeyId: string, documentId: string, contentType: string, accessToken: string): Promise<any> =>
    api.get(`${envConfig.STORAGE_ROUTE}/v2/documents/download/${documentId}`,
        {
            responseType : 'blob',
            responseEncoding : 'binary',
            headers :{
                "Content-Type": contentType
            }
        }
    ).then((response) => response);

export const downloadImage = (journeyId: string, documentId: string, accessToken: string): Promise<any> =>
    downloadDocument(journeyId, documentId, "image/jpeg, image/jpg, image/png, image/tiff, image/heic", accessToken);

export const downloadPDF = (journeyId: string, documentId: string, accessToken: string): Promise<any> =>
    downloadDocument(journeyId, documentId, "application/pdf", accessToken);
