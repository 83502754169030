import {log} from "echarts/types/src/util/log";

export let isLocalhost = window.location.hostname.includes("localhost");

const prefixHostName = window.location.hostname.split('portal.getmeelo.com')

const getEnv = (name: string) => process.env[name];

const envPrefix = isLocalhost ? 'dev.' : prefixHostName[0];


export default {
  BACKEND_ROUTE: "/api",
  HATCH_BACKEND_ROUTE: "/api/hatch",

  IAM_ROUTE: "/api/hatch/iam",
  TASK_ROUTE: "/api/hatch/tasks",
  ALERTS_ROUTE: "/api/hatch/alerting",
  STORAGE_ROUTE: "/api/hatch/storage",
  CONTEXT_ROUTE: "/api/hatch/context",
  AMLCFT_ROUTE: "/api/hatch/aml_cft",
  CHECK_VALUE_ROUTE: "/api/hatch/check_value",
  ENRICHMENT_ROUTE: "/api/hatch/enrichment",
  FACE_MATCHING_ROUTE: "/api/hatch/face_matching",
  TOOLBOX_ROUTE: "/api/hatch/toolbox",
  FRISK_ROUTE: "/api/hatch/frisk",
  DOCUMENT_ROUTE: "/api/hatch/document",
  OPENBANKING_ROUTE: "/api/hatch/open_banking",
  RULES_ENGINE_ROUTE: "/api/hatch/rules_engine",
  SCORING_ROUTE: "/api/hatch/scoring",
  TAX_REPORT_ANALYSIS_ROUTE: "/api/hatch/tax_report_analysis",
  TAX_NOTICE_ANALYSIS_ROUTE: "/api/hatch/tax_notice_analysis",
  UPLOAD_ROUTE: "/api/hatch/web_upload",
  CHECK_IBAN_ROUTE: "/api/hatch/check_iban",
  CHECK_COMPANY_ROUTE: "/api/hatch/check_company",
  TRANSLATION_ROUTE: "/api/hatch/translation",
  OPEN_DATA_ROUTE: "/api/hatch/open_data",

  TRACKING_HOST: getEnv("VUE_APP_TRACKING_HOST") || "https://" + envPrefix + "tracking.getmeelo.com",
  TRACKING_FINGERPRINT_SCRIPT_URL: getEnv("VUE_APP_TRACKING_FINGERPRINT_SCRIPT_URL") || 'https://s3.fr-par.scw.cloud/public.bucket.getmeelo.com/tracking/fingerprint.min.js',
  TRACKING_BEHAVIOUR_SCRIPT_URL: getEnv("VUE_APP_TRACKING_BEHAVIOUR_SCRIPT_URL") || 'https://s3.fr-par.scw.cloud/public.bucket.getmeelo.com/tracking/behavior-analytics.min.js',
  MAIL_FRAUD_REPORT: getEnv("MAIL_FRAUD_REPORT") || 'fraud-report@getmeelo.com',
  DOPPLER_ENVIRONMENT: getEnv("VUE_APP_DOPPLER_ENVIRONMENT") || 'SNAPSHOT',
  APM: {
    ENABLED: getEnv("VUE_APP_APM_ENABLED") ? getEnv("VUE_APP_APM_ENABLED") === 'true' : false,
    SERVER_URL: getEnv("VUE_APP_APM_SERVER_URLS") || 'https://apm.getmeelo.com',
    SERVICE_NAME: getEnv("VUE_APP_APM_SERVICE_NAME") || 'PORTAL_FRONTEND',
  },
  OPENBANKING_CALLBACK_URL: window.location.origin + "/open-banking-end"
}