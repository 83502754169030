<script setup lang="ts">
import {computed, ref} from 'vue';
import TwoFactorSecretInput from '@/components/input/TwoFactorSecretInput.vue';
import Button from '@/components/button/Button.vue';
import {translate} from '@/i18n';
import { AuthenticationResponse} from '@/apiCalls/login';
import {AxiosResponse} from 'axios';
import ContentCard from "@/components/card/ContentCard.vue";
import {useRoute, useRouter} from "vue-router";
import {useCookies} from "@/composables/useCookies";

const props = withDefaults(
    defineProps<{
      authenticationMethod: (secret: string, token: string) => Promise<AxiosResponse<AuthenticationResponse>>,
    }>(),
    {}
);

const route = useRoute();
const router = useRouter();
const cookies = useCookies();

const secret = ref(new Array(6).fill(''));

const contact = computed(() => {
  return route.query.contact as string;
})

const token = () => {
  return route.query.token as string;
}

const submit = async (e: SubmitEvent) => {
  e.preventDefault();
  const { data } = await props.authenticationMethod(secret.value.join(''), token());
  await cookies.persistUserCookie(data);
  await router.push("/home");
};
</script>

<template>
  <form v-if="['/login/2fa', '/login/2fa/setup', '/login/2fa/setup/validate'].includes($route.path)"
        class="two-factor-authentication-form"
        v-on:submit="submit">
    <p class="white">{{ `${translate('LOGIN_PAGE.TWO_FACTOR_AUTHENTICATION.MESSAGE_SEND')} : ${contact} ` }}</p>
    <p class="white">{{ translate('LOGIN_PAGE.TWO_FACTOR_AUTHENTICATION.FINALISATION_MESSAGE') }}</p>
    <TwoFactorSecretInput v-model:value="secret" class="two-factor-authentication-form__input"/>
    <Button type="submit" class="submit-button" :label="translate('BUTTONS.CONNEXION')"
            :disabled="!(secret.reduce((prev, curr) => prev && !isNaN(parseInt(curr)), true))"/>
  </form>
  <ContentCard v-else class="two-factor-authentication-form__content-card"
               :title="translate('LOGIN_PAGE.TWO_FACTOR_AUTHENTICATION.TITLE')">
    <form class="two-factor-authentication-form"
          v-on:submit="submit">
      <p>{{ `${translate('LOGIN_PAGE.TWO_FACTOR_AUTHENTICATION.MESSAGE_SEND')}: ${contact} ` }}</p>
      <p>{{ translate('LOGIN_PAGE.TWO_FACTOR_AUTHENTICATION.FINALISATION_MESSAGE') }}</p>
      <TwoFactorSecretInput v-model:value="secret"/>
      <Button type="submit" class="submit-button" :label="translate('BUTTONS.CONNEXION')"
              :disabled="!(secret.reduce((prev, curr) => prev && !isNaN(parseInt(curr)), true))"/>
    </form>
  </ContentCard>
</template>