import api from "@/utils/api";
import envConfig from "@/utils/envConfig";
import {AxiosResponse} from "axios";
import {lastMonth, lastWeekEnd, lastWeekStart, thisMonth, thisWeekEnd, thisWeekStart, today} from "@/utils/dateTime";

const periods = {
  today: {
    from: `${today.toLocaleDateString()} 00:00:00`
  },
  thisWeek: {
    from: `${thisWeekStart.toLocaleDateString()} 00:00:00`,
    to: `${thisWeekEnd.toLocaleDateString()} 00:00:00`
  },
  lastWeek: {
    from: `${lastWeekStart.toLocaleDateString()} 00:00:00`,
    to: `${lastWeekEnd.toLocaleDateString()} 00:00:00`
  },
  thisMonth: {
    from: `${thisMonth.toLocaleDateString()} 00:00:00`
  },
  lastMonth: {
    from: `${lastMonth.toLocaleDateString()} 00:00:00`,
    to: `${thisMonth.toLocaleDateString()} 00:00:00`
  },
  total: {
    from: "01/01/2017 00:00:00"
  }
};

export const myConsumption = async (services: Object) => {
  return await api.post(`${envConfig.TASK_ROUTE}/v1/tasks/consumption/me`,
    {
      tasks: {
        all: {},
        ...services
      },
      periods: periods
    }).then(response => response);
};

export const getAllTasks = (onlyMe: boolean): Promise<AxiosResponse<{
  type: string,
  subType: string,
  version?: string,
  label?: string,
  serviceName?: string;
}[]>> => {
  return api.get(`${envConfig.TASK_ROUTE}/v1/tasks/consumption/combinations?onlyMe=${onlyMe}`)
};

export const userConsumption = async (services: Object, userIds?: Array<number>) => {
  return await api.post(`${envConfig.TASK_ROUTE}/v1/tasks/consumption/users`,
    {
      userIds: userIds,
      tasks: {
        all: {},
        ...services
      },
      periods: periods
    }).then(response => response);
};

export const myCompanyConsumption = async (services: Object) => {
  return await api.post(`${envConfig.TASK_ROUTE}/v1/tasks/consumption/companies/me`,
    {
      tasks: {
        all: {},
        ...services
      },
      periods: periods
    }).then(response => response);
};

export const myTeamConsumption = async (services: Object) => {
  return await api.post(`${envConfig.TASK_ROUTE}/v1/tasks/consumption/team`,
    {
      tasks: {
        all: {},
        ...services
      },
      periods: periods
    }).then(response => response);
};