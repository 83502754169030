<script setup lang="ts">
import { computed } from 'vue';
import { defineProps, withDefaults } from 'vue';

const props = withDefaults(defineProps<{
  progress: number,
  color?: string,
  height?: string
}>(), {
  color: '#4caf50',
  height: '20px'
});

const progress = computed(() => props.progress);
const color = computed(() => props.color);
const height = computed(() => props.height);
</script>

<style scoped>
.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  transition: width 0.3s ease;
}
</style>

<template>
  <div class="progress-bar-container" :style="{ height: height }">
    <div class="progress-bar" :style="{ width: progress + '%', backgroundColor: color }"></div>
  </div>
</template>