import {apiBackOffice as api} from '@/utils/api';
import envConfig from '@/utils/envConfig';
import {IPageResponse} from '@/types/Page';
import {PlaceRequest, PlaceResponse, SearchPlaceRequest} from '@/types/Places';

export const searchPlaces = (data: SearchPlaceRequest) =>
    api.post<IPageResponse<PlaceResponse>>(`${envConfig.IAM_ROUTE}/v5/client/admin/places/search`, data);

export const createPlace = (data: PlaceRequest) =>
    api.post(`${envConfig.IAM_ROUTE}/v5/client/admin/places`, data);

export const updatePlace = (placeName: string, data: PlaceRequest) =>
    api.put(`${envConfig.IAM_ROUTE}/v5/client/admin/places/${placeName}`, data);

export const deletePlace = (placeName: string, force?: boolean) => api.delete(`${envConfig.IAM_ROUTE}/v5/client/admin/places/${placeName}?${force ? 'force=true' : ''}`);
