<template>
  <div class="container-openbanking">
    <PrintFolderInfo
        :entries="{
        uniqId: journeyId,
      }"
    />
    <PrintApplicantOB
        v-if="checkDataAvailability"
        :entries="{
        bankName: obFormattedFields.bankName,
        holder: obFormattedFields.holderName,
        account: obFormattedFields.accountName,
        iban: obFormattedFields.iban,
        mainAccount: obFormattedFields.topSecondaryAccount,
        monthSalaries: obFormattedFields.monthSalaries,
        monthRents: obFormattedFields.monthRents,
        monthEndRatio: obFormattedFields.monthEndRatio,
        balance: obFormattedFields.balance,
        topBankingSupervision: obFormattedFields.topBankingSupervision,
        topDeferredDebit: obFormattedFields.topDeferredDebit,
        monthEndBalance: obFormattedFields.monthEndBalance,
        globalTransactionsCount: obFormattedFields.globalTransactionsCount,
      }"
        :title="translate('PRINT.APPLICANT_OB.OB_TITLE')"
        :score="Math.round(obFormattedFields.score || 0)"
        :color="colorScoreOB"
    >
      <span class="ob-financial-analysis-period">
        {{
          `${translate("OPEN_BANKING.FINANCIAL_ANALYSIS_PERIOD")}: ${
              obFormattedFields.startStudyPeriod
          } ${translate("OPEN_BANKING.TO")} ${
              obFormattedFields.endStudyPeriod
          } `
        }}
      </span>
    </PrintApplicantOB>
    <PrintCard title="PRINT.OB_ANALYSIS_DETAIL" class="pagebreak">
      <div class="print-ob-analysis-detail-container">
        <BoxOutputOpenBankingDetail
            v-if="Object.keys(obFormattedFields).length > 0 && checkDataAvailability"
            :ob-data="obFormattedFields"
            :can-open-banking="permissionsSorted.includes('KIT_OB_OB_DETAILS_GLOBAL')" :permissions="permissionsSorted"
        />
        <div v-else>
          <div class="container-openbanking-content-no-data">
            <span>{{ translate('OPEN_BANKING.PROVIDER_ERROR') }}</span>
          </div>
        </div>
      </div>
    </PrintCard>

    <BoxInputStateFolder v-if="displayStateFolder" :case-number="businessId" :case-state="journeyIdStatus"
                         @delete-folder="openModalDeleteFolder" :states="states()" :motive="motive"
                         @save-folder="openModalEditFolder"/>
    <EditFolderModal v-model:open-modal="modalEditFolder" :case-number="businessId" :case-state="journeyIdStatus"
                     @confirm-selection="editFolder"/>
    <DeleteFolderModal v-model:open-modal="modalDeleteFolder" @confirm-selection="deleteFolder"
                       :case-number="businessId"/>
    <BoxInputOpenBanking
        v-if="!(openBankingFields) && permissionsSorted?.some(elt => elt.includes('KIT_OB_OB_INPUT_MAIL') || elt.includes('KIT_OB_OB_INPUT_TEXT_MESSAGE'))"
        @generate-link="generateLink"
        :prop-prefill-client-form="prefillClientForm"
        class="no-print" :permissions="permissionsSorted" :can-open-baking="openBankingInputPermissions"
    >
      <template v-if="openBankingLink != ''">
        <div class="openbanking-bloc-link" v-if="obStarted && !obEnded">
          <a :href="openBankingLink" target="_blank">
            <Button
                class="openbanking-bloc-link-button"
                @click="goOpenBanking"
                :label="translate('BUTTONS.JOURNEY_OPEN_BANKING')"
            />
          </a>
        </div>
      </template>
      <Loader v-if="beginOpenBanking && !obEnded"/>
    </BoxInputOpenBanking>
    <BoxOutputOpenBanking
        v-if="Object.keys(obFormattedFields).length>0"
        :ob-data="obFormattedFields"
        :color-score="colorScoreOB" class="no-print"
        :can-open-banking="permissionsSorted.includes('KIT_OB_OB_DETAILS_GLOBAL')" :company="user?.company?.name"/>
    <BoxOutputOpenBankingDetail
        v-if="Object.keys(obFormattedFields).length>0 && checkDataAvailability"
        class="no-print" :ob-data="obFormattedFields" @to-account-summary-event="handleAccountSummary"
        :can-open-banking="permissionsSorted.includes('KIT_OB_OB_DETAILS_GLOBAL')" :permissions="permissionsSorted"
    />
    <BoxOutputOpenBankingAlert
        v-if=" Object.keys(obFormattedFields).length>0 && checkDataAvailability"
        :ob-data="obFormattedFields" class="no-print"
        :can-open-banking="permissionsSorted.includes('KIT_OB_OB_ALERTS_GLOBAL')"
    />
    <BoxOutputOpenBankingBankStatement class="no-print" v-if="isAccountSummaryButtonClicked"
                                       :ob-data="obFormattedFields"/>
  </div>
</template>


<script lang="ts">
import Button from "@/components/button/Button.vue";
import {defineComponent} from "vue";
import {translate} from "@/i18n";
import BoxOutputOpenBankingAlert from "@/components/box-output/BoxOutputOpenBankingAlert.vue";
import OpenBankingMixin from "@/mixins/openBankingMixin";
import BoxOutputOpenBanking from "@/components/box-output/BoxOutputOpenBanking.vue";
import BoxOutputOpenBankingDetail from "@/components/box-output/BoxOutputOpenBankingDetail.vue";
import BoxInputOpenBanking from "@/components/box-input/BoxInputOpenBanking.vue";
import {mapGetters} from "vuex";
import PrintFolderInfo from "@/components/print/shared/PrintFolderInfo.vue";
import PrintCard from "@/components/print/shared/PrintCard.vue";
import PrintApplicantOB from "@/components/print/shared/PrintApplicantOB.vue";
import ContentCard from "@/components/card/ContentCard.vue";
import Loader from "@/components/loader/Loader.vue";
import BoxOutputOpenBankingBankStatement from "@/components/box-output/BoxOutputOpenBankingBankStatement.vue";
import EditFolderModal from "@/components/modal/EditFolderModal.vue";
import BoxInputStateFolder from "@/components/box-input/BoxInputStateFolder.vue";
import DeleteFolderModal from "@/components/modal/DeleteFolderModal.vue";
import {states} from "@/utils/statesFolder";

export default defineComponent({
  components: {
    DeleteFolderModal, BoxInputStateFolder, EditFolderModal,
    Loader,
    ContentCard,
    BoxInputOpenBanking,
    BoxOutputOpenBankingDetail,
    BoxOutputOpenBanking,
    BoxOutputOpenBankingAlert,
    BoxOutputOpenBankingBankStatement,
    Button,
    PrintFolderInfo,
    PrintCard,
    PrintApplicantOB,
  },
  mixins: [OpenBankingMixin],
  data() {
    return {
      colorScoreOB: "",
      prefillClientForm: {
        phone: {disabled: false, value: "+33"},
        email: {disabled: false, value: ""},
      } as any
    };
  },
  beforeUnmount() {
    window.removeEventListener(
        "resetOpenBanking",
        this.cleanOpenBankingAnalysis
    );
  },
  async created() {
    window.addEventListener("resetOpenBanking", this.cleanOpenBankingAnalysis);
  },
  methods: {
    states() {
      return states;
    },
    translate,
    cleanOpenBankingAnalysis() {
      this.cleanOpenBanking();
      this.prefillClientForm = {
        phone: {disabled: false, value: "+33"},
        email: {disabled: false, value: ""},
      };
      this.isAccountSummaryButtonClicked = false;
      this.journeyIdStatus = '';
    }
  },
  watch: {
    applicantData(data: any) {
      this.prefillClientForm.email.value = data?.email;
      this.prefillClientForm.phone.value =
          data?.mobilePhone && data.mobilePhone.length >= 5
              ? data.mobilePhone
              : null;
    },
    async obFormattedFields() {
      this.colorScoreOB = await this.defineColor("score_ob", this.obFormattedFields.score, this.journeyId);
    },
  },
  computed: {
    openBankingFields() {
      return Object.keys(this.obFormattedFields).length > 0;
    },
    ...mapGetters(['permissionsSorted']),
    openBankingInputPermissions() {
      return this.permissionsSorted?.some((elt: string | string[]) => elt.includes('KIT_OB_OB_INPUT_MAIL') || elt.includes('KIT_OB_OB_INPUT_TEXT_MESSAGE'))
    }
  }
})
</script>
