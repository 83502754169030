<template>
  <div class="user-card">
    <Button :label="translate('BUTTONS.RETURN')" class="user-card__return" @click="goToManageUser" />
    <h1 class="user-card__title">{{ translate('BACKOFFICE.USERS.USER_PROFILE') }}</h1>
    <div v-if="contentUserCard" class="user-card__content">
      <ResultCard :title="translate('ACCOUNT_PAGE.GENERAL_INFORMATION')"
                  class="user-card__content__general-information">
        <div class="user-card__content__general-information__user">
          <span>{{
              `${translate('BACKOFFICE.COMPANY')} : ${user?.company.name ? user?.company.name : ''}`
            }}</span>
          <span>{{
              `${translate('IDENTITY.NAME')} : ${contentUserCard?.username ? contentUserCard?.username : ''}`
            }}</span>
          <span>{{ `${translate('EMAIL')} : ${contentUserCard?.email ? contentUserCard?.email : ''}` }}</span>
          <span>{{ `${translate('PHONE')} : ${contentUserCard?.phone ? contentUserCard?.phone : ''}` }}</span>
          <span>{{
              `${translate('BACKOFFICE.FUNCTION')} : ${contentUserCard?.function ? contentUserCard?.function : ''}`
            }}</span>
          <span>{{
              `${translate('BACKOFFICE.USERS.PLACE')} : ${contentUserCard?.place ? contentUserCard?.place : ''}`
            }}</span>
          <span>{{
              `${translate('BACKOFFICE.USERS.GROUP')} : ${contentUserCard?.group ? contentUserCard?.group : ''}`
            }}</span>
          <span>
            <span>{{ `${translate('BACKOFFICE.USERS.SUPERVISOR')} : ` }}</span>
            <router-link to="" @click="displaySupervisor">{{
                `${contentUserCard?.supervisor ? contentUserCard?.supervisor.username : ''}`
              }}</router-link>
          </span>
          <span>{{
              `${translate('BACKOFFICE.ADVANCED_USER')} : ${contentUserCard?.roles && contentUserCard?.roles.includes('COMPANY_USER') ? translate('NO') : translate('YES')}`
            }}</span>
          <span>{{
              `${translate('BACKOFFICE.USERS.ACTIVE_ACCOUNT')} : ${contentUserCard?.enabled ? translate('YES') : translate('NO')}`
            }}</span>
          <span>{{ `${translate('BACKOFFICE.USERS.PROFILE')} : ` }}
            <span>{{ getRoles }}</span>
          </span>
        </div>
        <div v-if="compareRole(user, contentUserCard)" class="user-card__content__general-information__edit-action">
          <Button :label="translate('BUTTONS.MODIFY')" @click="openEditModal" />
          <BackOfficeUserModal v-if="contentEditModal" :modal-flag="editUserModalFlag" :content="contentEditModal"
                               :step="firstStepEdit"
                               @validate-action="validateUpdateUser" @next-step="nextStep"
                               @cancel-action="cancelUpdateUser"
                               :supervisors="supervisors" v-model:check-box="checkBoxRoleUpdate" :places="places"
                               :groups="groups" :user="user" :title="translate('BACKOFFICE.USERS.USER_MODIFICATION')"
                               :profiles="profiles" v-model:profiles-checked="profilesUser"
                               @back-first-page="nextStep" />
        </div>
      </ResultCard>
      <div class="user-card__content__right-block">
        <ResultCard :title="translate('BACKOFFICE.USERS.TECHNICAL_ACTIONS')"
                    class="user-card__content__right-block__actions">
          <Button v-if="contentUserCard?.enabled" :label="translate('BUTTONS.PASSWORD_RESET')"
                  class="user-card__content__right-block__actions__reset"
                  @click="resetPasswordModal" :disabled="canDoAction(user)" />
          <CustomModal v-model:open="resetPasswordModalFlag" class="backoffice-user-table__delete-modal">
            <ResultCard :title="translate('BACKOFFICE.USERS.RESET_PASSWORD.TITLE')">
              <div class="user-card__content__right-block__actions__reset-modal-message">
                  <span>{{
                      `${translate('BACKOFFICE.USERS.RESET_PASSWORD.MESSAGE_1')} ${contentUserCard?.username ? contentUserCard?.username : ''}
                      ${translate('BACKOFFICE.USERS.RESET_PASSWORD.MESSAGE_2')} ${contentUserCard?.email ? contentUserCard?.email : ''}`
                    }}</span>
              </div>
              <div class="user-card__content__right-block__actions__reset-modal-buttons">
                <Button :label="translate('BUTTONS.CANCEL')"
                        class="backoffice-user-table__delete-modal__actions__cancel"
                        @click="resetPasswordModal" />
                <Button :label="translate('BUTTONS.CONFIRM')"
                        class="user-card__content__right-block__actions__reset-modal-buttons__validate"
                        @click="validateResetPassword" />
              </div>
            </ResultCard>
          </CustomModal>
          <Button :label="translate('BUTTONS.ACTIVATE_DEACTIVATE_ACCOUNT')"
                  class="user-card__content__right-block__actions__deactivate-account"
                  @click="activateDeactivateAccount" />
          <Button :label="translate('BUTTONS.RESETTING_API_KEY')"
                  class="user-card__content__right-block__actions__resetting-api"
                  :disabled="canDoAction(user)"
                  @click="resetApiKeyModal" />
          <ResetApiKeyModal v-model:open-modal="resetApiKeyModalFlag" @validate-reset-api-key="validateResetApiKey"
                            :connected-user-id="user.id" :content-user-card="contentUserCard" />
        </ResultCard>
        <ResultCard :title="translate('ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.TITLE')"
                    class="user-card__content__right-block__two-authentication">
          <span>{{
              `${translate('ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.REQUIRED')} : ${contentUserCard?.isTwoFactorAuthenticationRequiredByCompany ? translate('YES') : translate('NO')}`
            }}</span>
          <span>{{
              `${translate('ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.MODE.TITLE')} : ${translate(contentUserCard?.twoFactorAuthenticationStatus ? `ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.MODE.${contentUserCard?.twoFactorAuthenticationStatus}` : `ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.MODE.DISABLED`)}`
            }}</span>
          <span>{{
              `${translate('ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.CHECKED')} : ${contentUserCard?.isTwoFactorAuthenticationChecked ? translate('YES') : translate('NO')}`
            }}</span>
        </ResultCard>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ContentCard from '@/components/card/ContentCard.vue';
import { translate } from '@/i18n';
import ResultCard from '@/components/card/ResultCard.vue';
import LabelValuePrinter from '@/components/LabelValuePrinter.vue';
import usersBackOfficeMixin from '@/mixins/usersBackOfficeMixin';
import {
  getGroups,
  getPlaces,
  getUserByUserId,
  getUsersByRole,
  patchUserByUserIdCompanyAdmin
} from '@/apiCalls/backOfficeApi';
import Button from '@/components/button/Button.vue';
import CustomModal from '@/components/modal/CustomModal.vue';
import TextInput from '@/components/input/TextInput.vue';
import BackOfficeUserModal from '@/components/backoffice/BackOfficeUserModal.vue';
import { mapGetters } from 'vuex';
import { compareRole } from '@/utils/backOffice';
import { ApiUserResponseDto } from '@/apiCalls/login';
import { getProfilesByCompanyAdmin } from '@/apiCalls/backOffice/admin/profiles';
import { getProfilesByCompanyManager } from '@/apiCalls/backOffice/manager/profiles';
import { useToast } from 'vue-toast-notification';
import { isNull } from 'lodash';
import ResetApiKeyModal from '@/components/modal/ResetApiKeyModal.vue';
import * as _ from 'lodash';

export default defineComponent({
  methods: {
    compareRole,
    getUserByUserId,
    translate,
    async activateDeactivateAccount() {
      this.contentUserCard = await patchUserByUserIdCompanyAdmin({
        userId: this.contentUserCard?.userId,
        enabled: !this.contentUserCard?.enabled
      });
      const toast = useToast();
      toast.success(translate('BACKOFFICE.USERS.UPDATE_STATUS'));
    },
    canDoAction(user: any) {
      return compareRole(user, this.contentUserCard) == -1;
    },
    resetPasswordModal() {
      this.resetPasswordModalFlag = !this.resetPasswordModalFlag;
    },
    resetApiKeyModal() {
      this.resetApiKeyModalFlag = !this.resetApiKeyModalFlag;
    },
    async validateResetPassword() {
      const userId = this.contentUserCard ? this.contentUserCard.userId : 0;
      if (!isNull(this.contentUserCard?.userId)) await this.sendEmailResetPassword(userId);
      this.resetPasswordModal();
    },
    async validateResetApiKey() {
      const userId = this.contentUserCard ? this.contentUserCard.userId : 0;
      if (!isNull(this.contentUserCard?.userId)) await this.sendEmailResetApiKey(userId);
      this.resetApiKeyModal();
    },
    editModal() {
      this.editUserModalFlag = !this.editUserModalFlag;
    },
    openEditModal() {
      // /!\ need to copy all objects contained in contentUserCard
      this.contentEditModal = _.clone(this.contentUserCard);
      this.contentEditModal?.supervisor ? this.contentEditModal.supervisor = _.clone(this.contentUserCard?.supervisor) : null;
      this.editModal();
    },
    cancelUpdateUser() {
      this.firstStepEdit = true;
      this.editModal();
    },
    nextStep() {
      this.firstStepEdit = !this.firstStepEdit;
    },
    async validateUpdateUser() {
      this.editModal();
      this.nextStep();
      if (this.checkBoxRoleUpdate) this.roleUpdate = 'COMPANY_MANAGER';
      await this.updateUser(this.contentEditModal, this.roleUpdate, this.profilesUser);
      this.contentUserCard = await getUserByUserId(this.$route.params.userId);
    },
    async displaySupervisor() {
      this.$router.push(`/back-office/users/${this.contentUserCard?.supervisor?.id}/card`);
      this.contentUserCard = await getUserByUserId(this.contentUserCard?.supervisor?.id);
    },
    goToManageUser() {
      this.$router.push('/back-office/users');
    }
  },
  components: {
    ResetApiKeyModal,
    BackOfficeUserModal, TextInput, CustomModal, LabelValuePrinter, ResultCard, ContentCard, Button
  },
  mixins: [usersBackOfficeMixin],
  async created() {
    this.contentUserCard = await getUserByUserId(this.$route.params.userId);
    this.supervisors = await getUsersByRole(['COMPANY_ADMIN', 'COMPANY_MANAGER']);
    this.groups = await getGroups();
    this.places = await getPlaces();
    const profilesApi = this.user.roles.includes('COMPANY_ADMIN') ? await getProfilesByCompanyAdmin() : await getProfilesByCompanyManager();
    this.profiles = profilesApi.filter((objet: any) => objet?.enabled)?.filter((obj: {
      name: string
    }) => obj.name !== 'ADMIN_ACCESS');
    this.profilesUser = this.contentUserCard?.profiles?.filter((profile: any) => profile.name !== 'ADMIN_ACCESS')?.map((item: any) => {
      return {
        name: item.name,
        enabled: item.enabled
      };
    });
  },
  data: () => ({
    contentUserCard: undefined as ApiUserResponseDto | undefined,
    contentEditModal: undefined as ApiUserResponseDto | undefined,
    deleteUserModalFlag: false,
    resetPasswordModalFlag: false,
    resetApiKeyModalFlag: false,
    editUserModalFlag: false,
    firstStepEdit: true,
    roleUpdate: 'COMPANY_USER' as 'COMPANY_MANAGER' | 'COMPANY_USER',
    checkBoxRoleUpdate: false,
    supervisors: [],
    groups: [],
    places: [],
    profiles: []
  }),
  computed: {
    ...mapGetters(['user']),
    getRoles() {
      let profiles = this.contentUserCard?.profiles?.map((elt: any) => elt.name);
      return profiles?.join(', ');
    }
  }
});
</script>

