import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

export const requestPasswordResetEmail = (email: string) => {
  return api.post(`${envConfig.IAM_ROUTE}/v5/password/forgot`, {
    email,
  });
};

export const resetPassword = (newPassword: string, resetToken: string) => {
  return api.post(`${envConfig.IAM_ROUTE}/v5/password/reset`, {
    resetToken,
    newPassword,
  });
};

export const sendEmailResetPasswordAdmin = (userId: number) => {
  return api.post(`${envConfig.IAM_ROUTE}/v5/client/admin/users/${userId}/password`);
};

export const sendEmailResetPasswordManager = (userId: number) => {
  return api.post(`${envConfig.IAM_ROUTE}/v5/client/manager/users/${userId}/password`);
};

