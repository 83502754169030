<script setup lang="ts">
import { translate } from '@/i18n';
import { ref } from 'vue';

const props = withDefaults(
  defineProps<{
    label: string;
    color: string;
    disabled: boolean;
    loading: boolean;
  }>(), {
    label: '',
    color: '',
    disabled: false,
    loading: false
  }
);

const color = ref(props.color);
</script>

<template>
  <template v-if="!loading">
    <button :class="`top-button ${disabled ? 'top-button-disabled' : ''}`" :disabled="disabled">{{ translate(label) }}
    </button>
  </template>
  <template v-else>
    <button :class="`top-button ${disabled ? 'top-button-disabled' : ''}`" :disabled="disabled">
      <div class="top-button-loading">
        <span class="loader"></span>
        {{ translate(label) }}
      </div>
    </button>
  </template>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.top-button {
  border-bottom: 1px solid v-bind(color);
}

.top-button-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-button-disabled {
  cursor: not-allowed;
}

.loader {
  border: 2px solid $pureWhite;
  border-top: 2px solid $blueLightMeelo;
  border-radius: 50%;
  width: .8rem;
  height: .8rem;
  margin-right: .3rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>