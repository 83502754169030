<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';

import { getStatusIcon } from '@/composables/formatKeyValuePairWithFunc';
import { translate } from '@/i18n';
import { KeyValueType } from '@/utils/sortArrayObjectKeys';

import ResultCard from '@/components/card/ResultCard.vue';

const props = defineProps<{
  checks: KeyValueType;
  type: string;
  provider: string;
  faceMatchingStatus: string;
  liveCheckStatus: 'SUCCESS' | 'IN_PROGRESS' | 'CREATED' | 'FAILED';
}>();

const localChecks = ref(props.checks);
const refuseReason = ref('');
const refuseMessage = ref('');

const controlChecks = (checkControlList: Array<string>, checkKey: string) => {
  if (!localChecks.value) {
    return;
  }

  localChecks.value[checkKey] = 'OK';
  let tabChecksValue = [];

  for (let i = 0; i < checkControlList.length; i++) {
    tabChecksValue.push(localChecks.value[checkControlList[i]]);
  }

  if (!tabChecksValue.includes('KO') && !tabChecksValue.includes('OK')) {
    localChecks.value[checkKey] = 'NONE';
  }

  if (tabChecksValue.includes('KO')) {
    localChecks.value[checkKey] = 'KO';
  }
};

const noDisplayCheck = [
  'DOC_TYPE',
  'MRZ_FIELDS_SYNTAX',
  'MRZ_CHECKSUMS',
  'MRZ_EXPECTED_FOUND',
  'MRZ_CLASSIFIER',
  'MRZ_ALIGNEMENT',
  'LAST_NAME',
  'PHOTO_CONFORMITY',
  'PHOTO_LOCATION',
  'DRIVING_LICENSE_VALIDITY',
  'DRIVING_LICENSE_DIGITS',
  'DOCUMENT_TYPE_IDENTIFIED',
  'SPECIMEN',
  'DOCUMENT_BLACKLISTED',
  'DOCUMENT_NATIONALITY',
  'SIDES_DATA_MATCHING',
  'DOCUMENT_ANALYZED',
  'SIDES_MODEL_MATCHING',
  'INVALIDATED_DOCUMENT',
  'REFUSE_REASON',
  'LIVE_CHECK_REFUSE_MESSAGE',
  'documentCategory',
  'authenticity',
  'authenticityReasons'
];

const checksWithOutDocType = computed(() => {
  if (props.type === 'DRIVING_LICENSE') {
    if (props.provider && props.provider === 'OPTION_1') {
      controlChecks(
        [
          'MRZ_FIELDS_SYNTAX',
          'MRZ_CHECKSUMS',
          'MRZ_EXPECTED_FOUND',
          'MRZ_CLASSIFIER'
        ],
        'MRZ_CONTROL'
      );
      controlChecks(['LAST_NAME'], 'MRZ_IDENTITY_COHERENT');
      controlChecks(['PHOTO_CONFORMITY', 'PHOTO_LOCATION'], 'PICTURE_CONTROL');
    }
    controlChecks(
      ['DRIVING_LICENSE_VALIDITY', 'DRIVING_LICENSE_DIGITS'],
      'DRIVING_LICENSE_VALIDITY'
    );
  } else {
    if (props.provider && props.provider === 'OPTION_1') {
      controlChecks(
        [
          'MRZ_FIELDS_SYNTAX',
          'MRZ_CHECKSUMS',
          'MRZ_EXPECTED_FOUND',
          'MRZ_CLASSIFIER',
          'MRZ_ALIGNEMENT'
        ],
        'MRZ_CONTROL'
      );
      controlChecks(
        ['LAST_NAME', 'FIRST_NAMES', 'BIRTHDATE'],
        'MRZ_IDENTITY_COHERENT'
      );
      controlChecks(['PHOTO_CONFORMITY', 'PHOTO_LOCATION'], 'PICTURE_CONTROL');
    }
  }

  return Object.keys(localChecks.value).filter(
    (elt) => !noDisplayCheck.includes(elt)
  );
});

const liveCheckInfo = computed(() => {
  const baseMessage = 'DOCUMENT_CONTROL.TABLE.RESULT.LIVE_CHECK';
  if (props.liveCheckStatus === 'FAILED')
    return {
      title: `${baseMessage}.FAILED_TITLE`,
      desc: `${baseMessage}.FAILED_INFO`
    };
  return {
    title: `${baseMessage}.IN_PROGRESS_TITLE`,
    desc: `${baseMessage}.IN_PROGRESS_INFO`
  };
});

const faceMatchingInfo = computed(() => {
  const baseMessage = 'DOCUMENT_CONTROL.TABLE.RESULT.FACE_MATCHING';
  if (['CREATED', 'IN_PROGRESS'].includes(props.faceMatchingStatus))
    return {
      title: `${baseMessage}.IN_PROGRESS_TITLE`,
      desc: `${baseMessage}.IN_PROGRESS_INFO`
    };
  return {
    title: `${baseMessage}.FAILED_OR_CANCELLED_TITLE`,
    desc: `${baseMessage}.${
      props.faceMatchingStatus === 'FAILED' ? 'FAILED_INFO' : 'CANCELLED_INFO'
    }`
  };
});

onMounted(() => {
  if (localChecks.value.REFUSE_REASON) {
    // @ts-ignore
    refuseReason.value = localChecks.value.REFUSE_REASON;
    delete localChecks.value.REFUSE_REASON;
  }

  if (localChecks.value.LIVE_CHECK_REFUSE_MESSAGE) {
    // @ts-ignore
    refuseMessage.value = localChecks.value.LIVE_CHECK_REFUSE_MESSAGE;
    delete localChecks.value.LIVE_CHECK_REFUSE_MESSAGE;
  }
});
</script>

<template>
  <ResultCard :title="translate('INFORMATIONS')">
    <div class="live-check-result">
      <div v-if="liveCheckStatus === 'SUCCESS'" class="live-check-result-success">
        <span>{{ translate('DOCUMENT_CONTROL.TABLE.RESULT.LIVE_CHECK.SUCCESS_TITLE') }}</span>
        <template v-if="!!refuseReason">
          <span class="underlined">{{ translate('DOCUMENT_CONTROL.TABLE.RESULT.LIVE_CHECK.ANALYSIS_RESULT') }}:</span>
          <div class="refused-reason-check">
            <span>
              <img :src="require(`/public/images/red-circle.png`)" alt="red-circle" class="id-check-results__icon" />
              {{ translate(`DOCUMENT_CONTROL.TABLE.RESULT.CHECKS.${refuseReason}`) }}
            </span>
          </div>
        </template>
        <template v-if="refuseMessage">
          <span class="underlined">{{ translate('DOCUMENT_CONTROL.TABLE.RESULT.LIVE_CHECK.ANALYST_MESSAGE') }}:</span>
          <span>{{ refuseMessage }}</span>
        </template>
      </div>
      <div v-if="['IN_PROGRESS', 'FAILED'].includes(liveCheckStatus)" class="live-check-result-not-success">
        <span class="title">{{ translate(liveCheckInfo.title) }}</span>
        <span class="info">{{ translate(liveCheckInfo.desc) }}</span>
      </div>
      <div v-if="['CREATED', 'IN_PROGRESS', 'FAILED', 'CANCELLED'].includes(faceMatchingStatus)"
           class="live-check-result-not-success">
        <span class="title">{{ translate(faceMatchingInfo.title) }}</span>
        <span class="info">{{ translate(faceMatchingInfo.desc) }}</span>
      </div>
    </div>
    <div class="id-check">
      <span class="id-check-results" v-for="doc in checksWithOutDocType">
        <img :src="require(`/public/images/${getStatusIcon(localChecks[doc] as string)}`)"
             :alt="getStatusIcon(localChecks[doc] as string)" class="id-check-results__icon" />
        {{ translate(`DOCUMENT_CONTROL.TABLE.RESULT.CHECKS.${doc}`) }}
      </span>
    </div>
  </ResultCard>
</template>
