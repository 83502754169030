<script setup lang="ts">
import { locale, translate } from '@/i18n';
import { isEmpty } from 'lodash';
import { computed } from 'vue';
import { useStore } from 'vuex';

import { formatSiren } from '@/utils/formatRegistrationNumber';

const props = defineProps<{
  docCreator: string;
  docCreationDate: Date;
  contextData: any;
}>();

const store = useStore();

const user = computed(() => store.getters.user);
const companyLogo = computed(() => store.getters.companyLogo);

const formattedCreationDate = computed(() =>
  new Date(props.docCreationDate)?.toLocaleString(locale)
);

const buildB2BTitle = () => {
  const idNumber =
    !isEmpty(props.contextData?.company_registration_number) &&
    props.contextData?.company_registration_number[0];
  return `${translate('PRINT.COVER_PAGE.DOC_B2B')}${
    idNumber ? ' - ' + formatSiren('FR', idNumber) : ''
  }`;
};

const buildB2CTitle = () => {
  const applicant =
    !isEmpty(props.contextData?.applicant) && props.contextData?.applicant[0];
  return `${translate('PRINT.COVER_PAGE.DOC_B2C')}${
    applicant ? ' - ' + applicant.firstname + ' ' + applicant.lastname : ''
  }`;
};

const buildOBTitle = () => {
  const obAccount =
    !isEmpty(props.contextData?.open_banking_account) &&
    props.contextData?.open_banking_account[0];

  return `${translate('PRINT.COVER_PAGE.DOC_OB')}${
    obAccount ? ' - ' + obAccount.iban : ''
  }`;
};

const docSynthesisTitle = computed(() => {
  if (isEmpty(props.contextData?.clientId)) return translate('PRINT.COVER_PAGE.DOC_SYNTHESIS');
  const clientId = props.contextData?.clientId.toLocaleLowerCase();

  if (clientId === 'portal-b2b') return buildB2BTitle();
  if (clientId === 'portal-b2c') return buildB2CTitle();
  if (clientId === 'open-banking') return buildOBTitle();

  return translate('PRINT.COVER_PAGE.DOC_ANALYSIS');
});
</script>

<template>
  <div class="print-cover-page">
    <span class="company-name">{{ user.company?.name }}</span>
    <img
      :src="require('/public/images/logo_vertical_meelo_blue.png')"
      alt="logo_vertical_meelo_blue"
      class="company-logo"
    />
    <span class="cover-page-title">{{
        translate('PRINT.COVER_PAGE.TITLE')
      }}</span>
    <span class="cover-page-subtitle">{{ docSynthesisTitle }}</span>
    <div class="creation-detail">
      <div class="detail">
        <span>{{ translate('PRINT.COVER_PAGE.CREATED_BY') }}:</span>
        {{ props.docCreator }}
      </div>
      <div class="detail">
        <span>{{ translate('PRINT.COVER_PAGE.CREATION_DATE') }}:</span>
        {{ formattedCreationDate }}
      </div>
    </div>
  </div>
</template>
