import { createStore } from 'vuex';
import login, { ApiUserResponseDto } from '@/apiCalls/login';
import { inject } from 'vue';
// @ts-ignore
import { Zo } from './vue-zo/build/main/core/Zo';
import _, { isString } from 'lodash';
import { formatLocaleDateTimeUTC } from '@/utils/dateTime';
import taskModule from '@/composables/useTaskData';

export interface PermissionResponse {
  name: string;
  description: string;
}

export interface ModuleResponse {
  name: string;
  description: string;
  permissions: PermissionResponse[];
}

export interface KitResponse {
  name: string;
  description: string;
  modules: ModuleResponse[];
}

const zo: Zo | undefined = inject('$zo');

export default createStore({
  modules: {
    taskModule
  },
  state: () => ({
    user: undefined as (undefined | any),
    parameters: {} as { [key: string]: string },
    companyParameters: {} as { [key: string]: string },
    kits: undefined as (undefined | KitResponse[]),
    modules: undefined as (undefined | any),
    permissionsSorted: undefined as (undefined | any),
    responseStatus: {},
    maintenacesSevices: [] as any[]
  }),
  mutations: {
    setUser(state, user?: ApiUserResponseDto) {
      state.user = user;
    },
    setParameters(state, parameters: { [key: string]: string }) {
      state.parameters = parameters;
    },
    setCompanyParameters(state, companyParameters: { [key: string]: string }) {
      state.companyParameters = companyParameters;
    },
    setKits(state, kits?: KitResponse[]) {
      state.kits = kits;
    },
    setModules(state, modules?: any[]) {
      state.modules = modules;
    },
    setPermissionsSorted(state, permissionsSorted?: any[]) {
      state.permissionsSorted = permissionsSorted;
    },
    setResponseStatus(state, responseStatus: { [key: string]: string }) {
      state.responseStatus = Object.assign({}, state.responseStatus, {
        ...responseStatus
      });
    },
    clearResponseStatus(state) {
      state.responseStatus = {};
    },
    setMaintenancesServices(state, services) {
      state.maintenacesSevices = services;
    }
  },
  getters: {
    user: ({ user }): ApiUserResponseDto | undefined => user,
    parameters: ({ parameters }) => parameters,
    companyParameters: ({ companyParameters }) => companyParameters,
    kits: ({ kits }) => kits,
    modules: ({ kits }) => (kitName: string): string[] => {
      return kits?.find((kit: { name: string; }) => kit.name === kitName)
        ?.modules.map(({ name }: { name: string }) => name) || [];
    },
    permissions: ({ kits }) => (kitName: string, moduleName: string): string[] => {
      return kits?.find((kit: { name: string; }) => kit.name === kitName)
        ?.modules?.find((m: { name: string; }) => moduleName === m.name)
        ?.permissions.map(({ name }: { name: string }) => name) || [];
    },
    permissionsSorted: ({ permissionsSorted }) => permissionsSorted,
    responseStatus: ({ responseStatus }) => responseStatus,
    companyLogo: ({ user }) => {
      const logoBase64 = user?.company?.logoBase64;
      return {
        src: logoBase64 ? `data:image/png;base64,${logoBase64}` : '/images/logo_vertical_meelo.png',
        alt: user?.company?.name
      };
    },
    maintenacesSevices: ({ maintenacesSevices }) => maintenacesSevices
  },
  actions: {
    async fetchUser({ commit }) {
      const { data: userData } = await login.getMe();
      const { data: parametersData } = await login.getParameters();
      const { data: kitsData } = await login.getKits();
      const { data: companyParametersData } = await login.getCompanyParameters();
      zo?.setRoles(userData.roles);
      const permissionsSorted = getPermissionsFromAPI(kitsData);
      zo?.setPermissions(permissionsSorted);
      commit('setUser', userData);
      commit('setParameters', parametersData);
      commit('setKits', kitsData);
      commit('setPermissionsSorted', permissionsSorted);
      commit('setParameters', parametersData);
      commit('setCompanyParameters', companyParametersData);
    },
    updateResponseStatus({ commit }, responseStatus) {
      commit('setResponseStatus', responseStatus);
    },
    clearResponseStatus({ commit }) {
      commit('clearResponseStatus');
    },
    setMaintenacesSevices({ commit }, services) {
      commit('setMaintenancesServices', services);
    }
  }
});

function getPermissionsFromAPI(kits: KitResponse[]): string[] {
  const perms = kits.map((kit, index) => {
    let kitName = kit.name;
    let moduleName: string;
    let permissionNames: any[] = [];
    kit.modules.map((module) => {
      moduleName = module.name;
      module.permissions.map(perm => {
        const name = `${kitName}_${moduleName}_${perm.name}`;
        permissionNames.push(name);
      });
    });
    return permissionNames;
  }).flat(1);

  return _.uniq(perms);
}
