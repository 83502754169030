<script setup lang="ts">
import { translate } from '@/i18n';
import { snakeCase } from 'lodash';

const props = withDefaults(defineProps<{
  iban: string;
  bic: string;
  bankName: string;
}>(), {
  iban: '',
  bic: '',
  bankName: ''
});
</script>
<template>
  <div class="check-iban-bank-container">
    <template v-for="(value, key) in props">
      <span>
        <span class="check-iban-bank-key-label">
          {{ translate(`DOCUMENT_CONTROL.TABLE.RESULT.BANK_DETAILS.${snakeCase(key).toUpperCase()}`) }}:
        </span>
        {{ value }}
      </span>
    </template>
  </div>
</template>
