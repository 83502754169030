import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import moment from 'moment/moment';
import { useToast } from 'vue-toast-notification';

import { translate } from '@/i18n';
import errorHandler from '@/middleware/responseHandler';
import { deleteLocalCookiesAndPushToLogin } from '@/utils/authUtils';
import { checkUserPayload } from '@/utils/filterUserPayload';
import { getTokenFromUrlParams } from '@/utils/getTokenFromUrlParams';
import { useCookies } from '@/composables/useCookies';

const toast = useToast();
const cookies = useCookies();

export function requestInterceptor({
                                     isBackOffice
                                   }: {
  isBackOffice: boolean;
}) {
  return (config: InternalAxiosRequestConfig<any>) => {
    const controller = new AbortController();
    if (
      isBackOffice &&
      config.method &&
      ['post', 'put'].includes(config.method)
    ) {
      try {
        checkUserPayload(config);
      } catch (e) {
        controller.abort();
        toast.error(translate('MALICIOUS_PAYLOAD'), { duration: 5 * 1000 });
      }
    }
    let accessToken =
      getTokenFromUrlParams() || cookies.getCookie('access_token');
    if (!accessToken) return config;
    if (config.url?.includes('/authenticate/2fa/setup/validate')) return config;
    let configWithHeaders;
    if (config.url?.includes('?')) config.url += '&';
    else config.url += '?';
    config.url += `access_token=${accessToken}`;
    configWithHeaders = {
      ...config,
      signal: controller.signal
    };
    return configWithHeaders;
  };
}

export function responseStatusInterceptor(response: AxiosResponse) {
  if (response.status >= 200 && response.status <= 299) {
    cookies.setCookie('connexion_date', moment().format('YYYY-MM-DD HH:mm'));
  }
  if (response.status === 404) {
    toast.warning(translate('ERRORS.NO_DATA_FOUND'), { duration: 5 * 1000 });
  }
  if (response.status >= 400 && response.status <= 499) {
    toast.error(translate('ERRORS.ERROR_OCCURRED'), { duration: 5 * 1000 });
  }
  return response;
}

export async function responseStatusErrorInterceptor(
  error: any
): Promise<never> {
  if (!error.response) return Promise.reject(error);
  if (error.response.status === 401) await deleteLocalCookiesAndPushToLogin();
  return Promise.reject(error);
}

export function portalResponseInterceptor(response: AxiosResponse) {
  const pathname = window.location.pathname;
  if (pathname.startsWith('/analysis-b2c')) {
    errorHandler.handleB2CResponseFailed(response);
  }
  if (pathname.startsWith('/analysis-b2b')) {
    errorHandler.handleB2BResponseFailed(response);
  }
  if (
    pathname.startsWith('/analysis-b2b') ||
    pathname.startsWith('/analysis-b2c')
  ) {
    errorHandler.handleB2BAndB2CResponseFailed(response);
  }
  return response;
}

export function portalResponseErrorInterceptor(error: AxiosError) {
  errorHandler.handleServerError(error);
  return Promise.reject(error);
}
