import {apiBackOffice as api} from "@/utils/api";
import envConfig from "@/utils/envConfig";
import {isEmpty} from "lodash";

export const createUserCompanyAdmin = (userName?: string, email?: string, enabled?: boolean, phone?: string,
                                       position?: string, group?: string, place?: string, role?: 'COMPANY_MANAGER' | 'COMPANY_USER') =>
  api.post(`${envConfig.IAM_ROUTE}/v5/client/admin/users`, {
    "username": userName,
    "email": email,
    "enabled": enabled,
    "phone": phone,
    "function": position,
    "group": group,
    "place": place,
    "role": role
  });

export const allUsersForCompany = (userName?: string, email?: string, enabled?: boolean, phone?: string,
                                   position?: string, group?: string, place?: string, profiles?: string, page?: number, size?: number,
                                   sortName?: string, sortDirection?: string) =>
  api.post(`${envConfig.IAM_ROUTE}/v5/client/manager/users/search`, {
    "page": page,
    "size": size,
    "sort": [
      {
        "name": sortName,
        "direction": sortDirection
      }
    ],
    "username": (userName === '' || userName?.replaceAll(' ', '') === '') ? undefined : userName,
    "email": (email === '' || email?.replaceAll(' ', '') === '') ? undefined : email,
    "enabled": enabled,
    "phone": (phone === '' || phone?.replaceAll(' ', '') === '') ? undefined : phone,
    "function": position,
    "group": (group === '' || group?.replaceAll(' ', '') === '') ? undefined : group,
    "place": (place === '' || place?.replaceAll(' ', '') === '') ? undefined : place,
    "profiles": isEmpty(profiles) ? undefined : [profiles]
  });

export const updateUserCompanyAdmin = (userName?: string, email?: string, enabled?: boolean, phone?: string,
                                       position?: string, group?: string, place?: string, role?: 'COMPANY_MANAGER' | 'COMPANY_USER', userId?: number, supervisor?: number, profiles?: string[]) =>
  api.put(`${envConfig.IAM_ROUTE}/v5/client/admin/users/${userId}`, {
    "username": userName,
    "email": email,
    "enabled": enabled,
    "phone": phone,
    "function": position,
    "group": group,
    "place": place,
    "role": role,
    "supervisorId": supervisor,
    "profiles": isEmpty(profiles) ? undefined : profiles
  });

export const updateUserCompanyManager = (userName?: string, email?: string, enabled?: boolean, phone?: string,
                                         position?: string, group?: string, place?: string, userId?: number, profiles?: string[]) =>
  api.put(`${envConfig.IAM_ROUTE}/v5/client/manager/users/${userId}`, {
    "username": userName,
    "email": email,
    "enabled": enabled,
    "phone": phone,
    "function": position,
    "group": group,
    "place": place,
    "profiles": isEmpty(profiles) ? undefined : profiles
  });

export const deleteUserCompanyAdmin = (userId?: number) =>
  api.delete(`${envConfig.IAM_ROUTE}/v5/client/admin/users/${userId}`);

export const addUserCompanyAdmin = (userName?: string, email?: string, enabled?: boolean, phone?: string,
                                    position?: string, group?: string, place?: string, role?: 'COMPANY_MANAGER' | 'COMPANY_USER', supervisor?: number, profiles?: string[]) =>
  api.post(`${envConfig.IAM_ROUTE}/v5/client/admin/users`, {
    "username": userName,
    "email": email,
    "enabled": enabled,
    "phone": phone,
    "function": position,
    "group": group,
    "place": place,
    "role": role,
    "supervisorId": supervisor,
    "profiles": profiles
  });

export const getUserByUserId = (userId: any) =>
  api.get(`${envConfig.IAM_ROUTE}/v5/client/manager/users/${userId}`)
    .then(response => response.data);

export const patchUserByUserIdCompanyAdmin = ({userId, userName, email, enabled, phone, position, group, place, role}: {
  userId?: number; userName?: string; email?: string; enabled?: boolean;
  phone?: string; position?: string; group?: string; place?: string;
  role?: 'COMPANY_MANAGER' | 'COMPANY_USER'
}) =>
  api.patch(`${envConfig.IAM_ROUTE}/v5/client/admin/users/${userId}`, {
    "username": userName,
    "email": email,
    "enabled": enabled,
    "phone": phone,
    "function": position,
    "group": group,
    "place": place,
    "role": role
  }).then(response => response.data);

export const getUsersByRole = (roles?: ('COMPANY_MANAGER' | 'COMPANY_USER' | 'COMPANY_ADMIN')[]) =>
  api.get(`${envConfig.IAM_ROUTE}/v5/client/manager/users?${roles ? 'roles=' + roles.join(',') : ''}`)
    .then(response => response.data);

export const getPlaces = () =>
  api.get(`${envConfig.IAM_ROUTE}/v5/client/manager/places`)
    .then(response => response.data);

export const getGroups = () =>
  api.get(`${envConfig.IAM_ROUTE}/v5/client/manager/groups`)
    .then(response => response.data);

export const addUserCompanyManager = (userName?: string, email?: string, enabled?: boolean, phone?: string,
                                      position?: string, group?: string, place?: string, supervisor?: number, profiles?: string[]) =>
  api.post(`${envConfig.IAM_ROUTE}/v5/client/manager/users`, {
    "username": userName,
    "email": email,
    "enabled": enabled,
    "phone": phone,
    "function": position,
    "group": group,
    "place": place,
    "supervisor": supervisor,
    "profiles": profiles
  });

