<script setup lang="ts">
import ContentCard from '@/components/card/ContentCard.vue';
import ScoreCircleChart from '@/components/ScoreCircleChart.vue';
import LabelValuePrinter from '@/components/LabelValuePrinter.vue';
import { translate } from '@/i18n';
import { ObData, Routes } from '@/utils/openBankingUtils';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import BoxOutputMultiRequests from '@/components/box-output/BoxOutputMultiRequests.vue';
import { searchCountryFlag } from '@/utils/countryCodes';

const route = useRoute();

const props = withDefaults(defineProps<{
  obData: ObData,
  isRecovery: boolean,
  colorScore: string,
  canOpenBanking: boolean,
  company: string
}>(), {
  isRecovery: false,
  colorScore: '#D9D9D9',
  canOpenBanking: false
});

const routesAndProAccountCond = (routes: string[]) => {
  return routes.includes(route.path) && props.obData.topProAccount;
};
const routesAndPerAccountCond = (routes: string[]) => {
  return routes.includes(route.path) && !props.obData.topProAccount;
};

const checkDataAvailability = computed(() => {
  return !(props.obData.iban === '-');
});

const displayStudyPeriod = computed(() => {
  return props.obData.startStudyPeriod !== '-' && props.obData.endStudyPeriod !== '-' ? `${props.obData.startStudyPeriod} ${translate('OPEN_BANKING.TO')} ${props.obData.endStudyPeriod}` : translate('OPEN_BANKING.UNDEFINED');
});

const displayAccountValuesPersonalAccount = computed(() => {
  const { obData } = props;
  const formatIban = (iban: string) => `${searchCountryFlag(iban.slice(0, 2))} ${iban.replace(/(.{4})/g, '$1 ')}`;

  return [
    { label: translate('OPEN_BANKING.BANK_NAME'), value: obData.bankName },
    { label: translate('OPEN_BANKING.HOLDER'), value: obData.holderName },
    { label: translate('OPEN_BANKING.ACCOUNT_NAME'), value: obData.accountName },
    { label: translate('OPEN_BANKING.IBAN'), value: formatIban(obData.iban) },
    { label: translate('OPEN_BANKING.MAIN_ACCOUNT'), value: obData.topSecondaryAccount },
    { label: translate('OPEN_BANKING.MONTH_SALARIES'), value: obData.monthIncomesSalariesAmountMean },
    { label: translate('OPEN_BANKING.MONTH_RENTS'), value: obData.monthRents },
    { label: translate('OPEN_BANKING.CATEGORIES.MONTH_END_RATIO'), value: obData.monthEndRatio },
    { label: translate('OPEN_BANKING.BALANCE'), value: obData.balance },
    {
      label: translate('OPEN_BANKING.MONTH_END_BALANCE'),
      value: obData.monthEndBalance,
      tooltip: translate('TOOLTIP.MONTH_END_BALANCE')
    }
  ];
});

const displayAccountValuesProfessionalAccount = computed(() => {
  const { obData } = props;
  const formatIban = (iban: string) => `${searchCountryFlag(iban.slice(0, 2))} ${iban.replace(/(.{4})/g, '$1 ')}`;

  return [
    { label: translate('OPEN_BANKING.BANK_NAME'), value: obData.bankName },
    { label: translate('OPEN_BANKING.HOLDER'), value: obData.holderName },
    { label: translate('OPEN_BANKING.ACCOUNT_NAME'), value: obData.accountName },
    { label: translate('OPEN_BANKING.IBAN'), value: formatIban(obData.iban) },
    { label: translate('OPEN_BANKING.CATEGORIES.MONTH_END_RATIO'), value: obData.monthEndRatio },
    { label: translate('OPEN_BANKING.BALANCE'), value: obData.balance },
    {
      label: translate('OPEN_BANKING.MONTH_END_BALANCE'),
      value: obData.monthEndBalance,
      tooltip: translate('TOOLTIP.MONTH_END_BALANCE')
    },
    { label: translate('OPEN_BANKING.GLOBAL_TRANSACTION_COUNT'), value: obData.globalTransactionsCount },
    { label: translate('OPEN_BANKING.AVERAGE_NUMBER_OF_TRANSACTIONS_BY_MONTH'), value: obData.monthTransactionsCount }
  ];
});
</script>

<template>
  <ContentCard v-if="!canOpenBanking" :checked-display-content="false" :is-padlock="true"
               :title="translate('OPEN_BANKING.RESULT_ANALYSIS')"
               :class="isRecovery ? 'container-openbanking-recovery container-openbanking-lite-result' : 'container-openbanking-lite-result'" />
  <template v-else>
    <ContentCard
      :class="isRecovery ? 'container-openbanking-recovery container-openbanking-lite-result' : 'container-openbanking-lite-result'"
      :title="translate('OPEN_BANKING.RESULT_ANALYSIS')">
      <template v-if="checkDataAvailability">
        <div class="container-openbanking-content">
          <div class="container-openbanking-content__left-block">
            <div class="financial-period">
              <span>{{
                  `${translate('OPEN_BANKING.FINANCIAL_ANALYSIS_PERIOD')}: ${displayStudyPeriod} `
                }}</span>
              <span
                v-if="routesAndProAccountCond([Routes.ANALYSIS_B2C])"
                class="financial-period__wrong-analysis no-print">{{
                  `⚠️ ${translate('OPEN_BANKING.WRONG_ANALYSIS_B2B')}`
                }}</span>
              <span v-else-if="routesAndPerAccountCond([Routes.ANALYSIS_B2B])"
                    class="financial-period__wrong-analysis no-print">{{
                  `⚠️ ${translate('OPEN_BANKING.WRONG_ANALYSIS_B2C')}`
                }}</span>
            </div>
            <div class="container-openbanking-content__account">
              <LabelValuePrinter
                v-if="routesAndPerAccountCond([Routes.OPEN_BANKING, Routes.ANALYSIS_B2C, Routes.SYNTHESIS])"
                v-for="account in displayAccountValuesPersonalAccount"
                :label="account.label" :value="account.value" :tooltip-title="account.tooltip" />
              <LabelValuePrinter v-else
                                 v-for="account in displayAccountValuesProfessionalAccount"
                                 :label="account.label"
                                 :value="account.value" :tooltip-title="account.tooltip" />
            </div>
          </div>
          <div>
            <ScoreCircleChart
              class="circle-chart score-open-banking"
              :circle-value="Math.round(obData.score)" :circle-width="'30%'"
              :tooltip="translate('TOOLTIP.SCORE_OPEN_BANKING')"
              :circle-color="colorScore"
              :helper-text-display="false"
            />
            <template v-if="routesAndPerAccountCond([Routes.OPEN_BANKING, Routes.ANALYSIS_B2C, Routes.SYNTHESIS])">
              <LabelValuePrinter
                v-if="routesAndPerAccountCond([Routes.OPEN_BANKING, Routes.SYNTHESIS])"
                :label="translate('OPEN_BANKING.TOP_BANKING_SUPERVISION')"
                :value="obData.topBankingSupervision" />
              <LabelValuePrinter :label="translate('OPEN_BANKING.DEFERRED_DEBIT')"
                                 :value="obData.topDeferredDebit" />
              <LabelValuePrinter :label="translate('OPEN_BANKING.GLOBAL_TRANSACTION_COUNT')"
                                 :value="obData.globalTransactionsCount" />
              <LabelValuePrinter :label="translate('OPEN_BANKING.AVERAGE_NUMBER_OF_TRANSACTIONS_BY_MONTH')" :value="obData.monthTransactionsCount" />
            </template>
          </div>
        </div>
        <BoxOutputMultiRequests v-if="obData?.multiRequests?.iban !== 0" :result="obData.multiRequests"
                                :title="translate('MULTI_REQUESTS.TITLE')"
                                :company="company" market-type="open-banking" />
      </template>
      <div v-else>
        <div class="container-openbanking-content-no-data">
          <span>{{ translate('OPEN_BANKING.PROVIDER_ERROR') }}</span>
        </div>
      </div>
    </ContentCard>
  </template>
</template>
