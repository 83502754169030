<script setup lang="ts">
import { translate } from '@/i18n';
import * as _ from 'lodash';
import { watch } from 'vue';

import {
  CheckType,
  formatDataChecks,
  formatKeyValuePairWithFunc,
  selectAndSortChecksByKey
} from '@/composables/formatKeyValuePairWithFunc';
import { KeyValueType } from '@/utils/sortArrayObjectKeys';

import LabelValuePrinter from '@/components/LabelValuePrinter.vue';
import ResultCard from '@/components/card/ResultCard.vue';
import AuthenticityResults from '@/components/document-analysis/AuthenticityResults.vue';
import XMasBonhomme from '@/components/x-mas/XMasBonhomme.vue';

const props = withDefaults(defineProps<{
  checks: Object,
  ocrStatus: string
}>(), {});

const sortKeys: KeyValueType[] = [
  {
    PAYMENT_INFO: [
      'remainingAmountToPay',
      'paymentDeadline',
      'remainingAmountToRepay',
      'creditorName',
      'account',
      'bankId'
    ]
  },
  { LEGAL_DEBTORS: ['identifier', 'right', 'designationAndAddress'] },
  { PROPERTIES_AND_BUILDINGS: ['addresses'] },
  {
    INFO: [
      'registrant',
      'taxNumber',
      'address',
      'ref',
      'roleNumber',
      'taxDepartment',
      'ownerNumber',
      'taxCommune',
      'serviceId',
      '',
      'issue',
      'assessment'
    ]
  }
];

const formatPaymentInfo = (paymentInfoData: KeyValueType) => {
  const _data = Object.assign({}, paymentInfoData);
  const remainingAmountToPay = _data.remainingAmountToPay as number;
  // Rules for remainingAmountToRepay
  if (remainingAmountToPay >= 0) {
    _data.remainingAmountToPay = `${remainingAmountToPay} EUR`;
    _data.remainingAmountToRepay = `-`;
  } else {
    _data.remainingAmountToRepay = `${remainingAmountToPay * -1} EUR`;
    _data.remainingAmountToPay = `-`;
  }
  return _data;
};

const sortedCheks = () => {
  let _checks: CheckType = {};
  _checks = formatKeyValuePairWithFunc({
    data: props.checks,
    key: 'PAYMENT_INFO',
    funcs: [formatPaymentInfo]
  }) as CheckType;
  return selectAndSortChecksByKey({ checks: _checks, sortKeys });
};

const formattedChecks = () => formatDataChecks(sortedCheks());

watch(
  () => props.checks,
  (currentChecks, _old) => {
    if (!_.isEmpty(currentChecks)) {
      formattedChecks();
    }
  }
);
</script>

<template>
  <div class="property-tax-results">
    <ResultCard
      v-if="!['FAILED', 'CANCELED'].includes(ocrStatus)"
      v-for="check in formattedChecks()"
      :title="translate(`DOCUMENT_CONTROL.TABLE.RESULT.PROPERTY_TAX.${check.transKey}`)">
      <div :class="`property-tax-results-${check.className}`">
        <template v-for="obj in check.value">
          <template v-if="!Array.isArray(obj)">
            <LabelValuePrinter
              v-if="!!obj.key"
              :label="
                translate(
                  `DOCUMENT_CONTROL.TABLE.RESULT.PROPERTY_TAX.${obj.transKey}`
                )
              "
              :value="obj.value"
            />
            <div v-else></div>
          </template>
          <template v-else>
            <template v-for="item in obj">
              <LabelValuePrinter
                v-if="!!item.key"
                :label="
                  translate(
                    `DOCUMENT_CONTROL.TABLE.RESULT.PROPERTY_TAX.${item.transKey}`
                  )
                "
                :value="item.value"
              />
              <div v-else></div>
            </template>
          </template>
        </template>
      </div>
    </ResultCard>
    <AuthenticityResults
      :title="translate('DOCUMENT_CONTROL.AUTHENTICITY_DETAILS')"
      :checks="checks.authenticityReasons" :status="checks.AUTHENTICITY_STATUS"
    />
  </div>
</template>
