export const emailDomains = {
  AF: ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com', 'mail.com'],
  AL: ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 'mail.com'],
  DZ: ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 'mail.com'],
  AO: ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 'mail.com'],
  AR: [
    'gmail.com',
    'yahoo.com.ar',
    'hotmail.com',
    'outlook.com',
    'icloud.com',
    'fibertel.com.ar',
    'live.com.ar',
    'ciudad.com.ar',
    'arnet.com.ar',
    'uol.com.ar'
  ],
  AU: [
    'gmail.com',
    'yahoo.com.au',
    'hotmail.com',
    'outlook.com',
    'icloud.com',
    'bigpond.com',
    'optusnet.com.au',
    'live.com.au',
    'aussiebb.com.au',
    'dodo.com.au'
  ],
  BR: [
    'gmail.com',
    'yahoo.com.br',
    'hotmail.com',
    'outlook.com',
    'icloud.com',
    'bol.com.br',
    'uol.com.br',
    'globo.com',
    'terra.com.br',
    'r7.com'
  ],
  BE: ['gmail.com', 'hotmail.com', 'outlook.com', 'skynet.be', 'telenet.be', 'proximus.be', 'yahoo.com', 'voo.be', 'icloud.com', 'live.com'],
  CA: [
    'gmail.com',
    'yahoo.ca',
    'hotmail.com',
    'outlook.com',
    'icloud.com',
    'sympatico.ca',
    'rogers.com',
    'bell.net',
    'shaw.ca',
    'telus.net'
  ],
  CN: [
    'qq.com',
    '163.com',
    '126.com',
    'sina.com',
    'sohu.com',
    'gmail.com',
    'hotmail.com',
    'yahoo.com.cn',
    '139.com',
    'aliyun.com'
  ],
  DE: [
    'gmail.com',
    'web.de',
    'gmx.de',
    't-online.de',
    'yahoo.de',
    'freenet.de',
    'hotmail.de',
    'outlook.de',
    'posteo.de',
    'mail.de'
  ],
  EG: ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 'mail.com'],
  FR: [
    'gmail.com',
    'yahoo.fr',
    'orange.fr',
    'hotmail.fr',
    'wanadoo.fr',
    'free.fr',
    'sfr.fr',
    'laposte.net',
    'bbox.fr',
    'neuf.fr'
  ],
  GB: [
    'gmail.com',
    'yahoo.co.uk',
    'hotmail.co.uk',
    'outlook.com',
    'icloud.com',
    'btinternet.com',
    'live.co.uk',
    'sky.com',
    'virginmedia.com',
    'talktalk.net'
  ],
  IN: [
    'gmail.com',
    'yahoo.in',
    'hotmail.com',
    'outlook.com',
    'icloud.com',
    'rediffmail.com',
    'in.com',
    'sify.com',
    'indiatimes.com',
    'airtelmail.in'
  ],
  IT: [
    'gmail.com',
    'yahoo.it',
    'libero.it',
    'hotmail.it',
    'outlook.it',
    'tiscali.it',
    'alice.it',
    'virgilio.it',
    'tin.it',
    'fastwebnet.it'
  ],
  JP: [
    'gmail.com',
    'yahoo.co.jp',
    'icloud.com',
    'hotmail.co.jp',
    'outlook.jp',
    'ezweb.ne.jp',
    'docomo.ne.jp',
    'au.com',
    'softbank.ne.jp',
    'rakuten.ne.jp'
  ],
  KR: [
    'naver.com',
    'daum.net',
    'gmail.com',
    'hotmail.com',
    'nate.com',
    'kakao.com',
    'yahoo.co.kr',
    'hanmail.net',
    'korea.com',
    'empal.com'
  ],
  MX: [
    'gmail.com',
    'yahoo.com.mx',
    'hotmail.com',
    'outlook.com',
    'icloud.com',
    'prodigy.net.mx',
    'live.com.mx',
    'mail.com',
    'aol.com',
    'msn.com'
  ],
  NG: ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 'mail.com'],
  RU: [
    'gmail.com',
    'yandex.ru',
    'mail.ru',
    'rambler.ru',
    'outlook.com',
    'icloud.com',
    'bk.ru',
    'inbox.ru',
    'list.ru',
    'hotmail.ru'
  ],
  SA: [
    'gmail.com',
    'yahoo.com.sa',
    'hotmail.com',
    'outlook.com',
    'icloud.com',
    'windowslive.com',
    'stc.com.sa',
    'go.com.sa',
    'arab.net',
    'mail.com'
  ],
  ZA: [
    'gmail.com',
    'yahoo.co.za',
    'hotmail.com',
    'outlook.com',
    'icloud.com',
    'mweb.co.za',
    'telkomsa.net',
    'webmail.co.za',
    'vodamail.co.za',
    'absa.co.za'
  ],
  ES: [
    'gmail.com',
    'yahoo.es',
    'hotmail.es',
    'outlook.com',
    'icloud.com',
    'terra.es',
    'telefonica.net',
    'orange.es',
    'ono.com',
    'movistar.es'
  ],
  TR: [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'outlook.com',
    'icloud.com',
    'mynet.com',
    'turk.net',
    'superonline.com',
    'ttnet.net.tr',
    'mail.com'
  ],
  AE: [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'outlook.com',
    'icloud.com',
    'emirates.net.ae',
    'eim.ae',
    'etisalat.ae',
    'du.ae',
    'mail.com'
  ]
};

export const globalEmailDomains = ['gmail.com', 'hotmail.com', 'outlook.com', 'icloud.com'];
