import { isEmpty, isEqual } from 'lodash';

const getAuthenticityFromId = (id: string[], authenticityData: any) => {
  if (isEmpty(authenticityData)) return false;
  for (let i = 0; i < authenticityData.length; i++) {
    if (authenticityData[i]) {
      if (authenticityData[i].status === 'CANCELLED') {
        return {
          index: i,
          authenticity: 'CANCELED',
          authenticityReasons: {
            REASONS: 'CANCELED',
            REASONS_DETAILS: 'CANCELED',
            REDIRECT_URL: ''
          },
          authenticityStatus: authenticityData[i]?.status
        };
      } else if (isEqual(id, authenticityData[i].documentIds)) {
        return {
          index: i,
          authenticity: authenticityData[i]?.authenticity,
          authenticityReasons: {
            REASONS: authenticityData[i]?.reasons,
            REASONS_DETAILS: authenticityData[i]?.reasonsDetails,
            REDIRECT_URL: authenticityData[i]?.redirectUrl
          },
          authenticityStatus: authenticityData[i]?.status
        };
      }
    }
  }
  return false;
};

export const addAuthenticityToCheck = (
  ctxData: any,
  authenticityDataKey: string
) => {
  const keys = [
    'tax_report_data',
    'kbis_data',
    'tax_notice_data',
    'payslip_data',
    'bank_details'
  ];
  const newContext = JSON.parse(JSON.stringify(ctxData));
  if (isEmpty(newContext)) return {};
  newContext[authenticityDataKey] = newContext[authenticityDataKey]?.filter(
    (authenticity: any) => authenticity
  );
  keys.forEach((key) => {
    newContext[key]?.forEach((data: any) => {
      const authenticityStatus = getAuthenticityFromId(
        data?.documentIds,
        newContext[authenticityDataKey]
      );

      if (authenticityStatus) {
        data.data.authenticity = authenticityStatus.authenticity;
        data.data.authenticityReasons = authenticityStatus.authenticityReasons;
        data.data.authenticityStatus = authenticityStatus.authenticityStatus;
        newContext[authenticityDataKey] = newContext[
          authenticityDataKey
          ]?.filter((authenticity: any, idx: number) => {
          return authenticity && idx !== authenticityStatus.index;
        });
      }
    });
  });
  return newContext;
};
