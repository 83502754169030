<script setup lang="ts">
import { isEmpty } from 'lodash';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toast-notification';
import { useStore } from 'vuex';

import { createJourneyIdByTask, getData } from '@/apiCalls/taskApi';
import {
  DOCUMENTS_TIMEOUT,
  DocumentTypeDto,
  allUploadedFilesOcrDone,
  createIframeUploadDocument,
  isAuthenticityDone,
  runFaceMatching
} from '@/apiCalls/uploadDocumentsApi';
import { usePermissions } from '@/composables/usePermissions';
import { translate } from '@/i18n';
import { handleUpload } from '@/utils/documentAnalysis';
import { DOCUMENTS_FIELDS } from '@/utils/dtoFields';
import { sleep } from '@/utils/sleep';

import BoxOutputDoc from '@/components/BoxOutputDoc.vue';
import CustomModal from '@/components/modal/CustomModal.vue';
import DocumentModalContent from '@/components/modal/document-analysis/DocumentModalContent.vue';
import FaceMatchingModalContent from '@/components/modal/document-analysis/FaceMatchingModalContent.vue';
import IDModalContent from '@/components/modal/document-analysis/IDModalContent.vue';
import PrintFolderInfo from '@/components/print/shared/PrintFolderInfo.vue';
import { setRule } from '@/apiCalls/rulesEngine';

const props = withDefaults(
  defineProps<{ showTitle: boolean; siren?: string }>(),
  {
    showTitle: true,
    siren: ''
  }
);

const emit = defineEmits(['loadTaxReportResult', 'startLoading', 'check-list']);

const router = useRouter();
const route = useRoute();
const toast = useToast();
const store = useStore();
const permissions = usePermissions();

const contextData = ref({});
const iframeLink = ref('');
const selectedDocCategory = ref<'ID' | 'OTHER'>();
const uploadMode = ref<'PHONE' | 'IFRAME'>('IFRAME');
const showModal = ref(false);
const isLoadingIframe = ref(false);
const isLoadingOcrResponse = ref(false);
const isLoadingIdOcrResponse = ref(false);
const isLoadingOtherDocOcrResponse = ref(false);
const isAuthenticity = ref(false);
const canSelectDocsType = ref(true);
const isFaceMatching = ref(false);
const timer = ref<NodeJS.Timeout>();

const journeyId = computed(() => {
  const query = router.currentRoute.value.query.journeyId;
  return query ? query.toString() : '';
});

const permissionsSorted = computed(() => store.getters.permissionsSorted);

const showUploadDocumentBlock = computed(() => {
  return permissions.docPermissions('UPLOAD_UPLOAD');
});

const permissionKitPath = computed(() => {
  const path = route.path;
  if (path.startsWith('/analysis-b2b')) return 'KIT_B2B_RESTITUTION';
  if (path.startsWith('/analysis-b2c')) return 'KIT_B2C_RESTITUTION';
  return 'KIT_DOC_RESTITUTION';
});

const showRestitutionDocumentBlock = computed(() => {
  const path = permissionKitPath.value;
  return permissionsSorted.value?.some(
    (elt: string | string[]) =>
      elt.includes(`${path}_DETAILS`) || elt.includes(`${path}_SOFT`)
  );
});

const permissionKitPathLabel = computed(() => {
  const path = route.path;
  if (path.startsWith('/analysis-b2b')) return 'KIT_B2B_UPLOAD';
  if (path.startsWith('/analysis-b2c')) return 'KIT_B2C_UPLOAD';
  return 'KIT_DOC_UPLOAD';
});

const hasDocumentPermission = (docType: string): boolean => {
  return permissionsSorted.value?.includes(
    `${permissionKitPathLabel.value}_${docType}`
  );
};

const documentType = computed(() => {
  return [
    {
      label: translate('DOCUMENT_CONTROL.RIB'),
      value: 'BANK_DETAILS',
      isPermitted: hasDocumentPermission('RIB')
    },
    {
      label: translate('DOCUMENT_CONTROL.KBIS_SELECT'),
      value: 'KBIS',
      isPermitted: hasDocumentPermission('KBIS')
    },
    {
      label: translate('DOCUMENT_CONTROL.TAX_REPORT'),
      value: 'TAX_REPORT',
      isPermitted: hasDocumentPermission('TAX_REPORT')
    },
    {
      label: translate('DOCUMENT_CONTROL.TAX_NOTICE_SELECT'),
      value: 'TAX_NOTICE',
      isPermitted:
        hasDocumentPermission('TAX_NOTICE_INCOME') &&
        hasDocumentPermission('TAX_NOTICE_PROPERTY')
    },
    {
      label: translate('DOCUMENT_CONTROL.PAYSLIP'),
      value: 'PAYSLIP',
      isPermitted: hasDocumentPermission('PAYSLIP')
    }
  ];
});

const canUploadIdCheckDoc = computed(() => hasDocumentPermission('ID_CHECK'));

const canFaceMatching = computed(() => {
  const path = route.path;
  let permissionName = 'KIT_DOC_FACE_MATCHING_GLOBAL';

  if (path.startsWith('/analysis-b2b')) permissionName = 'KIT_B2B_FACE_MATCHING_GLOBAL';
  if (path.startsWith('/analysis-b2c')) permissionName = 'KIT_B2C_FACE_MATCHING_GLOBAL';

  return permissionsSorted.value?.includes(permissionName);
});

const sendSms = async (phoneNumber: string) => {
  if (selectedDocCategory.value === 'ID') {
    await createIframeUploadDocument(
      journeyId.value,
      'ID_CHECK',
      'PHONE',
      phoneNumber
    );
    setTimeout(() => {
      showModal.value = false;
    }, 2000);
    emit('startLoading', 'ID_CHECK');
  }
};

const openModal = (category: 'ID' | 'OTHER', faceMatching = false) => {
  if (category === 'OTHER') iframeLink.value = '';
  selectedDocCategory.value = category;
  isFaceMatching.value = faceMatching;
  isAuthenticity.value = false;
  isLoadingIframe.value = false;
  canSelectDocsType.value = true;
  showModal.value = true;
  uploadMode.value = 'IFRAME';
  iframeLink.value = '';
};

const setBoxLoading = (
  category: 'ID' | 'OTHER' | undefined,
  value: boolean
) => {
  if (!category) isLoadingOcrResponse.value = value;
  if (category === 'ID') {
    isLoadingIdOcrResponse.value = value;
  } else {
    isLoadingOtherDocOcrResponse.value = value;
  }
};

const generateJourneyId = async () => {
  isLoadingIframe.value = true;
  const journey = await createJourneyIdByTask(
    'meelo-portal',
    'document-analysis'
  );
  isLoadingIframe.value = false;
  return journey.id;
};

const uploadDocument = async (
  uploadMode: 'PHONE' | 'IFRAME',
  category: 'ID' | 'OTHER',
  selectedDocTypes: DocumentTypeDto[]
) => {
  canSelectDocsType.value = false;
  let _journeyId = journeyId.value;
  if (!_journeyId) {
    _journeyId = await generateJourneyId();
    router.push({
      query: { journeyId: _journeyId }
    });
  }
  isLoadingIframe.value = true;
  iframeLink.value = await handleUpload({
    documentCategory: category,
    journeyId: _journeyId,
    uploadMode,
    documentTypes: category === 'OTHER' ? selectedDocTypes : []
  });
  isLoadingIframe.value = false;
};

const uploadIDDoc = async (uploadMode: 'PHONE' | 'IFRAME') => {
  openModal('ID');
  await uploadDocument(uploadMode, 'ID', []);
};

const uploadOtherDocument = (selectedDocTypes: DocumentTypeDto[]) => {
  uploadDocument('IFRAME', 'OTHER', selectedDocTypes);
};

const newFaceMatching = async (mobileNumber: string) => {
  let _journeyId = journeyId.value;
  if (!_journeyId) {
    _journeyId = await generateJourneyId();
    await router.push({
      query: { journeyId: _journeyId }
    });
  }
  await runFaceMatching(mobileNumber, journeyId.value);
  showModal.value = false;
  toast.clear();
  toast.success(translate('FACE_MATCHING_LINK_CREATED'));
  setBoxLoading('ID', true);
  setTimeout(async () => {
    await fetchOCR(true);
  }, 1000);
};

const fetchOCR = async (isOnMounted = false) => {
  const { data } = await getData(journeyId.value, DOCUMENTS_FIELDS);
  contextData.value = data.data;
  if (
    !isOnMounted &&
    contextData.value &&
    Object.keys(contextData.value).includes('tax_reports_result') &&
    // @ts-ignore
    !isEmpty(contextData.value['tax_reports_result'])
  ) {
    if (props.siren) {
      // @ts-ignore
      const taxReportData = contextData.value['tax_reports_result'].filter(
        // @ts-ignore
        (el) => el.context.siren === props.siren
      );
      emit('loadTaxReportResult', taxReportData);
    }
  }

  if (!isEmpty(contextData.value) && allUploadedFilesOcrDone(contextData.value)) {
    setBoxLoading(selectedDocCategory.value, false);

    if (route.path === '/analysis-b2c') {
      emit('check-list', true, 'CHECKLIST_B2C');
    }

    if (route.path === '/analysis-b2b') {
      emit('check-list', true, 'CHECKLIST_B2B');
    }
  }

  // @ts-ignore
  const loadingFaceMatching = contextData.value.face_matching.filter(
    (el: any) => ['CREATED', 'IN_PROGRESS'].includes(el.status)
  );

  if (
    !isEmpty(loadingFaceMatching) ||
    (!isEmpty(contextData.value) && !isAuthenticityDone(contextData.value))
  ) {
    await sleep(10 * 1000);
    await fetchOCR(false);
  }
};

const loadOCRResult = () => {
  const documentTimeOut =
    selectedDocCategory.value === 'ID'
      ? DOCUMENTS_TIMEOUT['ID_CHECK']
      : isAuthenticity.value
        ? DOCUMENTS_TIMEOUT['OTHER']
        : 45;
  toast.clear();
  toast.success(translate('UPLOAD_FINISHED'));
  setTimeout(() => {
    showModal.value = false;
  }, 2000);
  timer.value = setInterval(async () => {
    await fetchOCR();
  }, 15000);
  setTimeout(() => {
    clearInterval(timer.value);
  }, documentTimeOut * 1000);
};

onMounted(async () => {
  if (journeyId.value) {
    setBoxLoading(selectedDocCategory.value, true);
    await fetchOCR(true);
  }
  window.addEventListener('message', async (e: MessageEvent) => {
    if (e.data === 'OCR_DONE') {
      setBoxLoading(selectedDocCategory.value, true);
      await loadOCRResult();
    }
  });
});

onUnmounted(() => {
  clearInterval(timer.value);
});
</script>

<template>
  <div class="document-analysis">
    <h5 v-if="showTitle" class="title">{{ translate('SIDEBAR.DOCUMENT_ANALYSIS') }}</h5>
    <div class="document-analysis-box-container">
      <PrintFolderInfo :entries="{uniqId: journeyId,}" />
      <BoxOutputDoc @new-analysis="() => uploadIDDoc('IFRAME')" @new-face-matching="() => openModal('ID', true)"
                    :desc="translate('DOCUMENT_CONTROL.ID_ANALYSIS_DESCRIPTION')"
                    :is-loading="isLoadingIdOcrResponse || isLoadingOcrResponse" :context-data="contextData"
                    :can-upload-document="canUploadIdCheckDoc && showUploadDocumentBlock"
                    :can-restitute-document="showRestitutionDocumentBlock" :can-face-matching="canFaceMatching"
                    title="DOCUMENT_CONTROL.ID_ANALYSIS_TITLE" doc-category="ID" :can-show="!isLoadingOcrResponse" />
      <BoxOutputDoc @new-analysis="() => openModal('OTHER')"
                    :desc="translate('DOCUMENT_CONTROL.DOCUMENT_ANALYSIS_DESCRIPTION')"
                    :is-loading="isLoadingOtherDocOcrResponse || isLoadingOcrResponse" :context-data="contextData"
                    :can-upload-document="showUploadDocumentBlock"
                    :can-restitute-document="showRestitutionDocumentBlock" doc-category="OTHER"
                    title="DOCUMENT_CONTROL.DOCUMENT_ANALYSIS_TITLE" :can-show="!isLoadingOcrResponse" />
    </div>
  </div>
  <CustomModal :custom-class="`web-upload-modal ${isFaceMatching ? 'face-matching-modal' : ''}`"
               v-model:open="showModal">
    <div v-if="showModal && selectedDocCategory" class="document-analysis-modal">
      <IDModalContent v-if="selectedDocCategory === 'ID' && !isFaceMatching" :iframe-link="iframeLink"
                      :is-loading-iframe="isLoadingIframe" :upload-mode="uploadMode" @close-modal="showModal = false"
                      @send-sms="sendSms" />
      <DocumentModalContent v-if="selectedDocCategory === 'OTHER'" :can-select-docs-type="canSelectDocsType"
                            :document-type="documentType"
                            :has-authenticity-perms="hasDocumentPermission('AUTHENTICITY')" :iframe-link="iframeLink"
                            :is-loading-iframe="isLoadingIframe" :upload-mode="uploadMode"
                            @close-modal="showModal = false" @upload-document="uploadOtherDocument"
                            @toggle-authenticity="(val) => (isAuthenticity = val)" />
      <FaceMatchingModalContent v-if="isFaceMatching" @close-modal="showModal = false"
                                @startFaceMatching="newFaceMatching" />
    </div>
  </CustomModal>
</template>
