<script setup lang="ts">
import { translate } from '@/i18n';
import LabelValuePrinter from '@/components/LabelValuePrinter.vue';
import ResultCard from '@/components/card/ResultCard.vue';
import AuthenticityResults from '@/components/document-analysis/AuthenticityResults.vue';
import { Checks } from '@/utils/kbisResults';
import { useKbis } from '@/composables/useKbis';

const props = withDefaults(defineProps<{
  checks: Checks,
  ocrStatus: string
}>(), {});

const kbis = useKbis();
</script>
<template>
  <div class="kbis-results">
    <template v-if="['FAILED', 'CANCELED'].includes(ocrStatus)">
      <div class="kbis-results-first-block">
        <ResultCard :title="translate('DOCUMENT_CONTROL.TABLE.RESULT.KBIS.IDENTIFICATION')">
          <LabelValuePrinter v-for="(result, index) in kbis.identificationResults(props.checks)" :key="index"
                             class="kbis-results-label" :label="result.label" :value="result.value" />
        </ResultCard>
        <ResultCard :title="translate('DOCUMENT_CONTROL.TABLE.RESULT.KBIS.MAIN_ACTIVITY')">
          <template v-for="mainActivity in kbis.mainActivities(props.checks)">
            <LabelValuePrinter class="kbis-results-label" :label="mainActivity.label" :value="mainActivity.value" />
          </template>
        </ResultCard>
      </div>
      <ResultCard :title="translate('DOCUMENT_CONTROL.TABLE.RESULT.KBIS.MEMBERS')">
        <div class="kbis-results-members">
          <div>
            <LabelValuePrinter class="kbis-results-label"
                               :label="translate('DOCUMENT_CONTROL.TABLE.RESULT.KBIS.NATURAL_PERSON')" />
            <template v-for="(item, index) in kbis.getMembersResults(props.checks)" :key="index">
              <div v-if="item && item[0].label === translate('DOCUMENT_CONTROL.TABLE.RESULT.KBIS.IDENTITY')">
                <LabelValuePrinter class="kbis-results-label" v-for="(result, index) in item" :key="index"
                                   :label="result.label" :value="result.value" />
              </div>
              <div class="kbis-results-members-message" v-if="item && item.length === 0">
                <span>{{ translate('DOCUMENT_CONTROL.NO_PERSON_FOUND') }}</span>
              </div>
            </template>
          </div>
          <div>
            <LabelValuePrinter :label="translate('DOCUMENT_CONTROL.TABLE.RESULT.KBIS.LEGAL_PERSON')" />
            <template v-for="(item, index) in kbis.getMembersResults(props.checks)" :key="index">
              <div v-if="item && item[0].label === translate('DOCUMENT_CONTROL.TABLE.RESULT.KBIS.COMPANY_NAME')">
                <LabelValuePrinter class="kbis-results-label" v-for="(result, index) in item" :key="index"
                                   :label="result.label" :value="result.value" />
              </div>
              <div class="kbis-results-members-message" v-if="item && item.length === 0">
                <span>{{ translate('DOCUMENT_CONTROL.NO_PERSON_FOUND') }}</span>
              </div>
            </template>
          </div>
        </div>
      </ResultCard>
    </template>
    <AuthenticityResults :title="translate('DOCUMENT_CONTROL.AUTHENTICITY_DETAILS')"
                         :checks="checks.authenticityReasons" :status="checks.AUTHENTICITY_STATUS" />
  </div>
</template>
