import {locale} from "@/i18n";
import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

export const getPdfSynthesis = async (journeyId: string, filename: string) => {
    try {
        const response = await api.get(
            `${envConfig.BACKEND_ROUTE}/toolbox/synthesis?journeyId=${journeyId}&lang=${locale}&filename=${filename}`,
            {
                responseType: 'blob',
            });

        const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
}
