<template>
    <div class="account-page">
      <ContentCard class="account-page-content" :title="translate('SIDEBAR.MONITORING.MY_USAGE')">
        <div class="account-page__monitoring">
          <MonitoringCharts :total-label-usage="totalLabelUsage"
                            :total-usage="totalUsage"
                            :total-value-usage="totalValueUsage"
                            :color-usage="generateRandomColorsArray(totalValueUsage.length)"
                            consumption-to-display="MY_CONSUMPTION"
                            :is-monitoring-data-loading="isMonitoringDataLoading"/>
          <MonitoringValues :usage-without-all="usageWithoutAll"
                            :scores-usages="scoresUsages"/>
        </div>
      </ContentCard>
    </div>
  </template>

  <script lang="ts">
  import {defineComponent} from 'vue';
  import {translate} from '@/i18n';
  import ContentCard from '@/components/card/ContentCard.vue';
  import MonitoringValues from "@/components/monitoring/MonitoringValues.vue";
  import MonitoringCharts from "@/components/monitoring/MonitoringCharts.vue";
  import monitoringMixin from "@/mixins/monitoringMixin";
  
  export default defineComponent({
    components: {
      MonitoringCharts, MonitoringValues, ContentCard
    },
    mixins: [monitoringMixin],
    methods: {
      translate,
    }
  });
  </script>
