<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import TopButton from '@/components/button/TopButton.vue';
import { locale, translate } from '@/i18n';
import { handleUserLogout } from '@/utils/authUtils';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import ConnectAsModal from '@/views/connectAs/ConnectAsModal.vue';
import connectAsApi from '@/apiCalls/connectAsApi';
import { isEmpty, isNull } from 'lodash';
import { useToast } from 'vue-toast-notification';
import MenuBurger from './MenuBurger.vue';
import PortalInformationModal from '@/components/modal/PortalInformationModal.vue';
import { useHeaderBar } from '@/composables/useHeaderBar';
import { useJourneyId } from '@/composables/useJourneyId';
import Notification from '@/components/header/Notifications.vue';
import { getPdfSynthesis } from '@/apiCalls/toolBox';

const props = withDefaults(defineProps<{
  route: string
}>(), {});

const toast = useToast();
const store = useStore();
const router = useRouter();
const headerBar = useHeaderBar();
const journeyId = useJourneyId();
const routePath = useRoute();

const disabledSynthesis = ref(false);
const showMenu = ref(false);
const modalConnectAs = ref(false);
const modalPortalInformation = ref(false);
const companySelected = ref('');
const userSelected = ref('');
const openedComponent = ref('');
const routes = ['/analysis-b2c', '/analysis-b2b', '/synthesis', '/open-banking', '/recovery'];

const pdfSynthesisURL = ref('');

const user = computed(() => store.getters.user);

const badgeClass = computed(() => {
  return props.route?.startsWith('/back-office')
    ? 'header-bar__identifier backoffice__identifier'
    : !isNull(user.value.realUser)
      ? 'header-bar__identifier connect-as'
      : 'header-bar__identifier';
});

const LocaleSwitcherClass = computed(() => {
  return props.route?.startsWith('/back-office') ? 'switch-lang-backoffice' : '';
});

const usernameBadge = computed(() => {
  return !isEmpty(user.value) ? user.value.username : '';
});

const displayDownloadTopButton = computed(() => {
  return ((props.route === '/analysis-b2b' || props.route === '/open-banking' || props.route === '/analysis-b2c') && journeyId.getJourneyId.value) || (props.route === '/synthesis');
});

const updateUserInfo = () => {
  userSelected.value = '';
  companySelected.value = '';
  modalConnectAs.value = false;
};

const logoutAs = async () => {
  toast.info(`${translate('CONNECT_AS.TOAST.LOGOUT')} ${userSelected.value}`);
  await connectAsApi.logoutConnectAs();
  await store.dispatch('fetchUser');
  updateUserInfo();
  await router.push('/home');
  location.reload();
};

const logOut = async () => {
  if(!isNull(user.value.realUser)) {
    await connectAsApi.logoutConnectAs();
    await store.dispatch('fetchUser');
    updateUserInfo();
  }
  await handleUserLogout();
};

const handleComponentOpened = (componentName: string) => {
  openedComponent.value = componentName;
};

const toggleMenu = async (label: string) => {
  showMenu.value = !showMenu.value;
  switch (label) {
    case 'MENU.LOG_AS':
      modalConnectAs.value = true;
      break;
    case 'MENU.PORTAL_INFORMATION':
      modalPortalInformation.value = true;
      break;
    case 'MENU.LOGOUT_AS':
      await logoutAs();
      break;
    case 'MENU.LOGOUT':
      await logOut();
      break;
  }
};

async function startDownload() {
  disabledSynthesis.value = true;
  await getPdfSynthesis(journeyId.getJourneyId.value, `${journeyId.getJourneyId.value}_dossier_synthese.pdf`);
  disabledSynthesis.value = false;
}

watch(() => routePath.path, () => {
  if (routePath.path?.startsWith('/synthesis')) {
    journeyId.getDetailsForJourneyId(journeyId.getJourneyId.value);
  }
});
</script>

<template>
  <div class="header-bar">
    <div v-if="routes.includes(route)"
         class="header-bar__group-button">
      <TopButton v-for="button in headerBar.getInfoButtons(route, journeyId.getJourneyId, journeyId.details.value)"
                 :color="button?.color"
                 :label="button?.title"
                 @click="headerBar.navigate(route, button?.route)"
                 class="top-button"
      />
      <TopButton v-if="displayDownloadTopButton" v-on:click="startDownload()" :loading="disabledSynthesis"
                 :label="translate('BUTTONS.EXPORT_PDF')" color="#ff7676" :disabled="disabledSynthesis"
      />
    </div>
    <div class="locale-logout-container">
      <LocaleSwitcher :class="LocaleSwitcherClass" />
      <div v-if="!isEmpty(user)" :class="badgeClass">
        <div v-if="(companySelected && userSelected) || user.realUser !== null"
             class="header-bar__identifier__user-name-connect-as">
          <span class="header-bar__identifier__user-name-connect-as__content">
            {{ user.realUser?.username }} {{ translate('CONNECT_AS.BADGE') }}</span>
          <span class="strong-name">{{ user.username }}</span>
        </div>
        <span v-else class="header-bar__identifier__user-name">
          {{ usernameBadge }}
        </span>
        <Notification :opened-component="openedComponent" @notifications-opened="handleComponentOpened" />
        <div>
          <MenuBurger
            :show-menu="showMenu"
            :opened-component="openedComponent"
            :menu-class="`${(companySelected && userSelected) || user.realUser !== null ? 'menu-connect' : 'menu'}`"
            @click="toggleMenu" @menu-burger-opened="handleComponentOpened">
            <div class="menu-list">
              <router-link v-for="(item, index) in headerBar.menuFiltered(user, userSelected, companySelected, route)"
                           :key="index"
                           :to="item.route"
                           @click="toggleMenu(item.label)"
                           class="menu-list-item">
                <img :src="require(`../../../public/images/${item.imageSrc}`)"
                     :alt="require(`../../../public/images/${item.imageSrc}`)" class="menu-item-icon">
                <span
                  :class="`${translate(item.label) === translate('MENU.LOGOUT_AS') && user && user.realUser !== null ? 'disconnect-option' : '' }`">
                  {{ translate(item.label) }}</span>
              </router-link>
            </div>
          </MenuBurger>
          <ConnectAsModal v-model:openModal="modalConnectAs" @confirm-selection="updateUserInfo" />
          <PortalInformationModal v-model:openModal="modalPortalInformation" />
        </div>
      </div>
    </div>
  </div>
</template>