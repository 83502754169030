import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "account-page" }
const _hoisted_2 = { class: "account-page-title" }
const _hoisted_3 = { class: "account-page-content-block" }
const _hoisted_4 = { class: "account-page-content-action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XMasBonhomme = _resolveComponent("XMasBonhomme")!
  const _component_LabelValuePrinter = _resolveComponent("LabelValuePrinter")!
  const _component_ResultCard = _resolveComponent("ResultCard")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ContentCard = _resolveComponent("ContentCard")!
  const _component_TwoFactorAuthenticationSetupForm = _resolveComponent("TwoFactorAuthenticationSetupForm")!
  const _component_TwoFactorAuthenticationForm = _resolveComponent("TwoFactorAuthenticationForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_XMasBonhomme, {
      style: {top: '34vh', left: '75vw'},
      value: 15
    }),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.translate('ACCOUNT_PAGE.TITLE')), 1),
    _createVNode(_component_ContentCard, {
      class: "account-page-content",
      title: _ctx.translate('ACCOUNT_PAGE.INFORMATION')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ResultCard, {
            title: _ctx.translate('ACCOUNT_PAGE.GENERAL_INFORMATION')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LabelValuePrinter, {
                label: _ctx.translate('ACCOUNT_PAGE.COMPANY'),
                value: _ctx.user?.companyName
              }, null, 8, ["label", "value"]),
              _createVNode(_component_LabelValuePrinter, {
                label: _ctx.translate('ACCOUNT_PAGE.USERNAME'),
                value: _ctx.user?.username
              }, null, 8, ["label", "value"]),
              _createVNode(_component_LabelValuePrinter, {
                label: _ctx.translate('ACCOUNT_PAGE.EMAIL'),
                value: _ctx.email
              }, null, 8, ["label", "value"]),
              _createVNode(_component_LabelValuePrinter, {
                label: _ctx.translate('ACCOUNT_PAGE.PHONE_NUMBER'),
                value: _ctx.phone ? _ctx.phone : _ctx.translate('ACCOUNT_PAGE.PHONE_NUMBER_NOT_SPECIFIED')
              }, null, 8, ["label", "value"])
            ]),
            _: 1
          }, 8, ["title"]),
          _createVNode(_component_ResultCard, {
            title: _ctx.translate('ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.TITLE')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LabelValuePrinter, {
                label: _ctx.translate('ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.REQUIRED'),
                value: _ctx.user?.company?.twoFactorRequired ? _ctx.translate('YES') : _ctx.translate('NO')
              }, null, 8, ["label", "value"]),
              _createVNode(_component_LabelValuePrinter, {
                label: _ctx.translate('ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.MODE.TITLE'),
                value: _ctx.translate( `ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.MODE.${_ctx.user?.twoFactorStatus !== 'DISABLED' ? _ctx.user?.twoFactorStatus : 'DISABLED'}`)
              }, null, 8, ["label", "value"]),
              _createVNode(_component_LabelValuePrinter, {
                label: _ctx.translate('ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.CHECKED'),
                value: _ctx.user?.isTwoFactorAuthenticationChecked ? _ctx.translate('YES') : _ctx.translate('NO')
              }, null, 8, ["label", "value"])
            ]),
            _: 1
          }, 8, ["title"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Button, {
            label: _ctx.translate('ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.EDIT'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pushEditQuery(_ctx.edit === 'mode' ? undefined : 'mode')))
          }, null, 8, ["label"])
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    (_ctx.edit === 'mode')
      ? (_openBlock(), _createBlock(_component_TwoFactorAuthenticationSetupForm, {
          key: 0,
          onSuccess: _ctx.setup2FA,
          "with-disabled": !_ctx.user?.isTwoFactorAuthenticationRequiredByCompany,
          "is-required-by-company": _ctx.user?.isTwoFactorAuthenticationRequiredByCompany,
          email: _ctx.email,
          "is-checked": _ctx.isAlready2FA,
          "authentication-status": _ctx.user?.twoFactorStatus
        }, null, 8, ["onSuccess", "with-disabled", "is-required-by-company", "email", "is-checked", "authentication-status"]))
      : (_ctx.edit === 'validate')
        ? (_openBlock(), _createBlock(_component_TwoFactorAuthenticationForm, {
            key: 1,
            "authentication-method": _ctx.authMethod
          }, null, 8, ["authentication-method"]))
        : _createCommentVNode("", true)
  ]))
}