<script setup lang="ts">
import Button from "@/components/button/Button.vue";
import {translate} from "@/i18n";
import {computed, onMounted, ref, watch} from "vue";
import Select from "@/components/select/Select.vue";
import {isArray, isEmpty, isNil, size} from "lodash";
import {Motive} from "@/utils/journeyUtils";
import {getAllMotives} from "@/apiCalls/taskApi";
import TextArea from "@/components/input/TextArea.vue";
import {useToast} from "vue-toast-notification";

const toast = useToast();

const props = withDefaults(
    defineProps<{
      caseNumber: string;
      caseState: string;
      journeyId: string;
      states: Array<string>;
      motive: Motive;
    }>(), {
      caseNumber: '',
      caseState: '',
      journeyId: ''
    }
);

const emit = defineEmits(["delete-folder", "edit-folder", "save-folder", "update:motive"]);

// Flags & Constants
const displaySaveButton = ref(false);
const motiveDescription = ref('');
const maxDescriptionLength = 250;

const state = ref('');
const motives = ref([] as Motive[]);
const motive = ref({} as Motive);
const newMotive = ref({} as Motive);

const currentMotiveId = ref('');

const disabledDescription = computed(() => {
  return (
      (newMotive.value.id === '-1' || newMotive.value.id === '') &&
      (props.motive.id === '' || currentMotiveId.value === '') &&
      (newMotive.value.id === '-1' || newMotive.value.id === '' || props.motive.id === '')
  ) || (newMotive.value.id === '' && props.motive.id === '');
});

const isNewMotiveEmpty = computed(() => {
  return !newMotive.value.id && !newMotive.value.label && !newMotive.value.description;
});

const disabledSaveButton = computed(() => {
  const isMotiveUnchanged = props.motive.label === newMotive.value.label && props.motive.description === newMotive.value.description;
  const isStateUnchanged = state.value === props.caseState;
  const isDescriptionTooLong = motiveDescription.value.length > maxDescriptionLength;
  return (isMotiveUnchanged && isStateUnchanged) || (isNewMotiveEmpty.value && isStateUnchanged) || isDescriptionTooLong;
});

const statesValues = computed(() => {
  return !isEmpty(props.states) && isArray(props.states) ? props.states?.map((elt: any) => elt) : [];
});
const statesOptions = computed(() => {
  return !isEmpty(props.states) && isArray(props.states) ? props.states?.map((elt: any) => translate(`FOLDER.STATE.${elt}`)) : [];
});

const motiveValues = computed(() => {
  return !isEmpty(motives.value) && isArray(motives.value) ? motives.value?.map((elt: any) => elt.id) : [];
});
const motiveOptions = computed(() => {
  return !isEmpty(motives.value) && isArray(motives.value) ? motives.value?.map((elt: any) => elt.label) : [];
});

const cancelFolder = () => {
  newMotive.value = {...props.motive};
  motiveDescription.value = props.motive.description || '';
  state.value = props.caseState;
  displaySaveButton.value = false;
};

const deleteFolder = (item: Object) => {
  emit('delete-folder', item);
};

const editFolder = () => {
  displaySaveButton.value = !displaySaveButton.value;
  emit('edit-folder');
};

const saveFolder = () => {
  emit('save-folder', state.value, props.caseState, newMotive.value);
  displaySaveButton.value = !displaySaveButton.value;
};

const newMotiveInit = () => {
  newMotive.value = {id: '', label: '', description: ''};
}

const nullMotiveHelper = () => {
  return {id: '-1', label: '', description: ''};
}

const computeNewState = async (value: string) => {
  // compute new motives list based on the selected state
  motives.value = await getAllMotives(-1, -1, '', value, false);
  newMotive.value.id = '';
  newMotive.value.label = '';
  newMotive.value.description = '';
  currentMotiveId.value = '';
  motiveDescription.value = '';
}

// Motive create
const computeNewMotiveId = (value: string) => {
  value !== '' ?
      newMotive.value = {
        id: value,
        label: motives.value.find((motive) => motive.id === value)?.label || '',
        description: ''
      } : newMotive.value = nullMotiveHelper();
  motiveDescription.value = '';
}

const computeMotiveDescription = (value: string) => {
  if (!newMotive.value.id) newMotive.value.id = props.motive.id;
  if (!newMotive.value.label) newMotive.value.label = props.motive.label;
  newMotive.value.description = value;
}

onMounted(async () => {
  motives.value = await getAllMotives(-1, -1, '', props.caseState, false);
  motive.value = props.motive;
  motiveDescription.value = props.motive.description || '';
  currentMotiveId.value = props.motive.id || '';
  newMotiveInit();
  state.value = props.caseState;
});

watch(() => props.motive, (mMotive) => {
  motive.value = mMotive;
}, {deep: true});

</script>

<template>
  <div class="box-input-state-folder">

    <div class="box-input-state-folder__wrapper">
      <div class="box-input-state-folder__content">
        <div>
          <div class="box-input-state-folder__wrapper__paragraph">
            <span class="box-input-state-folder__underline">{{ `${translate('TABLE.FILE_NUMBER')} :` }}</span>
            <span class="box-input-state-folder__label">{{ ` ${props.caseNumber}` }}</span>
          </div>
          <div class="box-input-state-folder__wrapper__paragraph">
            <span class="box-input-state-folder__underline">{{ `${translate('STATE')} :` }}</span>
            <span v-if="!displaySaveButton"
                  class="box-input-state-folder__label">{{ ` ${translate(`FOLDER.STATE.${props.caseState}`)}` }}</span>
            <Select v-else v-model:value="state" :values="statesValues" @update:value="computeNewState"
                    :options="statesOptions" class="box-input-state-folder__select-state"/>
          </div>
          <div class="box-input-state-folder__wrapper__paragraph">
            <span class="box-input-state-folder__underline__motive">{{ `${translate('MOTIVE.THIS')} :` }}</span>
            <span v-if="!displaySaveButton" class="box-input-state-folder__label">{{
                ` ${motive.label ? motive.label : '-'}`
              }}</span>
            <Select v-else :value="currentMotiveId" :values="motiveValues" @option-selected="computeNewMotiveId"
                    :empty-option="true" :options="motiveOptions" class="box-input-state-folder__select"/>
          </div>
        </div>
        <div class="box-input-state-folder__wrapper__paragraph box-input-state-folder__wrapper__description">
          <span class="box-input-state-folder__underline__description">{{
              `${translate('MOTIVE.REASON')} :`
            }}</span>
          <span v-if="!displaySaveButton"
                class="box-input-state-folder__label">{{ ` ${motive.description ? motive.description : '-'}` }}</span>
          <TextArea v-else v-model:value="motiveDescription" @update:value="computeMotiveDescription"
                    :disabled="disabledDescription"
                    class="box-input-state-folder__input"/>
          <span v-if="displaySaveButton" class="character-counter">{{
              `${motiveDescription.length}/${maxDescriptionLength}`
            }}</span>
        </div>
      </div>
      <div class="box-input-state-folder__actions">
        <Button v-if="!displaySaveButton" :label="translate('BUTTONS.MODIFY')" @click="editFolder"/>
        <Button v-if="!displaySaveButton" :label="translate('BUTTONS.DELETE')"
                class="box-input-state-folder__actions__delete" @click="deleteFolder"/>
        <Button v-else :label="translate('BUTTONS.SAVE')" @click="saveFolder"
                class="box-input-state-folder__actions__save" :disabled="disabledSaveButton"/>
        <Button v-if="displaySaveButton" :label="translate('BUTTONS.CANCEL')" @click="cancelFolder"
                class="box-input-state-folder__actions__cancel"/>
      </div>
    </div>
  </div>
</template>
