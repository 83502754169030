import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

export const listCompanies = (excludeMeelo: boolean, excludeDisabled: boolean) =>
  api.get(`${envConfig.IAM_ROUTE}/v5/internal/user/companies?excludeMeelo=${excludeMeelo}&excludeDisabled=${excludeDisabled}`)
    .then((response) => response.data);

export const listUsers = (companyId: string, excludeDisabled: boolean) =>
  api.get(`${envConfig.IAM_ROUTE}/v5/internal/companies/${companyId}/users?excludeDisabled=${excludeDisabled}`)
    .then((response) => response.data);

export const goConnectAs = (userId: string) =>
  api.post(`${envConfig.IAM_ROUTE}/v5/me/connect-as/${userId}`)
    .then((response) => response.data);

export const logoutConnectAs = () => {
  return api.post(`${envConfig.IAM_ROUTE}/v5/me/connect-as/reset`)
}

export default {logoutConnectAs, goConnectAs, listUsers, listCompanies};

