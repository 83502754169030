<script setup lang="ts">
import ResultCard from "@/components/card/ResultCard.vue";
import Button from '@/components/button/Button.vue';
import {useStore} from "vuex";

const props = withDefaults(
    defineProps<{
      title: string
      checked: boolean,
      text: string,
      labelButton: string,
      disabled: boolean
    }>(), {
      checked: false,
      disabled: false
    }
);

const modalFlag = defineModel('modalFlag', {default: false, type: Boolean});

const emit = defineEmits(['select']);

const modalFlagClick = () => {
  modalFlag.value = !modalFlag.value;
}

const select = () => {
  emit('select');
}
</script>

<template>
  <ResultCard :class="`two-factor-radio ${checked ? 'two-factor-radio__checked' : ''}`"
      v-on:click="select" :title="title">
    <div class="two-factor-radio__content">
      <span class="two-factor-radio__content__text">{{text}}</span>
      <slot></slot>
      <Button :label="labelButton" @click="modalFlagClick()" :disabled="disabled"/>
    </div>
  </ResultCard>
</template>

