<template>
  <div
    :class="`custom-sidebar ${$route.path.startsWith('/back-office') ? 'custom-backoffice__sidebar-body' : ''}`">
    <div class="sidebar-body__logo-group">
      <router-link v-if="$route.path.startsWith('/back-office')" to="/back-office/home">
        <img class="sidebar-body__logo-group__logo" loading="lazy" src="/images/logo_vertical_meelo_blue.png"
             alt="Logo Meelo">
      </router-link>
      <router-link v-else to="/home">
        <img class="sidebar-body__logo-group__logo" :src="companyLogo.src"
             :alt="companyLogo.alt">
      </router-link>
    </div>
    <div v-if="!$route.path.startsWith('/back-office')" class="sidebar-body__group-button">
      <template v-for="(elt) in portalNavigation">
        <NavigationGroup
          :titles="getPermissions(elt).map(({title}) => title)"
          :colors-background="elt.elements.map(()=>elt.colorBackground)"
          :colors-title="elt.elements.map(()=>elt.colorTitle)"
          :group_title="elt.title"
          :routes="getPermissions(elt).map(({route})=>route)"
          @redirect="redirect" />
      </template>
    </div>
    <div v-if="$route.path.startsWith('/back-office/home')">
      <template v-for="(elt) in backOfficeNavigation">
        <NavigationGroup
          :titles="elt.elements.filter(({roles}) => roles === undefined || user?.roles?.some((e) => roles?.includes(e))).map(({title}) => title)"
          :colors-background="elt.elements.map(()=>elt.colorBackground)"
          :colors-title="elt.elements.map(()=>elt.colorTitle)"
          :group-title="elt.title"
          :routes="elt.elements.map(({route})=>route)"
        />
      </template>
    </div>
    <div v-if="$route.path.startsWith('/back-office/users')">
      <template v-for="(elt) in usersNavigation">
        <NavigationGroup
          :titles="elt.elements.filter(({roles}) => roles === undefined || user?.roles?.some((e) => roles?.includes(e))).map(({title}) => title)"
          :colors-background="elt.elements.map(()=>elt.colorBackground)"
          :colors-title="elt.elements.map(()=>elt.colorTitle)"
          :group-title="elt.title"
          :routes="elt.elements.map(({route})=>route)"
        />
      </template>
    </div>
    <div
      v-if="$route.path.startsWith('/back-office/my-usage') || $route.path.startsWith('/back-office/usage-company')">
      <template v-for="(elt) in monitoringNavigation">
        <NavigationGroup
          :titles="elt.elements.filter(({roles}) => roles === undefined || user?.roles?.some((e) => roles?.includes(e))).map(({title}) => title)"
          :colors-background="elt.elements.map(()=>elt.colorBackground)"
          :colors-title="elt.elements.map(()=>elt.colorTitle)"
          :group-title="elt.title"
          :routes="elt.elements.map(({route})=>route)"
        />
      </template>
    </div>
    <div
      v-if="$route.path.startsWith('/back-office/rules-engine')">
      <template v-for="(elt) in rulesNavigation">
        <NavigationGroup
          :titles="elt.elements.filter(({roles}) => roles === undefined || user?.roles?.some((e) => roles?.includes(e))).map(({title}) => title)"
          :colors-background="elt.elements.map(()=>elt.colorBackground)"
          :colors-title="elt.elements.map(()=>elt.colorTitle)"
          :group-title="elt.title"
          :routes="elt.elements.map(({route})=>route)"
        />
      </template>
    </div>
    <div
      v-if="$route.path.startsWith('/back-office/my-company')">
      <template v-for="(elt) in companyNavigation">
        <NavigationGroup
          :titles="elt.elements.filter(({roles}) => roles === undefined || user?.roles?.some((e) => roles?.includes(e))).map(({title}) => title)"
          :colors-background="elt.elements.map(()=>elt.colorBackground)"
          :colors-title="elt.elements.map(()=>elt.colorTitle)"
          :group-title="elt.title"
          :routes="elt.elements.map(({route})=>route)"
        />
      </template>
    </div>
    <div class="sidebar-body__faq">
      <button class="sidebar-body__faq-button" @click="handleOnClick">
        <img class="sidebar-body__faq-image"
             :src="require(`/public/images/headphones.png`)"
             alt="headphones.png">{{ translate('SIDEBAR.HELP') }}
      </button>
      <router-link v-if="permissionsSorted?.includes('KIT_DECLARE_FRAUD_DECLARE_FRAUD_GLOBAL')" to="/fraud-declaration"
                   class="sidebar-body__fraud-link">
        <button class="sidebar-body__fraud-button">
          <div class="sidebar-body__fraud-title">
            <img class="sidebar-body__fraud-image"
                 :src="require(`/public/images/fraud-detection.png`)"
                 alt="fraud-detection.png">
            {{ translate('DECLARATION.FRAUD.BUTTON_SIDEBAR').toUpperCase() }}
          </div>
        </button>
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import NavigationGroup from '@/components/sidebar/NavigationGroup.vue';
import { translate } from '@/i18n';
import Button from '@/components/button/Button.vue';
import { mapGetters } from 'vuex';
import portalNavigation from '@/components/sidebar/groups/portal';
import monitoringNavigation from '@/components/sidebar/groups/monitoring';
import rulesNavigation from '@/components/sidebar/groups/rules';
import { NavigationItem } from '@/components/sidebar/groups/NavigationItem';
//import usersNavigation from "@/components/sidebar/groups/users";
import backOfficeNavigation from '@/components/sidebar/groups/backOffice';
import usersNavigation from './groups/users';
import Sticker from '../sticker/Sticker.vue';
import companyNavigation from '@/components/sidebar/groups/company';
import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

export default defineComponent({
  methods: {
    async handleOnClick() {
      const url = await api.get(envConfig.BACKEND_ROUTE + '/toolbox/help');
      window.open(url.data.url, '_blank');
    },
    usersNavigation() {
      return usersNavigation;
    },
    monitoringNavigation() {
      return monitoringNavigation;
    },
    rulesNavigation() {
      return rulesNavigation;
    },
    translate,
    redirect(path: string) {
      if (!['analysis_b2c', 'analysis_b2b', 'document_analysis', 'openbanking', 'batch', 'recovery'].includes(path)) {
        this.$router.push(`${path}`);
        return;
      }
      switch (path) {
        case 'analysis_b2c': {
          //this.marketType = 'portal-b2c';
          this.$router.push(`/analysis-b2c`);
          window.dispatchEvent(new Event('resetAnalysisB2c'));
          break;
        }
        case 'analysis_b2b': {
          //this.marketType = 'portal-b2b';
          this.$router.push(`/analysis-b2b`);
          window.dispatchEvent(new Event('resetAnalysisB2b'));
          break;
        }
        case 'openbanking': {
          //this.marketType = 'open-banking';
          this.$router.push(`/open-banking`);
          window.dispatchEvent(new Event('resetOpenBanking'));
          break;
        }
        case 'document_analysis': {
          //this.marketType = 'document-analysis';
          this.$router.push(`/document-analysis`);
          window.dispatchEvent(new Event('resetDocumentAnalysis'));
          break;
        }
        case 'recovery': {
          //this.marketType = 'recovery';
          this.$router.push(`/recovery`);
          window.dispatchEvent(new Event('resetRecoveryAnalysis'));
          break;
        }
      }
    },
    getPermissions(elt: NavigationItem) {
      return elt.elements.filter(({ requiredKit }) => requiredKit == null || this.kits.map((e: {
        name: any;
      }) => e.name).includes(requiredKit)).filter(({ roles }) => roles === undefined || this.user?.roles?.some((e: string) => roles?.includes(e)));
    }
  },
  data: () => ({
    stickerValues: translate('NEW'),
  }),
  components: {
    Button,
    NavigationGroup,
    Sticker
  },
  computed: {
    ...mapGetters(['user', 'kits', 'permissionsSorted', 'companyLogo']),
    portalNavigation: () => portalNavigation,
    backOfficeNavigation: () => backOfficeNavigation,
    monitoringNavigation: () => monitoringNavigation,
    rulesNavigation: () => rulesNavigation,
    usersNavigation: () => usersNavigation,
    companyNavigation: () => companyNavigation
  }
});
</script>
