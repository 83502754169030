<script setup lang="ts">
import GeneralCondition from '@/components/GeneralCondition.vue';
import { onMounted } from 'vue';
import MeeloLogo from '@/components/Logo.vue';
import {handleUserLogout} from "@/utils/authUtils";

onMounted(async () => {
  await handleUserLogout();
});
</script>

<template>
  <div class="LoginPage">
    <div class="LoginPage__left">
      <MeeloLogo class="LoginPage__left__logo"/>
      <a href="https://www.getmeelo.com/meelo-fintech-certifiee-b-corp/" target="_blank">
        <img class="logoB" :src="require('/public/images/bcorpLogo.png')" alt="LogoBCorp">
      </a>
      <GeneralCondition class="LoginPage__left__cgu"/>
    </div>
    <div class="LoginPage__right">
      <MeeloLogo class="LoginPage__right__logo" white/>
      <router-view class="LoginPage__right__form" white/>
      <GeneralCondition is-right class="LoginPage__right__cgu" white/>
    </div>
  </div>
</template>

